import React, { useEffect, useState } from 'react';
import Button from 'Components/Button';
import { MainType } from './Container';
import ProgressBar from '@ramonak/react-progress-bar';
import Countdown from 'react-countdown';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { SVG } from 'Components';
// import ReactPlayer from 'react-player';
import moment from 'moment';
import { useNavigate } from 'react-router';

const QUESTION_TYPE = {
  basic: 'PODSTAWOWY',
  specialized: 'SPECJALISTYCZNY',
};

const questionSpecializedNumber = {
  21: 1,
  22: 2,
  23: 3,
  24: 4,
  25: 5,
  26: 6,
  27: 7,
  28: 8,
  29: 9,
  30: 10,
  31: 11,
  32: 12,
};

function ExaminationPage(props: MainType) {
  const {
    actions: { getDrivingTest, getDrivingTestAnswer, finishDrivingTest, getAuthorizedUser },
    drivingTest,
    authorizedUser,
  } = props;
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 1024;
  const readingBasicQuestionTime = 20000;
  const answearingBasicQuestionTime = 35000;
  const answearingSpecializedQuestionTime = 50000;
  const [question, setQuestion] = useState(null);
  const [time, setTime] = useState(undefined);
  const [isStart, setIsStart] = useState(false);
  const [isTimeFreeze, setIsTimeFreeze] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [activeAnswer, setActiveAnswer] = useState('');
  const [refreshTimer, setRefreshTimer] = useState(undefined);
  const isReadingQuestion = time === readingBasicQuestionTime;
  const handleFreezeTime = () => setIsTimeFreeze(true);
  const handleUnfreezeTime = () => setIsTimeFreeze(false);
  const localStorageTimeEndExam = JSON.parse(localStorage.getItem('EXAM_TIME_TO_END'));
  // const isRefreshSite = sessionStorage.getItem('wasReloaded');
  // const localStorageTimeEndExamMoment = moment(JSON.parse(localStorage.getItem('EXAM_TIME_TO_END')));
  const currentTime = moment();
  const timeToEndExam = currentTime.add(25, 'minutes');
  // const timeDiff = moment.duration(moment().diff(localStorageTimeEndExamMoment));
  // const asMilliseconds = Math.abs(timeDiff.asMilliseconds());
  const timerAfterRefresh = JSON.parse(localStorage.getItem('EXAM_TIMER'));
  const comaprisinDateLocalStorage = localStorageTimeEndExam && moment(moment().toISOString()).diff(moment(localStorageTimeEndExam));
  const minutes = Math.floor(comaprisinDateLocalStorage / 60000);
  let timer;

  useEffect(() => {
    localStorage.setItem('isNewVisit', JSON.stringify(true));
  }, []);

  const eddTime = moment.duration(moment().diff(drivingTest?.endExam));
  const endTimeSec = Math.abs(eddTime.asMilliseconds());
  const endDateTime = localStorageTimeEndExam ? Date.now() + endTimeSec : Date.now() + 1500000;

  useEffect(() => {
    authorizedUser && getAuthorizedUser();
  }, []);

  useEffect(() => {
    if (minutes > 25) {
      localStorage.removeItem('EXAM_TIME_TO_END');
    }
  }, [minutes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDrivingTest();
        if (response) {
          !localStorageTimeEndExam && localStorage.setItem('EXAM_TIME_TO_END', JSON.stringify(timeToEndExam));
          const findLastIndex = (arr, callback) => {
            for (let i = arr.length - 1; i >= 0; i--) {
              if (callback(arr[i])) {
                return i;
              }
            }
            return -1;
          };
          const questionIndex = findLastIndex(response.question, (obj) => obj.isAnswered === true);
          if (questionIndex == -1) {
            sessionStorage.setItem('wasReloaded', 'false');
          }
          setQuestionNumber(questionIndex + 1);
          setIsStart(true);
        } else {
          navigate({ pathname: ROUTES.DRIVING_TEST_SUMMARY });
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    setQuestion(drivingTest?.question[0]);
  }, [drivingTest]);

  useEffect(() => {
    setQuestion(drivingTest?.question[questionNumber]);
    const isNewVisit = localStorage.getItem('isNewVisit');

    if (isNewVisit && timerAfterRefresh) {
      setTime(timerAfterRefresh?.type);
      setRefreshTimer(timerAfterRefresh);
    } else {
      setTime(
        drivingTest?.question[questionNumber].type === undefined
          ? readingBasicQuestionTime
          : drivingTest?.question[questionNumber].type === QUESTION_TYPE.basic
          ? readingBasicQuestionTime
          : answearingSpecializedQuestionTime,
      );
    }
  }, [questionNumber]);

  const skipWaiting = () => {
    sessionStorage.setItem('wasReloaded', 'false');
    setRefreshTimer(undefined);
    setTime(answearingBasicQuestionTime);
    const filename = question.filePath.substring(question.filePath.lastIndexOf('/') + 1);
    filename.endsWith('.mp4') && handleFreezeTime();
    scrollToQuestion();
  };

  const timers = () => {
    const renderer = (props) => {
      timer = { total: props.total, seconds: props.seconds, type: time };
      localStorage.setItem('EXAM_TIMER', JSON.stringify(timer));
      const readingTimeout = props.seconds === 0 && time === readingBasicQuestionTime;
      const answeringTimeout = props.seconds === 0 && (time === answearingBasicQuestionTime || time === answearingSpecializedQuestionTime);
      !isTimeFreeze ? props.api.start() : props.api.pause();
      if (readingTimeout) {
        localStorage.removeItem('isNewVisit');
        setRefreshTimer(undefined);
        props.api.stop();
        skipWaiting();
      } else if (answeringTimeout) {
        localStorage.removeItem('isNewVisit');
        if (questionNumber + 1 <= 20) {
          setRefreshTimer(undefined);
          setTime(readingBasicQuestionTime);
        }
        setRefreshTimer(undefined);
        props.api.stop();
        nextQuestion();
      }
      const handleAnswer = () => {
        localStorage.removeItem('isNewVisit');
        setRefreshTimer(undefined);
        nextQuestion();
        !isTimeFreeze && props.api.start(), handleUnfreezeTime();
        // scrollToQuestion();
        setTimeout(() => {
          sessionStorage.setItem('wasReloaded', 'false');
        }, 300);
      };

      const initCompletedAnimation = () => {
        if (refreshTimer) {
          const fullTime = refreshTimer.type / 1000;
          const actualyTime = refreshTimer.seconds;
          const percentage = (actualyTime / fullTime) * 100;
          return 100 - percentage;
        } else {
          return 0;
        }
      };

      if (time === answearingBasicQuestionTime || time === answearingSpecializedQuestionTime) {
        return (
          <div className="timing">
            <div className="item">
              <div className="paragraph">Czas na udzielenie odpowiedzi:</div>
            </div>
            <div className="timing--question-timer">
              <div className="timing__timer">
                {!isTimeFreeze ? (
                  <ProgressBar
                    className="progressBar__wrapper"
                    barContainerClassName="progressBar__container"
                    labelClassName="progressBar__label--hidden"
                    completed="100"
                    isLabelVisible={false}
                    // transitionDuration={`${time === answearingBasicQuestionTime ? 35 : 50}s`}
                    transitionDuration={`${refreshTimer ? `${refreshTimer.seconds}s` : `${time === answearingBasicQuestionTime ? 35 : 50}s`}`}
                    dir="rtl"
                    animateOnRender
                    initCompletedOnAnimation={initCompletedAnimation()}
                  />
                ) : (
                  <div className="progressBar__wrapper">
                    <div className="progressBar__container">
                      <div className="empty"></div>
                    </div>
                  </div>
                )}
                <div className="timer--counter">{props.seconds}s</div>
              </div>
            </div>
            {questionNumber === 31 ? (
              <Button
                label="Zakończ egzamin"
                className="btn--orange small"
                onClick={async () => {
                  await getDrivingTestAnswer({ answer: activeAnswer, questionUuid: question?.uuid, drivingTestUuid: drivingTest.uuid });
                  finishExam();
                }}
              />
            ) : (
              <Button label="Następne pytanie" className="btn--orange small" onClick={() => handleAnswer()} />
            )}
          </div>
        );
      } else if (time === readingBasicQuestionTime) {
        return (
          <div className="timing">
            <div className="item">
              <div className="paragraph">Czas na zapoznanie się z pytaniem:</div>
            </div>
            <div className="timing--question-timer">
              <>
                <Button label="Start" className="btn--orange small" onClick={() => skipWaiting()} />
                <div className="timing__timer">
                  <ProgressBar
                    className="progressBar__wrapper"
                    barContainerClassName="progressBar__container progressBar__container--reading"
                    labelClassName="progressBar__label--hidden"
                    completed="100"
                    isLabelVisible={false}
                    transitionDuration={`${refreshTimer ? `${refreshTimer.seconds}s` : `20s`}`}
                    dir="rtl"
                    animateOnRender
                    initCompletedOnAnimation={initCompletedAnimation()}
                  />
                  <div className="timer--counter timer--counter--reading">{props.seconds}s</div>
                </div>
              </>
            </div>
            <Button label="Następne pytanie" disabled className="btn--orange small" onClick={() => handleAnswer()} />
          </div>
        );
      }
    };
    return <Countdown date={refreshTimer ? Date.now() + refreshTimer.total : Date.now() + time} renderer={renderer} />;
  };

  const getQuestionMedia = (file) => {
    const filename = file.substring(file.lastIndexOf('/') + 1);
    if (isReadingQuestion) {
      if (filename.endsWith('.mp4')) {
        return (
          <span onClick={() => skipWaiting()}>
            <SVG type={SVG_TYPE.VIDEO} />
          </span>
        );
      }
      if (filename.endsWith('.webp')) {
        return (
          <span onClick={() => skipWaiting()}>
            <SVG type={SVG_TYPE.CAMERA} />
          </span>
        );
      }
    } else {
      if (filename.endsWith('.mp4')) {
        return (
          // <ReactPlayer
          //   fullscreen={false}
          //   playsinline={true}
          //   playing={isRefreshSite === 'true' ? false : true}
          //   onEnded={() => handleUnfreezeTime()}
          //   url={file}
          //   controls={false}
          //   width="100%"
          //   height="100%"
          // />
          <video id="myVideo" src={file} controls={false} autoPlay muted playsInline>
            Twoja przeglądarka nie obsługuje elementu video.
          </video>
        );
      }
      if (filename.endsWith('.webp')) {
        return <img className="question__img" src={file} alt="" />;
      }
    }
  };

  const nextQuestion = async () => {
    const response = await getDrivingTestAnswer({ answer: activeAnswer, questionUuid: question?.uuid, drivingTestUuid: drivingTest.uuid });
    if (questionNumber + 1 <= 20) {
      null;
    } else {
      setTime(readingBasicQuestionTime);
      setTimeout(() => {
        setTime(answearingSpecializedQuestionTime);
      }, 50);
    }
    if (response === 422) {
      setTimeout(() => {
        navigate({ pathname: ROUTES.LOGIN_PAGE });
      }, 200);
    }
    setQuestionNumber(questionNumber + 1);
    setActiveAnswer('');
    scrollToQuestion();
  };

  const finishExam = async () => {
    sessionStorage.setItem('wasReloaded', 'false');
    const response = await finishDrivingTest(drivingTest.uuid);
    localStorage.removeItem('EXAM_TIME_TO_END');
    localStorage.removeItem('EXAM_TIMER');
    localStorage.setItem('EXAM_SUMMARY', JSON.stringify(response));
    navigate({ pathname: ROUTES.DRIVING_TEST_SUMMARY });
    setTime(readingBasicQuestionTime);
    // window.location.reload();
  };

  const setAnswer = (answer) => {
    setRefreshTimer(timer);
    setActiveAnswer(answer);
  };

  useEffect(() => {
    if (sessionStorage.getItem('wasReloaded')) {
      sessionStorage.setItem('wasReloaded', 'true');
    }
  }, []);

  useEffect(() => {
    const isRefresh = sessionStorage.getItem('wasReloaded');
    const checkForElement = () => {
      const videoElement = document.getElementById('myVideo') as HTMLVideoElement;
      if (videoElement) {
        isRefresh === 'true' ? videoElement.pause() : videoElement.play();
        const handleEnded = () => {
          handleUnfreezeTime();
        };
        videoElement.addEventListener('ended', handleEnded);
        return () => {
          videoElement.removeEventListener('ended', handleEnded);
        };
      } else {
        requestAnimationFrame(checkForElement);
      }
    };

    checkForElement();
  }, [question?.filePath]);

  const scrollToQuestion = () => {
    const contentContainer = document.querySelector('.examinationPage__content') as HTMLElement;
    const questionContainer = document.querySelector('.question') as HTMLElement;

    if (contentContainer && questionContainer) {
      const questionOffset = questionContainer.offsetTop - contentContainer.offsetTop;
      contentContainer.scrollTo({
        top: contentContainer.scrollTop + questionOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {isStart ? (
        <div className="examinationPage">
          <div className="examinationPage__content">
            <div className="top">
              <div className="top__item">
                <p className="paragraph"> {isMobile ? 'Punkty' : 'Wartość punktowa'}</p>
                <div className="box box--small">{question?.pointsNumber}</div>
              </div>
              <div className="top__item">
                <p className="paragraph">Kategoria</p>
                <div className="box box--small">{drivingTest?.category}</div>
              </div>
              <div className="top__item">
                <p className="paragraph">Czas do końca</p>
                <div className="box box--time">
                  <Countdown
                    date={endDateTime}
                    onComplete={() => finishExam()}
                    renderer={({ seconds, minutes }) => {
                      if ((minutes === 0 && seconds === 0) || minutes < 0 || minutes > 25) {
                        finishExam();
                      }
                      return (
                        <span>
                          {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                        </span>
                      );
                    }}
                  />
                </div>
              </div>
              <Button label="Przerwij egzamin" className="btn--orange small" onClick={() => finishExam()} />
            </div>
            <div className="questions-count">
              <div className="questions-count__item">
                <p className="paragraph">
                  <span>Pytania</span> <span>podstawowe</span>
                </p>
                <div className={`box box--questions-count ${questionNumber + 1 >= 21 ? 'box--disabled' : ''}`}>
                  {questionNumber >= 20 ? '20 z 20' : `${questionNumber + 1} z 20`}
                </div>
              </div>
              <div className="questions-count__item">
                <p className="paragraph">
                  <span>Pytania</span> <span>specjalistyczne</span>
                </p>
                <div className={`box box--questions-count ${questionNumber + 1 <= 20 ? 'box--disabled' : ''}`}>
                  {questionNumber >= 20 ? `${questionSpecializedNumber[questionNumber + 1]} z 12` : '0 z 12'}
                </div>
              </div>
            </div>
            {timers()}
            {question && (
              <div className="question">
                <div className="question__photo">{getQuestionMedia(question.filePath)}</div>
                <p className="question__question paragraph">{question?.question}</p>
                {question?.type === QUESTION_TYPE.basic ? (
                  <div className="question__answers question__answers--basic">
                    <div className="question__answer-item">
                      <div className={`box ${activeAnswer === 'T' ? 'box--active' : ''}`} onClick={() => setAnswer('T')}>
                        TAK
                      </div>
                    </div>
                    <div className="question__answer-item">
                      <div className={`box ${activeAnswer === 'N' ? 'box--active' : ''}`} onClick={() => setAnswer('N')}>
                        NIE
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="question__answers">
                    <div className="question__answer-item">
                      <div onClick={() => setAnswer('answerA')} className={`box ${activeAnswer === 'answerA' ? 'box--active' : ''}`}>
                        A
                      </div>
                      <div className="paragraph">{question.answerA}</div>
                    </div>
                    <div className="question__answer-item">
                      <div onClick={() => setAnswer('answerB')} className={`box ${activeAnswer === 'answerB' ? 'box--active' : ''}`}>
                        B
                      </div>
                      <div className="paragraph">{question.answerB}</div>
                    </div>
                    <div className="question__answer-item">
                      <div onClick={() => setAnswer('answerC')} className={`box ${activeAnswer === 'answerC' ? 'box--active' : ''}`}>
                        C
                      </div>
                      <div className="paragraph">{question.answerC}</div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ExaminationPage;
