import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { createRef } from 'react';
import { Outlet } from 'react-router';

function LoginScreens() {
  const node = createRef<any>();

  return (
    <div className="login-screens" ref={node}>
      <main className="login-screens__left">
        <Outlet />
      </main>
      <aside className="login-screens__right">
        <div className="login-screens__right-container">
          <SVG type={SVG_TYPE.BIG_LOGO} className="login-screens__logo" />
          <h2 className="login-screens__title">Przygotuj się do egzaminu z&nbsp;Royal Academy!</h2>
          <ul className="login-screens__list">
            <li className="login-screens__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              {/* <p>Ścieżka nauki z postępami</p> */}
              <p>Lorem ipsum dolor sit amet.</p>
            </li>
            <li className="login-screens__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              {/* <p>Widoczny % szans na zdanie egzaminu państwowego</p> */}
              <p>Lorem ipsum dolor sit amet.</p>
            </li>
            <li className="login-screens__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              {/* <p>Nielimitowana ilość podejść do egzaminu</p> */}
              <p>Lorem ipsum dolor sit amet.</p>
            </li>
            <li className="login-screens__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              {/* <p>Szczegółowe statystyki dotyczące nauki oraz egzaminu</p> */}
              <p>Lorem ipsum dolor sit amet.</p>
            </li>
          </ul>
        </div>
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="login-screens__animated-element login-screens__animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="login-screens__animated-element login-screens__animated-element--second" />
      </aside>
    </div>
  );
}

export default LoginScreens;
