import { connect } from 'react-redux';
import LessonPage from './Main';
import { bindActionCreators } from 'redux';
import { startLesson as startLessonsSelector } from 'Store/Selectors/LessonSelector';
import { User } from 'Shared/models/UserModel';
import { startLessons, saveTask, answerTask, endLesson } from 'Store/Actions/Lesson';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  startLessons: any;
  authorizedUser: User | 'init';
};

type DispatchedP = {
  actions: {
    startLessons: (uuid) => (dispatch: any) => any;
    saveTask: (data) => (dispatch: any) => any;
    answerTask: (data) => any;
    endLesson: (uuid) => any;
  };
};
const mapStateToProps = (state) => ({
  startLessons: startLessonsSelector(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      startLessons: startLessons,
      saveTask: saveTask,
      answerTask: answerTask,
      endLesson: endLesson,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(LessonPage);
