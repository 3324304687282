import * as QuestionModule from 'Modules/QuestionModule';
import { Question } from '../EnumTypes/ActionEnumTypes';

export const success = (data) => ({
  type: Question.QUESTION_FILE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Question.QUESTION_FILE_FAILURE,
});

const questionFile = (uuid, file) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await QuestionModule.Connector.questionFile(uuid, formData);
    dispatch(success(response.data.user));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default questionFile;
