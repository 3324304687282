import { Button, Modal } from 'Components';

type RemoveTaskType = {
  isOpen: boolean;
  onClose: () => void;
  task: any;
  deleteTask: any;
};

const RemoveTask = ({ isOpen, onClose, task, deleteTask }: RemoveTaskType) => {
  const handleRemoveTask = async () => {
    await deleteTask(task.item.uuid, task.index);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-mobile--full">
      <h2 className="edit-user-modal__header">Usuwanie Zadania</h2>
      <p>Czy na pewno chcesz usunać to zadanie</p>
      <div className="remove-lesson__buttons">
        <Button label="Anuluj" type="button" onClick={() => onClose()} stroke />
        <Button label="Potwierdź" type="submit" onClick={() => handleRemoveTask()} />
      </div>
    </Modal>
  );
};

export default RemoveTask;
