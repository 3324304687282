import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { ROUTES } from 'Shared/enums';

import { Formik } from 'formik';
import { useNavigate } from 'react-router';

type changePasswordModalType = {
  isOpen: boolean;
  onClose: () => void;
  deleteCurrentUser: () => (dispatch: any) => Promise<any>;
};

function DeleteCurrentUserModal({ isOpen, onClose, deleteCurrentUser }: changePasswordModalType): JSX.Element {
  const navigate = useNavigate();
  const initialValues = {
    password: '',
  };

  const handleOnSubmit = async () => {
    const result = await deleteCurrentUser();
    if (result) {
      navigate(ROUTES.LOGIN_PAGE);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal modal-mobile--full`}>
      <h2 className="edit-user-modal__header">Usuń konto</h2>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={async () => handleOnSubmit()}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <p className="center mobile-delete"> Potwierdź usunięcie konta </p>
            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Usuń" className="add-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default DeleteCurrentUserModal;
