import * as Tasks from 'Modules/TaskModule';
import { Task } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Task.GET_SAVE_TASKS_INIT,
});

export const success = (data) => ({
  type: Task.GET_SAVE_TASKS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Task.GET_SAVE_TASKS_FAILURE,
});

const getSaveTasks = (page) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await Tasks.Connector.getSaveTasks(page);
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getSaveTasks;
