import { connect } from 'react-redux';
import MyProfilePacket from './Main';
import { bindActionCreators } from 'redux';
import { User } from 'Shared/models/UserModel';
import { getPaymentsLabel, getPayments } from 'Store/Actions/Payment';
import { getCities} from 'Store/Actions/Auth';
import { cities as getCitiesSelector} from 'Store/Selectors/AuthSelector';
import { getPaymentsLabel as getPaymentsLabelSelector } from 'Store/Selectors/PaymentSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  authorizedUser: User | 'init';
  paymentsLabel: any;
  cities: any;
};

type DispatchedP = {
  actions: {
    getPaymentsLabel: () =>  any;
    getPayments: (params) =>  any;
    getCities: () => any;
  };
};
const mapStateToProps = (state) => ({
  paymentsLabel: getPaymentsLabelSelector(state),
  authorizedUser: authorizedUser(state),
  cities: getCitiesSelector(state)
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getPaymentsLabel: getPaymentsLabel,
      getPayments: getPayments,
      getCities: getCities,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePacket);
