import React, { useRef, ReactNode, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'Shared/enums';

const getPathOrder = (path: string) => {
  const order = {
    [ROUTES.LEARNING]: 1,
    [ROUTES.EXAM]: 2,
    [ROUTES.USERS]: 3,
    [ROUTES.USER_STATISTICS]: 4,
    [ROUTES.QUESTIONS_DATABASE]: 5,
    [ROUTES.ADMIN_LEARNING]: 6,
    [ROUTES.GAMIFICATION]: 7,
    [ROUTES.MYPROFILE]: 8,
    [ROUTES.CONTACT]: 9,
    [ROUTES.SELECT_CATEGORY]: 10,
    [ROUTES.RECOMMENDED_OTHERS]: 11,
  };
  return order[path] || 0;
};

const shouldAnimate = (path: string, prevPathRef: any, setIsAnimate: any) => {
  // return path.includes('learning') && !path.includes('dashboard');
  if (prevPathRef.current === path) {
    setIsAnimate(false);
  } else {
    setIsAnimate(!path.includes('learning') && !path.includes('users') && !path.includes('question-database'));
  }
};

const AnimatedRoute = ({ children }: { children: ReactNode }) => {
  const [isAnimate, setIsAnimate] = useState(false);
  const isMobile = window.innerWidth < 1024;
  const location = useLocation();
  const prevPathRef = useRef(location.pathname);

  const direction = getPathOrder(location.pathname) - getPathOrder(prevPathRef.current);
  const exit = direction < 0 ? '100%' : '-100%';
  const init = direction < 0 ? '-100%' : '100%';

  useEffect(() => {
    shouldAnimate(location.pathname, prevPathRef, setIsAnimate);
    prevPathRef.current = location.pathname;
  }, [location.pathname]);

  return isMobile ? (
    <AnimatePresence mode="popLayout">
      {isAnimate ? (
        <motion.div
          key={location.pathname}
          transition={{ duration: 0.2 }}
          initial={{
            x: init,
            opacity: 0,
          }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: exit, opacity: 0 }}
          dragConstraints={{ left: 0, right: 0 }}>
          {children}
        </motion.div>
      ) : (
        <>{children}</>
      )}
    </AnimatePresence>
  ) : (
    <>{children}</>
  );
};

export default AnimatedRoute;
