import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

type AnswerNotificationType = {
  isPropertyAnswer: string;
  answer: string;
};

const AnswerNotification = ({ isPropertyAnswer, answer }: AnswerNotificationType) => (
  <>
    <div
      className={`lesson-page__notification lesson-page__notification--corectly ${
        isPropertyAnswer && isPropertyAnswer === answer ? 'lesson-page__notification--active' : ''
      }`}>
      Prawidłowa odpowiedź <SVG type={SVG_TYPE.CIRCLE_GREEN} />
    </div>
    <div
      className={`lesson-page__notification ${
        isPropertyAnswer && isPropertyAnswer != answer ? 'lesson-page__notification--active' : ''
      } lesson-page__notification--wrong`}>
      Błędna odpowiedź <SVG type={SVG_TYPE.CIRCLE_RED} />
    </div>
  </>
);

export default AnswerNotification;
