import * as Tasks from 'Modules/TaskModule';
import { Task, Lesson } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Task.ADD_TASK_INIT,
});

export const success = (data) => ({
  type: Task.ADD_TASK_SUCCESS,
  payload: {
    data,
  },
});

export const successLessonAddNumber = (data) => ({
  type: Lesson.ADD_TASK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Task.ADD_TASK_FAILURE,
});

const addTask = (data) => async (dispatch) => {
  const id = toast.loading('Trwa dodawanie zadania...');
  dispatch(init());
  try {
    const response = await Tasks.Connector.addTask(data);
    dispatch(success(response.data.task), successLessonAddNumber(response.data.task));
    toast.update(id, {
      render: 'Pomyślnie dodano zadanie',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się dodać zadania',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default addTask;
