import { connect } from 'react-redux';
import EnterSmsCodePage from './Main';
import { bindActionCreators } from 'redux';
import { login } from 'Store/Actions/Auth';
import getSmsCode from 'Store/Actions/Auth/getSmsCode';
import { User } from 'Shared/models/UserModel';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  authorizedUser: User | 'init' | 0;
};

type DispatchedP = {
  actions: {
    login: (smsCode: string) => any;
    getSmsCode: ({ phoneNumber, phonePrefixUuid }) => any;
  };
};
const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      login: login,
      getSmsCode: getSmsCode,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(EnterSmsCodePage);
