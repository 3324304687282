import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { Input } from 'Components/form';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

type changePasswordModalType = {
  isOpen: boolean;
  onClose: () => void;
  changeUserName: (name: string) => (dispatch: any) => Promise<boolean>;
  currentName: string;
};

function ChangeUserNameModal({ isOpen, onClose, changeUserName, currentName }: changePasswordModalType): JSX.Element {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .test('spaceCout', VALIDATION_MESSAGES.MAX_ONE_SPACE, (value) => {
        const spaceCount = value?.split(' ')?.length - 1;
        return spaceCount <= 1;
      }),
  });

  const initialValues = {
    name: currentName,
  };

  const handleOnSubmit = (values) => {
    changeUserName(values.name);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal modal-mobile--full`}>
      <h2 className="edit-user-modal__header">Zmiana nazwy użytkownika</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => handleOnSubmit(values)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field
              className={''}
              label="Nazwa użytkownika"
              name="name"
              placeholder="Wpisz nową nazwę użytkownika"
              required
              component={Input}
              errors={errors}
              treshold={0}
              maxLength={50}
            />

            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default ChangeUserNameModal;
