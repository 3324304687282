import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getAuthStore = (state: RootState) => state.authStore;
const authorizedUser = createSelector(getAuthStore, (state) => state.authorizedUser);
const token = createSelector(getAuthStore, (state) => state.token);
const getUserRoles = createSelector(authorizedUser, (user) => user.roles);
const phonePrefixes = createSelector(getAuthStore, (state) => state.phonePrefixes);
const cities = createSelector(getAuthStore, (state) => state.cities);

export { authorizedUser, token, getUserRoles, phonePrefixes, cities };
