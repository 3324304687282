import React from 'react';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const SvgView = () => {
  return (
    <ul className="svg-list">
      <li>
        <SVG type={SVG_TYPE.ARROW_RIGHT} /> ARROW_RIGHT
      </li>
      <li>
        <SVG type={SVG_TYPE.CIRCLE} /> CIRCLE
      </li>
      <li>
        <SVG type={SVG_TYPE.CHECKED} /> CHECKED
      </li>
      <li>
        <SVG type={SVG_TYPE.SEND_FILE} /> SEND_FILE
      </li>
      <li>
        <SVG type={SVG_TYPE.BACK_CHEVRON} /> BACK_CHEVRON
      </li>
      <li>
        <SVG type={SVG_TYPE.TIES} /> TIES
      </li>
      <li>
        <SVG type={SVG_TYPE.CLOSE} /> CLOSE
      </li>
      <li>
        <SVG type={SVG_TYPE.SEARCH} /> SEARCH
      </li>
      <li>
        <SVG type={SVG_TYPE.PERSON} /> PERSON
      </li>
      <li>
        <SVG type={SVG_TYPE.AWARD} /> AWARD
      </li>
      <li>
        <SVG type={SVG_TYPE.USER_CHECK} /> USER_CHECK
      </li>
      <li>
        <SVG type={SVG_TYPE.RECOMMENDATION} /> RECOMMENDATION
      </li>
      <li>
        <SVG type={SVG_TYPE.HAMBURGER} /> HAMBURGER
      </li>
      <li>
        <SVG type={SVG_TYPE.LOGO_GROUP} /> LOGO_GROUP
      </li>
      <li>
        <SVG type={SVG_TYPE.LOGO} /> LOGO
      </li>
      <li>
        <SVG type={SVG_TYPE.STUDY} /> STUDY
      </li>
      <li>
        <SVG type={SVG_TYPE.EXAM} /> EXAM
      </li>
      <li>
        <SVG type={SVG_TYPE.TROPHY} /> TROPHY
      </li>
      <li>
        <SVG type={SVG_TYPE.EDIT} /> EDIT
      </li>
      <li>
        <SVG type={SVG_TYPE.BIG_LOGO} /> BIG_LOGO
      </li>
      <li>
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} /> CIRCLE
      </li>
      <li>
        <SVG type={SVG_TYPE.EYE} /> EYE
      </li>
      <li>
        <SVG type={SVG_TYPE.EYE_OFF} /> EYE_OFF
      </li>
      <li>
        <SVG type={SVG_TYPE.WARNING} /> WARNING
      </li>
      <li>
        <SVG type={SVG_TYPE.LOGO_ROYAL} /> LOGO_ROYAL
      </li>
      <li>
        <SVG type={SVG_TYPE.STATS} /> STATS
      </li>
      <li>
        <SVG type={SVG_TYPE.USERS} /> USERS
      </li>
      <li>
        <SVG type={SVG_TYPE.FOLDER_QUESTION} /> FOLDER_QUESTION
      </li>
      <li>
        <SVG type={SVG_TYPE.CURLY_ARROW} /> CURLY_ARROW
      </li>
      <li>
        <SVG type={SVG_TYPE.INFO} /> INFO
      </li>
      <li>
        <SVG type={SVG_TYPE.CIRCLE_CHECKED} /> CIRCLE_CHECKED
      </li>
      <li>
        <SVG type={SVG_TYPE.DEFAULT_AVATAR} /> DEFAULT_AVATAR
      </li>
      <li>
        <SVG type={SVG_TYPE.BULB} /> BULB
      </li>
      <li>
        <SVG type={SVG_TYPE.CHEVRONE} /> CHEVRONE
      </li>
      <li>
        <SVG type={SVG_TYPE.MOTOR} /> MOTOR
      </li>
      <li>
        <SVG type={SVG_TYPE.CAR} /> CAR
      </li>
      <li>
        <SVG type={SVG_TYPE.DELIVERY_TRUCK} /> DELIVERY_TRUCK
      </li>
      <li>
        <SVG type={SVG_TYPE.BUS} /> BUS
      </li>
      <li>
        <SVG type={SVG_TYPE.CIRCLE_GREEN} /> CIRCLE_GREEN
      </li>
      <li>
        <SVG type={SVG_TYPE.CIRCLE_RED} /> CIRCLE_RED
      </li>
      <li>
        <SVG type={SVG_TYPE.CAMERA} /> CAMERA
      </li>
      <li>
        <SVG type={SVG_TYPE.VIDEO} /> VIDEO
      </li>
      <li>
        <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} /> SHORT_ARROW_RIGHT
      </li>
      <li>
        <SVG type={SVG_TYPE.ENTRY} /> ENTRY
      </li>
      <li>
        <SVG type={SVG_TYPE.STAR} /> STAR
      </li>
      <li>
        <SVG type={SVG_TYPE.SUPRISE} /> SUPRISE
      </li>
      <li>
        <SVG type={SVG_TYPE.SAD} /> SAD
      </li>
      <li>
        <SVG type={SVG_TYPE.EXCLAMATION} /> EXCLAMATION
      </li>
      <li>
        <SVG type={SVG_TYPE.EXIT} /> EXIT
      </li>
      <li>
        <SVG type={SVG_TYPE.REFRESH} /> REFRESH
      </li>
      <li>
        <SVG type={SVG_TYPE.PADLOCK} /> PADLOCK
      </li>
      <li>
        <SVG type={SVG_TYPE.SAVE_LABEL} /> SAVE_LABEL
      </li>
      <li>
        <SVG type={SVG_TYPE.BIN} /> BIN
      </li>
      <li>
        <SVG type={SVG_TYPE.SHERLOCK} /> SHERLOCK
      </li>
      <li>
        <SVG type={SVG_TYPE.ELLIPSIS} /> ELLIPSIS
      </li>
      <li>
        <SVG type={SVG_TYPE.CLOSE_BOLD} /> CLOSE_BOLD
      </li>
      <li>
        <SVG type={SVG_TYPE.CLOUD} /> CLOUD
      </li>
      <li>
        <SVG type={SVG_TYPE.WHITE_LOGO} /> WHITE_LOGO
      </li>
      <li>
        <SVG type={SVG_TYPE.COPY} /> COPY
      </li>
    </ul>
  );
};

export default SvgView;
