import { connect } from 'react-redux';
import SaveTaskPage from './Main';
import { bindActionCreators } from 'redux';
import { User } from 'Shared/models/UserModel';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { saveTask } from 'Store/Actions/Lesson';
import { getSingleSaveTask, answerSaveTask } from 'Store/Actions/Task';
import { getSingleSaveTask as getSingleSaveTaskSelector } from 'Store/Selectors/TaskSelector';

type ConnectedP = {
  authorizedUser: User | 'init';
  singleSaveTask: any;
};

type DispatchedP = {
  actions: {
    saveTask: (data) => (dispatch: any) => any;
    getSingleSaveTask: (uuid) => (dispatch: any) => any;
    answerSaveTask: (uuid, answer) => any;
  };
};
const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
  singleSaveTask: getSingleSaveTaskSelector(state)
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      saveTask: saveTask,
      getSingleSaveTask: getSingleSaveTask,
      answerSaveTask: answerSaveTask,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(SaveTaskPage);
