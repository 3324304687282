/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { DatePicker, Input, Select, DropFileInput } from 'Components/form';
import { SVG_TYPE, VALIDATION_MESSAGES, USER_ROLES, USER_TYPE } from 'Shared/enums';
import { phoneNumberValidation } from 'Shared/utils';
import { AdminEdit, User, UserEdit } from 'Shared/models/UserModel';
import { Field, Formik } from 'formik';
import * as Types from 'Shared/models/AuthModel';
import userTypeOptions from 'Shared/options/userTypeOptions';
import * as Yup from 'yup';

type SingleUserType = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  phonePrefixes: Types.PhonePrefix[];
  editUser: (userData: UserEdit | AdminEdit, uuid: string) => (dispatch: any) => Promise<boolean>;
  cities: Array<any>;
  manageUserSuspension: (uuid) => void;
  getUsersList: () => void;
  editAvatar: (uuid, file) => void;
};

function EditUserModal({
  user,
  isOpen,
  onClose,
  phonePrefixes,
  editUser,
  cities,
  manageUserSuspension,
  editAvatar,
  getUsersList,
}: SingleUserType): JSX.Element {
  const isAdmin = user?.role === USER_ROLES.ADMIN;
  const [isValidCityAndDeadline, setIsValidCityAndDeadline] = useState(false);
  const [isNewFile, setIsNewFile] = useState(false);
  const isPremiumUser = user?.role === 'client' && user?.type === USER_TYPE.PREMIUM;

  const phonePrefixesOptions = phonePrefixes.map((item) => {
    return {
      value: item.value,
      label: `+ ${item.phonePrefix}`,
      svgType: SVG_TYPE[`FLAG_${item.country.toUpperCase()}`],
    };
  });

  const userCity = cities.find((item) => item.label === user?.city);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    phoneNumber: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .when('phonePrefixUuid', (phonePrefixUuid, schema) => phoneNumberValidation(phonePrefixUuid, schema)),
    phonePrefixUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    cityUuid: !isAdmin && isValidCityAndDeadline && Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    deadline: !isAdmin && isValidCityAndDeadline && Yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
    email: isAdmin && Yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
    // type: Yup.object().required(VALIDATION_MESSAGES.REQUIRED),
    // suspend: Yup.object().required(VALIDATION_MESSAGES.REQUIRED),
  });

  const initialValues = {
    name: user?.name,
    phonePrefixUuid: phonePrefixesOptions.find((item) => item.label === `+ ${user?.phonePrefix}`)?.value,
    phoneNumber: user?.phoneNumber,
    deadline: user?.deadline ? new Date(user?.deadline?.deadline) : null,
    email: user?.email,
    type: user?.type,
    suspended: user?.suspended,
    avatar: user?.avatar[0] ? user?.avatar : undefined,
    cityUuid: user?.city ? userCity?.value : '',
  };
  const userStatusOptions = [
    { value: false, label: 'Aktywny' },
    { value: true, label: 'Zawieszony' },
  ];

  const handleOnSubmit = async (values, props) => {
    const deadlineDate = new Date(values.deadline);
    const year = deadlineDate.getFullYear();
    const month = (deadlineDate.getMonth() + 1).toString().padStart(2, '0');
    const day = deadlineDate.getDate().toString().padStart(2, '0');
    const hours = deadlineDate.getHours().toString().padStart(2, '0');
    const minutes = deadlineDate.getMinutes().toString().padStart(2, '0');
    const seconds = deadlineDate.getSeconds().toString().padStart(2, '0');

    user.suspended !== values.suspended && (await manageUserSuspension(user.uuid));
    const formattedDateOutput = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    if (isAdmin) {
      if (isNewFile) {
        values?.avatar ? await editAvatar(user.uuid, values.avatar[0]) : await editAvatar(user.uuid, null);
      }
    }
    const response = await editUser({ ...values, phoneNumber: values.phoneNumber.toString(), deadline: formattedDateOutput }, user.uuid);
    if (Array.isArray(response)) {
      response.map((item) => {
        item.field === 'email' && props.setFieldError(item.field, 'Email jest zajęty');
        item.fields === 'phoneNumber, phonePrefix' && props.setFieldError('phoneNumber', 'Nr telefonu jest zajęty');
        item.field === 'name' && props.setFieldError(item.field, 'Nazwa użytkownika jest zajęta');
      });
    } else {
      await getUsersList();
      onClose();
    }
    setIsNewFile(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal edit-user-modall modal-mobile--full`}>
      <h2 className="edit-user-modal__header">Edycja użytkownika</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, props) => handleOnSubmit(values, props)}>
        {({ errors, handleSubmit, values }) => {
          const isImpulsStudent = values.type === USER_TYPE.STUDENT_IMPULS;
          useEffect(() => {
            setIsValidCityAndDeadline(isImpulsStudent ? true : false);
          }, [isImpulsStudent]);
          return (
            <form onSubmit={handleSubmit} className="edit-user-modal__form">
              <Field
                className={''}
                label="Nazwa użytkownika"
                name="name"
                placeholder="Wpisz nazwę użytkownika"
                required
                component={Input}
                errors={errors}
                treshold={0}
                maxLength={50}
              />
              {isAdmin && (
                <Field
                  className={''}
                  label="Avatar"
                  name="avatar"
                  placeholder="Przeciągnij i upuść lub"
                  required
                  component={DropFileInput}
                  errors={errors}
                  onFileDrop={() => setIsNewFile(true)}
                  accept="image/*"
                  removeAvatar={() => setIsNewFile(true)}
                />
              )}
              <div className="edit-user-modal__phone login-page__form--phone">
                <Field
                  component={Select}
                  label="Telefon"
                  name="phonePrefixUuid"
                  options={phonePrefixesOptions}
                  errors={errors}
                  className={'login-page__select'}
                  required
                  placeholder=" "
                />
                <Field component={Input} required name="phoneNumber" type="number" errors={errors} className={'login-page__input'} />
              </div>
              {!isAdmin && (
                <>
                  {!isPremiumUser && (
                    <Field
                      component={Select}
                      label="Rodzaj konta"
                      name="type"
                      options={userTypeOptions}
                      errors={errors}
                      className={'login-page__select'}
                      required
                      placeholder=" "
                    />
                  )}
                  {isImpulsStudent && (
                    <>
                      <Field
                        className="question-date"
                        label="Data obowiązywania"
                        name="deadline"
                        placeholder="Konto aktywne do"
                        component={DatePicker}
                        errors={errors}
                      />
                      <div className="city-edit">
                        <Field
                          required
                          label="Miasto"
                          isSearchable
                          name="cityUuid"
                          placeholder="Wybierz miasto"
                          component={Select}
                          options={cities}
                          errors={errors}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {isAdmin && (
                <Field
                  className={''}
                  label="Email"
                  name="email"
                  placeholder="Wpisz email użytkownika"
                  required
                  maxLength={45}
                  component={Input}
                  errors={errors}
                />
              )}
              <Field
                component={Select}
                label="Status"
                name="suspended"
                options={userStatusOptions}
                type="date"
                errors={errors}
                className={'login-page__select'}
                required
                placeholder=" "
              />
              <div className="edit-user-modal__buttons">
                <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
                <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default EditUserModal;
