enum ROUTES {
  LEARNING = '/dashboard/learning/:submenu',
  EXAM = '/dashboard/exam',
  GAMIFICATION = '/dashboard/gamification',
  LOGIN_PAGE = '/client/login',
  MYPROFILE = '/dashboard/myprofile',
  HOME_PAGE = '/',
  ENTER_SMS_CODE_PAGE = '/client/enter-sms-code',
  SELECT_CATEGORY = '/client/select-category',
  ADMIN_LOGIN_PAGE = '/admin/login',
  SET_PASSWORD_PAGE = '/admin/set-password',
  CHANGE_PASSWORD_PAGE = '/admin/change-password',
  RESET_PASSWORD_PAGE = '/admin/reset-password',
  SUCCESSFUL_PASSWORD_RESET_LINK_SEND = '/admin/successful-password-reset-link-send',
  SUCCESSFUL_PASSWORD_CHANGE = '/admin/successful-password-change',
  DASHBOARD = '/dashboard',
  DEPARTMENTS_PAGE = '/dashboard/department/:uuid',
  ADMIN_LEARNING = '/dashboard/admin-learning',
  ADMIN_DEPARTMENT = '/dashboard/admin-department/:uuid',
  LESSON = '/dashboard/lesson/:uuid',
  MYPROFILE_PACKET = '/dashboard/myprofile/package',
  USERS = '/dashboard/users/:role/:page',
  USER_STATISTICS = '/dashboard/users/stats/:uuid',
  ADMIN_LOGIN_SCREENS = '/admin',
  EXAMINATION = '/exam',
  DRIVING_TEST_SUMMARY = '/driving-test-summary/:uuid',
  SAVE_TASK = '/dashboard/save-task/:uuid',
  LOGIN_SCREENS = '/client',
  QUESTIONS_DATABASE = '/dashboard/question-database/:category/:page',
  QUESTION = '/dashboard/question/:uuid',
  TOKEN_EXPIRED = '/admin/token-expired',
  CHECK_TOKEN = '/admin/activate/',
  SVG_VIEW = '/svg-view',
  RECOMMENDED_OTHERS = '/dashboard/recomended-others',
  CONTACT = '/dashboard/contact',
  TRANSACTION_SUCCESS = '/transaction-success',
  TRANSACTION_FAILED = '/transaction-failed',
  STATS = '/dashboard/stats',
  STATISTICS = '/dashboard/statistics',
  ALL_CITIES = '/dashboard/all-cities',
  PAGE_404 = '/404/',
}

export default ROUTES;

