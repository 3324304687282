import { connect } from 'react-redux';
import StatisticsPage from './Main';
import { bindActionCreators } from 'redux';
import { adminLogin } from 'Store/Actions/Auth';
import { getAllUserCity as getAllUserCitySelector, getPopularCities as getPopularCitiesSelector } from 'Store/Selectors/CitySelector'
import { getAllUserCity, getPopularCities } from 'Store/Actions/City';

type ConnectedP = {
  popularCities: any;
  allUserCities: any;
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    getAllUserCity: (page) => any;
    getPopularCities: () => any;
  };
};
const mapStateToProps = (state) => ({
  allUserCities: getAllUserCitySelector(state),
  popularCities: getPopularCitiesSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      adminLogin: adminLogin,
      getAllUserCity: getAllUserCity,
      getPopularCities: getPopularCities,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPage);
