import * as Cities from 'Modules/CityModule';
import { City } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: City.GET_CITIES_BY_USERS_INIT,
});

export const success = (data) => ({
  type: City.GET_CITIES_BY_USERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: City.GET_CITIES_BY_USERS_FAILURE,
});

const getAllUserCity = (page) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await Cities.Connector.getAllUserCity(page);
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getAllUserCity;
