import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromUserReducer from './UserReducer';
import * as fromAuthReducer from './AuthReducer';
import * as fromDrivingTestReducer from './DrivingTestReducer';
import * as fromQuestionReducer from './QuestionReducer';
import * as fromViewManagementReducer from './ViewManagementReducer';
import * as fromDepartmentReducer from './DepartmentReducer';
import * as fromPaymentReducer from './PaymentReducer';
import * as fromLessonReducer from './LessonReducer';
import * as fromTaskReducer from './TaskReducer';
import * as fromCityReducer from './CityReducer';
import { createBrowserHistory } from 'history';

export type RootState = {
  router: any;
  userStore: fromUserReducer.State;
  authStore: fromAuthReducer.State;
  drivingTestStore: fromDrivingTestReducer.State;
  lessonStore: fromLessonReducer.State;
  departmentStore: fromDepartmentReducer.State;
  taskStore: fromTaskReducer.State;
  cityStore: fromCityReducer.State;
  questionStore: fromQuestionReducer.State;
  paymentStore: fromPaymentReducer.State;
  viewManagementStore: fromViewManagementReducer.State;
};

export const history = createBrowserHistory();

const rootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  userStore: fromUserReducer.userReducer,
  authStore: fromAuthReducer.authReducer,
  drivingTestStore: fromDrivingTestReducer.drivingTestReducer,
  departmentStore: fromDepartmentReducer.departmentReducer,
  taskStore: fromTaskReducer.taskReducer,
  lessonStore: fromLessonReducer.lessonReducer,
  questionStore: fromQuestionReducer.questionReducer,
  paymentStore: fromPaymentReducer.paymentReducer,
  viewManagementStore: fromViewManagementReducer.viewManagementReducer,
  cityStore: fromCityReducer.cityReducer,
} as any);

const appReducer = (state: RootState, action) => {
  if (action.type === 'LOG_OUT_SUCCESS') {
    // state.authStore = undefined;
    // state.viewManagementStore.isMenuExpanded = false;
    // state.router = {
    //   router: { ...state.router },
    // };
  }
  return rootReducer(state, action);
};

export default appReducer;
