import { Button } from 'Components';
import { ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router';

const SaveTasksEmptyState = () => {
  const navigate = useNavigate();
  return (
    <div className="save-tasks-empty-state">
      <div className="save-tasks-empty-state__content">
        <div className="save-tasks-empty-state__heading">🧐</div>
        <div className="save-tasks-empty-state__description">
          Wygląda na to, że nie masz jeszcze żadnych zapisanych pytań. Przejdź do nauki, aby móc zapisywać pytania.
        </div>
        <Button label="Przejdź do nauki" onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) })} />
      </div>
    </div>
  );
};

export default SaveTasksEmptyState;
