import * as AuthModule from 'Modules/AuthModule';
import * as AuthActionModel from '../Models/AuthActionModel';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.LOG_IN_INIT,
});

export const success = (authData: AuthModule.Types.LogInResponse): AuthActionModel.LoginSuccess => ({
  type: Auth.LOG_IN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = (): AuthActionModel.LoginFailure => ({
  type: Auth.LOG_IN_FAILURE,
});

const adminLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(init());
    const id = toast.loading('Trwa logowanie...');
    try {
      const response = await AuthModule.Connector.adminLogin({ email, password });
      localStorage.setItem('adminLogin', JSON.stringify(true));
      dispatch(success(response.data));
      await dispatch(AuthModule.Actions.getAuthorizedUser());
      toast.update(id, {
        render: 'Zalogowano',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
      });
      return true;
    } catch (err) {
      dispatch(failure());
      toast.update(id, {
        render: 'Nie udało się zalogować',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
      return false;
    }
  };

export default adminLogin;
