import { useEffect, useState } from 'react';
import { MainType } from './Container';
import { SVG_TYPE, USER_ROLES, ROUTES } from 'Shared/enums';
import Pagination from 'Components/Pagination';
import { User } from 'Shared/models/UserModel';
import { SingleUser, EditUserModal, RestoreUserModal } from './components';
import Button from 'Components/Button';
import AddUserModal from './components/AddUserModal';
import SVG from 'Components/SVG';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

function Users(props: MainType) {
  const { actions, usersList, usersListMeta, phonePrefixes, cities } = props;
  const { getUsers, getPhonePrefixes, editUser, resendActivateLink, getCities, manageUserSuspension, editAvatar, addAdmin, userRestoration } =
    actions;
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const lastElement = parts.pop();
  const userRole = parts[parts.length - 1];
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(userRole || USER_ROLES.CLIENT);
  const [currentPage, setCurrentPage] = useState(parseInt(lastElement));
  const [edittedUser, setEdittedUser] = useState<User | null>(null);
  const [restoreUser, setRestoreUser] = useState<User | null>(null);
  const isAdmin = selectedType === USER_ROLES.ADMIN;
  const [openedModal, setOpenedModal] = useState(null);
  const [indexExpandUser, setIndexExpandUser] = useState(undefined);
  const isMobile = window.innerWidth < 1024;

  const getUsersList = () => {
    getUsers(selectedType, currentPage);
  };

  useEffect(() => {
    getUsersList();
    getPhonePrefixes();
    getCities();
  }, []);

  useEffect(() => {
    getUsersList();
    // setCurrentPage(1);
  }, [selectedType]);

  useEffect(() => {
    getUsers(selectedType, currentPage);
    setIndexExpandUser(false);
  }, [currentPage]);

  const users = Object.values(usersList);

  useEffect(() => {
    if (currentPage.toString() !== lastElement) {
      setCurrentPage(parseInt(lastElement));
    }
  }, [lastElement]);

  const changePage = (page) => {
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.USERS, { role: selectedType, page }) });
    }, 200);
  };

  return (
    <>
      <header className="dashboard__header question-database">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
        <div className="dashboard__container">
          <h1 className="dashboard__title">Lista użytkowników</h1>
          <div className="dashboard__categories dashboard__categories--users">
            <p
              className={`dashboard__category ${selectedType === USER_ROLES.CLIENT ? 'dashboard__category--active' : ''}`}
              onClick={() => {
                setSelectedType(USER_ROLES.CLIENT);
                setTimeout(() => {
                  navigate({ pathname: generatePath(ROUTES.USERS, { role: USER_ROLES.CLIENT, page: '1' }) });
                }, 200);
                setIndexExpandUser(undefined);
              }}>
              Klienci
            </p>
            <p
              className={`dashboard__category ${selectedType === USER_ROLES.ADMIN ? 'dashboard__category--active' : ''}`}
              onClick={() => {
                setSelectedType(USER_ROLES.ADMIN);
                setTimeout(() => {
                  navigate({ pathname: generatePath(ROUTES.USERS, { role: USER_ROLES.ADMIN, page: '1' }) });
                }, 200);
                setIndexExpandUser(undefined);
              }}>
              Administratorzy
            </p>
          </div>
        </div>
      </header>
      <div className="users-list question-database">
        <div className="dashboard__container ">
          <div className="users-list__table-wrapper">
            {isAdmin && isMobile ? (
              <div className="users-list__add-user-mobile">
                <Button label={'Dodaj +'} className="users-list__add-user-button" onClick={() => setOpenedModal('addUser')} />
              </div>
            ) : null}
            <table className="users-list__table">
              <thead className="users-list__thead">
                <tr>
                  <th>Użytkownik</th>
                  <th>Telefon</th>
                  <th>{isAdmin ? 'Email' : 'Rodzaj konta'}</th>
                  <th> {isAdmin ? 'Status' : 'Miasto'}</th>
                  <th>{isAdmin ? '' : 'Status'}</th>
                  <th>
                    {isAdmin ? <Button label={'Dodaj +'} className="users-list__add-user-button" onClick={() => setOpenedModal('addUser')} /> : null}
                  </th>
                </tr>
              </thead>

              <tbody className="users-list__tbody">
                {users &&
                  users.length > 0 &&
                  users
                    .filter((user) => user.role === selectedType)
                    .map((user: User, index) => (
                      <SingleUser
                        indexExpandUser={indexExpandUser}
                        setIndexExpandUser={setIndexExpandUser}
                        setRestoreUser={setRestoreUser}
                        resendActivateLink={resendActivateLink}
                        user={user}
                        index={index}
                        key={user.uuid}
                        setEdittedUser={setEdittedUser}
                      />
                    ))}
              </tbody>
            </table>
          </div>
          <RestoreUserModal userRestoration={userRestoration} user={restoreUser} isOpen={restoreUser !== null} onClose={() => setRestoreUser(null)} />
          <EditUserModal
            editAvatar={editAvatar}
            cities={cities}
            editUser={editUser}
            user={edittedUser}
            isOpen={edittedUser !== null}
            onClose={() => setEdittedUser(null)}
            phonePrefixes={phonePrefixes}
            manageUserSuspension={manageUserSuspension}
            getUsersList={getUsersList}
          />
          <AddUserModal
            addAvatar={editAvatar}
            editUser={editUser}
            user={edittedUser}
            isOpen={openedModal === 'addUser'}
            onClose={() => setOpenedModal(null)}
            phonePrefixes={phonePrefixes}
            addAdmin={addAdmin}
            getUsersList={getUsersList}
          />
          {usersListMeta.last_page > 1 ? (
            <Pagination
              className="order-list__pagination users-list--pagination"
              totalPages={usersListMeta?.last_page || 0}
              currentPage={currentPage}
              onPageChange={(page) => changePage(page)}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Users;
