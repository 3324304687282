import * as LessonConnector from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Lesson.ANSWER_TASK_INIT,
});

export const success = (data) => ({
  type: Lesson.ANSWER_TASK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.ANSWER_TASK_FAILURE,
});

const saveTask = (data) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await LessonConnector.Connector.answerTask(data);
    dispatch(success(response.data));
    return response.data.task;
  } catch (err: any) {
    dispatch(failure());
    return false;
  }
};

export default saveTask;
