import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FieldProps } from 'formik';
import { useState } from 'react';

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  secondValue?: string;
  maxLength?: number;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function Input({ label, className = '', name, placeholder, required, type, form, errors = {}, field, maxLength, onChange }: InputProps & FieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';

  const handleOnChange = (e) => {
    if (type === 'number' && e.target.value.length > maxLength) return;
    field.onChange(e);
    onChange && onChange(e.target.value);
  };

  function handleOnKeyPress(event) {
    const inputType = event.target.type;
    const keyCode = event.keyCode || event.which;
    if (inputType === 'number' && !/\d/.test(String.fromCharCode(keyCode))) {
      event.preventDefault();
    }
  }

  const handleOnPaste = (event) => {
    const inputType = event.target.type;
    const pastedText = event.clipboardData.getData('text/plain');
    const maxLengthExceeded = pastedText.length <= maxLength;

    if (maxLengthExceeded || !maxLength) {
      if (inputType === 'number') {
        if (!/^\d+$/.test(pastedText)) {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  };

  return (
    <div className={`input__wrapper ${className}`}>
      <label className="input__label" htmlFor={field.name}>
        {label} {required ? null : <p className="input__optional">(opcjonalne)</p>}
      </label>

      <input
        {...field}
        name={field.name}
        className="input"
        value={field.value}
        onChange={handleOnChange}
        id={name}
        placeholder={placeholder}
        type={isPassword ? (showPassword ? 'text' : 'password') : type}
        maxLength={maxLength}
        onKeyPress={handleOnKeyPress}
        onPaste={handleOnPaste}
        inputMode={type === 'number' ? 'numeric' : undefined}
      />
      {isPassword ? (
        <span className="input__change-password" onClick={() => setShowPassword(!showPassword)}>
          <SVG type={showPassword ? SVG_TYPE.EYE : SVG_TYPE.EYE_OFF} />
        </span>
      ) : null}

      {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default Input;
