import * as Types from 'Shared/models/LessonModel';
import { Lesson } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.LessonStore;

export const initialState: State = {
  lessons: null,
  startLessons: null,
};

export function lessonReducer(state: any = initialState, action) {
  switch (action.type) {


    case Lesson.GET_LESSON_INIT: {
      return {
        ...state,
        lessons: undefined
      };
    }
    
    case Lesson.GET_LESSON_SUCCESS: {
      return {
        ...state,
        lessons: action.payload.data
      };
    }
    
    case Lesson.ADD_LESSON_SUCCESS: {
      return {
        ...state,
        lessons: {...state.lessons, lessons: [...state.lessons.lessons, {...action.payload.data.lessons, tasksCount: 0}]}
      };
    }

    case Lesson.EDIT_LESSON_SUCCESS: {
      let lessonList;
      if(action.payload.data.lessonList) {
        lessonList = action.payload.data.lessonList
      } else {
        lessonList = state.lessons.lessons
      }

      const element = action.payload.data.lesson;
      const updatedData = lessonList.map((item) => {
        if (item.uuid === element.uuid) {
          return element;
        }
        return item;
      });
      // updatedData.sort((a, b) => a.index - b.index); 
      if(!action.payload.data.lessonList) {
        return {
          ...state,
          lessons: {
            ...state.lessons,
            lessons: updatedData,
          },
        };
      } else {
        return {
          ...state,
          lessons: {
            ...state.lessons,
            lessons: lessonList,
          },
        };
      }
    }

    case Lesson.UPDATE_SORT_SUCCESS: {
      const lessonState = state.lessons
      return {
        ...state,
        lessons: {
          ...lessonState,
          lessons: action.payload.data,
        },
      };
    }
    
    case Lesson.DELETE_LESSON_SUCCESS: {
      const element = action.payload.data.lesson;
      const lessonState = state.lessons.lessons;
      const updatedData = lessonState.filter(item => item.uuid !== element.uuid);

      return {
        ...state,
        lessons: {...state.lessons, lessons: updatedData}
      };
    }
    case Lesson.ADD_TASK_SUCCESS: {
      const element = action.payload.data;
      const lessonState = state.lessons
      const updatedData = lessonState.lessons.map((item) => {
        if (item.id === element.lessonId) {
          return {...item, tasksCount: item.tasksCount + 1}; 
        }
        return item;
      });

      
      return {
        ...state,
        lessons: {
          ...lessonState,
          lessons: updatedData,
        },
      };
    }
    case Lesson.REDUCTION_TASK_SUCCESS: {
      const element = action.payload.data;
      const lessonState = state.lessons
      const updatedData = lessonState.lessons.map((item) => {
        if (item.id === element.lessonId) {
          return {...item, tasksCount: item.tasksCount - 1}; 
        }
        return item;
      });
      return {
        ...state,
        lessons: {
          ...lessonState,
          lessons: updatedData,
        },
      };
    }

    case Lesson.START_LESSONS_SUCCESS: {
      return {
        ...state,
        startLessons: action.payload.data,
      }
    }

    case Lesson.GET_CLIENT_LESSONS_SUCCESS: {
      return {
        ...state,
        lessons: action.payload.data,
      }
    }


    default: {
      return state;
    }
  }
}
