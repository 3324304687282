import { connect } from 'react-redux';
import TransactionSuccess from './Main';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { getAuthorizedUser } from 'Store/Actions/Auth';

type ConnectedP = {
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    getAuthorizedUser: () => (dispatch: any) => Promise<any>;
  };
};
const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: getAuthorizedUser,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSuccess);
