import YouTube from 'react-youtube';
import { USER_TYPE, SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { SVG } from 'Components';

type LessonExplanationType = {
  userType: any;
  activeTask: any;
  isUserStandard: any;
  isUserDemo: any;
  showNotify: any;
};

const LessonExplanation = ({ userType, activeTask, isUserStandard, isUserDemo, showNotify }: LessonExplanationType) => {
  const navigate = useNavigate();
  const image = activeTask?.question?.file?.url;
  const youtubeLink = activeTask.question?.youtubeLink;
  const isActiveExplanation = userType;
  const isPremiumOrImpuls = userType === USER_TYPE.PREMIUM || userType === USER_TYPE.STUDENT_IMPULS;
  const isMobile = window.innerWidth < 1024;

  const optionsYoutube = {
    height: '100%',
    width: '100%',
  };

  function getYouTubeVideoId(url) {
    if (url && url.indexOf('youtube.com') !== -1) {
      const urlParams = new URLSearchParams(new URL(url).search);
      const videoId = urlParams.get('v');
      return videoId;
    } else if (url.indexOf('youtu.be') !== -1) {
      const videoId = url.split('/').pop();
      return videoId;
    } else {
      return null;
    }
  }

  return (
    <>
      {!isPremiumOrImpuls && !image && youtubeLink ? null : (
        <>
          {isMobile && (
            <div className={`lesson-explanation ${isActiveExplanation ? 'lesson-explanation--active' : ''}`}>
              <p className="lesson-explanation__title">Wyjaśnienie</p>
              <p className="lesson-explanation__description">{activeTask.question.explanation}</p>
              {image && <img src={image} alt="" className="lesson-explanation__image" />}
              {isPremiumOrImpuls && youtubeLink && (
                <div className="lesson-explanation__youtube">
                  <YouTube videoId={getYouTubeVideoId(youtubeLink)} opts={optionsYoutube} />
                </div>
              )}
            </div>
          )}
        </>
      )}
      {isUserDemo ? (
        showNotify && (
          <div className="call-to-action">
            <div className="call-to-action--left">
              <SVG className="call-to-action__icon" type={SVG_TYPE.STAR} />
            </div>
            <div className="call-to-action--right">
              Chcesz mieć dostęp do większej ilości lekcji?
              <span
                className="call-to-action--action"
                onClick={() => {
                  sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
                  navigate({ pathname: ROUTES.LOGIN_PAGE });
                }}>
                Załóż konto <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
              </span>
            </div>
          </div>
        )
      ) : (
        <>
          {isUserStandard && showNotify && (
            <div className="call-to-action">
              <div className="call-to-action--left">
                <SVG className="call-to-action__icon" type={SVG_TYPE.STAR} />
              </div>
              <div className="call-to-action--right">
                <span className="call-to-action--text">Chcesz mieć dostęp do rozszerzonych wyjaśnień?</span>
                <span
                  className="call-to-action--action"
                  onClick={() => {
                    navigate({ pathname: ROUTES.MYPROFILE_PACKET });
                  }}>
                  Wykup pakiet premium <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
                </span>
              </div>
            </div>
          )}
        </>
      )}
      {isMobile && !isPremiumOrImpuls && !image && youtubeLink ? null : (
        <>
          {!isMobile && (
            <div className={`lesson-explanation ${isActiveExplanation ? 'lesson-explanation--active' : ''}`}>
              <p className="lesson-explanation__title">Wyjaśnienie</p>
              <p className="lesson-explanation__description">{activeTask.question.explanation}</p>
              {image && <img src={image} alt="" className="lesson-explanation__image" />}
              {isPremiumOrImpuls && youtubeLink && (
                <div className="lesson-explanation__youtube">
                  <YouTube videoId={getYouTubeVideoId(youtubeLink)} opts={optionsYoutube} />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LessonExplanation;
