import { USER_ROLES } from 'Shared/enums';
import { store } from 'Store/Store';
import { Navigate } from 'react-router';

type PrivateRouteProps = {
  children: JSX.Element;
  routeFor: USER_ROLES.ADMIN | USER_ROLES.CLIENT | 'ALL';
  redirectPath: string;
};

const PrivateRoute = ({ children, routeFor, redirectPath }: PrivateRouteProps) => {
  const user = store.getState().authStore.authorizedUser;

  if (routeFor !== user.role && routeFor !== 'ALL') {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
export default PrivateRoute;
