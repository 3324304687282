import { Button, Modal } from 'Components';

type RemoveLessonType = {
  isOpen: boolean;
  onClose: () => void;
  lesson: any;
  deleteLesson: any;
};

const RemoveLesson = ({ isOpen, onClose, lesson, deleteLesson }: RemoveLessonType) => {
  const handleRemoveLesson = async () => {
    await deleteLesson(lesson.uuid);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-mobile--full">
      <h2 className="edit-user-modal__header">Usuwanie lekcji</h2>
      <p className="remove-lesson__descript">Czy na pewno chcesz usunać lekcję:</p>
      <p className="remove-lesson__descript remove-lesson__bold">Nazwa lekcji: {lesson?.name}</p>
      <p className="remove-lesson__descript remove-lesson__bold">Ilość zadań: {lesson?.tasksCount}</p>
      <div className="remove-lesson__buttons">
        <Button label="Anuluj" type="button" onClick={() => onClose()} stroke />
        <Button label="Potwierdź" type="submit" onClick={() => handleRemoveLesson()} />
      </div>
    </Modal>
  );
};

export default RemoveLesson;
