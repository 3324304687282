import { Button, Modal } from 'Components';
import { ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router-dom';
import { generatePath } from 'react-router-dom';

type DailyLimitType = {
  isOpen: boolean;
  isUserStandard: any;
  isUserDemo: any;
};

const DailyLimit = ({ isOpen, isUserDemo, isUserStandard }: DailyLimitType) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} className={`edit-user-modal modal-mobile--full`}>
      {isUserDemo && (
        <>
          <div className="daily-limit">
            <h2 className="edit-user-modal__header daily-limit--header">Ups! Wykorzystałeś Twój dzienny limit zadań 🫢</h2>
            <p className="daily-limit__description">Użytkownik DEMO ma dostęp jedynie do 10 zadań dziennie z pierwszej lekcji każdego działu.</p>
            <p className="daily-limit__description">
              Jeśli chcesz mieć dostęp do większej ilości lekcji i zadań — <b>załóż konto</b>. Jest to całkowicie <b>bezpłatne</b> i zajmie{' '}
              <b>jedynie 1 minutę.</b>
            </p>
            <span className="daily-limit__icons">🚗 🚚 🛵 🏍️</span>
          </div>
          <div className="daily-limit__buttons">
            <Button
              label="Wróć do działów"
              stroke
              onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) })}
            />
            <Button
              label="Załóż konto"
              onClick={() => {
                navigate({ pathname: ROUTES.LOGIN_PAGE });
                sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
              }}
            />
          </div>
        </>
      )}

      {isUserStandard && (
        <>
          <div className="daily-limit">
            <h2 className="edit-user-modal__header daily-limit--header">Ups! Wykorzystałeś Twój dzienny limit zadań 🫢</h2>
            <p className="daily-limit__description">
              Użytkownik STANDARD ma dostęp jedynie do 60 zadań dziennie z 3 pierwszych lekcji każdego działu.
            </p>
            <p className="daily-limit__description">
              Jeśli chcesz mieć dostęp do wszystkich lekcji i zadań — <b>wykup pakiet PREMIUM </b>(już od 9zł).
            </p>
            <span className="daily-limit__icons">⭐️ 🚗 🚚 🛵 🏍️</span>
          </div>
          <div className="daily-limit__buttons">
            <Button
              label="Wróć do działów"
              stroke
              onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) })}
            />
            <Button
              label="Pakiet PREMIUM"
              onClick={() => {
                navigate({ pathname: ROUTES.MYPROFILE_PACKET });
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default DailyLimit;
