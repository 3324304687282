import * as Types from 'Shared/models/TaskModel';
import { Task } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.TaskStore;

export const initialState: State = {
  tasksByLesson: null,
  saveTasks: null,
  singleSaveTask: null
};

export function taskReducer(state: any = initialState, action) {
  switch (action.type) {

    case Task.GET_TASKS_BY_LESSON_SUCCESS: {
      return {
        ...state,
        tasksByLesson: action.payload.data
      };
    }

    case Task.ADD_TASK_SUCCESS: {
      const addedElement = action.payload.data;
      const tasksState = state.tasksByLesson;

      return {
        ...state,
        tasksByLesson: {...tasksState, tasks: [...tasksState.tasks, addedElement]}
      };
    }

    case Task.SAVE_TASK_SUCCESS: {
      const saveTaskUuid = action.payload.data.uuid;
      return {
        ...state,
        saveTasks: {meta: state.saveTasks.meta, data: state.saveTasks.data.filter((item) => item.uuid !== saveTaskUuid)}
      };
    }

    case Task.DELETE_TASK_SUCCESS: {
      const deletedIndex = action.payload.data;
      const tasksState= state.tasksByLesson
      tasksState.tasks.splice(deletedIndex, 1);
      return {
        ...state,
        tasksByLesson: { ...tasksState, tasks: tasksState.tasks}
      };
    }
    
    case Task.GET_SAVE_TASKS_SUCCESS: {
      return {
        ...state,
        saveTasks: action.payload.data,
      };
    }
        
    case Task.GET_SINGLE_SAVE_TASK_SUCCESS: {
      return {
        ...state,
        singleSaveTask: action.payload.data,
      };
    }
    
    case Task.GET_SAVE_TASKS_INIT: {
      return {
        ...state,
        saveTasks: null,
      };
    }
    
    case Task.CHANGE_ORDER_TASK_SUCCESS: {
      let taskList;
      const tasksState = state.tasksByLesson

      
      if(action.payload.taskList) {
        taskList = action.payload.taskList;
      } else {
        taskList = tasksState
      }
      const element = action.payload.data.task;
      const updatedData = taskList.map((item) => {
        if (item.uuid === element.uuid) {
          return element;
        }
        return item;
      });
      
      if(!action.payload.data.taskList) {
        return {
          ...state,
          tasksByLesson: {
            ...state.tasksByLesson,
            tasks: updatedData,
          },
        };
      } else {
        return {
          ...state,
          tasksByLesson: {
            ...state.tasksByLesson,
            tasks: taskList,
          },
        };
      }
    }
    
    default: {
      return state;
    }
  }
}
