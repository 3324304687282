import * as Departments from 'Modules/DepartmentModule';
import { Department } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Department.ADD_DEPARTMENT_PHOTO_INIT,
});

export const success = (data) => ({
  type: Department.ADD_DEPARTMENT_PHOTO_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Department.ADD_DEPARTMENT_PHOTO_FAILURE,
});

const addDepartmentPhoto =
  (uuid, file, update = false) =>
  async (dispatch) => {
    dispatch(init());
    const id = toast.loading(update ? 'Trwa edycja zdjęcia kategorii...' : 'Trwa dodawanie zdjęcia kategorii...');
    try {
      const formData = new FormData();
      formData.append('file', file[0]);
      const response = await Departments.Connector.addDepartmentPhoto(uuid, formData);
      toast.update(id, {
        render: update ? 'Pomyślnie edytowano zdjęcie kategorii' : 'Pomyślnie dodano zdjęcie do kategorii',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
      });
      dispatch(success(response.data));
      dispatch(success(response));
      return response.data;
    } catch (err) {
      dispatch(failure());
      return false;
    }
  };

export default addDepartmentPhoto;
