import { useState, useEffect } from 'react';
import { CallToAction } from 'Screens/LearningPage/components';
import { SVG } from 'Components';
import { USER_ROLES, USER_TYPE, SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type StatisticType = {
  user: any;
  learningInfo: any;
};

const Statistic = ({ user, learningInfo }: StatisticType) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isDemo = user.role === USER_ROLES.CLIENT && user.type === USER_TYPE.DEMO;
  const isPremiumOrImpuls = user.role === USER_ROLES.CLIENT && (user.type === USER_TYPE.PREMIUM || user.type === USER_TYPE.STUDENT_IMPULS);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 500);
  }, []);

  return (
    <div className="statistic__wrapper">
      <div className="statistic__content">
        <CallToAction user={user} />
        <div className="statistic__boxes">
          {isLoading && learningInfo ? (
            <>
              <div className="statistic__box">
                <div className="statistic__box-paragraph">Ilość zadań, jaką możesz jeszcze dzisiaj wykonać</div>
                <div
                  className={`statistic__box-counter ${
                    learningInfo?.numberOfAvailableTasks === 'unlimited' ? 'statistic__box-counter--unlimited' : ''
                  }`}>
                  {learningInfo?.numberOfAvailableTasks} {learningInfo?.numberOfAvailableTasks === 'unlimited' ? '⭐️' : ''}
                </div>
                <div className="statistic__box-icon">🚦</div>
              </div>
              <div className="statistic__box">
                <div className="statistic__box-paragraph">% przerobionej bazy</div>
                <div className="statistic__box-counter">{learningInfo?.questionAnsweredPercentage}</div>
                <div className="statistic__box-icon">🚀</div>
              </div>
            </>
          ) : (
            <>
              <div className="statistic__box statistic__box--skeleton">
                <Skeleton height="158px" />
              </div>
              <div className="statistic__box statistic__box--skeleton">
                <Skeleton height="158px" />
              </div>
            </>
          )}
        </div>
        {!isDemo && (
          <>
            {isLoading && learningInfo ? (
              <div className="statistic__effectiveness">
                <div className="statistic__effectiveness-header">Poprawne rozwiązania ✓ </div>
                <div className="statistic__effectiveness-counter">{learningInfo?.correctSolution}</div>
                <div className="statistic__effectiveness-paragraph">Twoja skuteczność: {learningInfo?.questionAnsweredCorrectly}</div>
              </div>
            ) : (
              <div className="statistic__effectiveness statistic__effectiveness--skeleton">
                <Skeleton height="164px" />
              </div>
            )}
          </>
        )}

        <>
          {isLoading && learningInfo ? (
            <>
              {isPremiumOrImpuls && learningInfo?.lastLessons && learningInfo?.lastLessons.length > 0 && (
                <div className="statistic__lesson-list">
                  <p className="exam-page__lesson-list-heading statistic__lesson-list--heading">lista lekcji które powinieneś powtórzyć:</p>
                  {isPremiumOrImpuls &&
                    learningInfo?.lastLessons &&
                    learningInfo?.lastLessons.length > 0 &&
                    learningInfo?.lastLessons.map((item) => {
                      return (
                        <div className="exam-page__lesson-list" key={item.uuid}>
                          <div className="exam-page__lesson-item">
                            <div className="exam-page__lesson-item__heading">{item.name}</div>
                            <div className="exam-page__lesson-item__counter">ilość zadań: {item.tasksCount}</div>
                            <div
                              className="exam-page__lesson-item__chevrone"
                              onClick={() => navigate({ pathname: ROUTES.LESSON.replace('uuid', item.uuid) })}>
                              <SVG type={SVG_TYPE.CHEVRONE} />
                            </div>
                            <div
                              className="exam-page__lesson-item__onclick"
                              onClick={() => navigate({ pathname: ROUTES.LESSON.replace('uuid', item.uuid) })}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          ) : (
            <div className="statistic__lesson-list statistic__lesson-list--skeleton">
              <Skeleton height="172px" />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Statistic;
