import * as Lessons from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Lesson.EDIT_LESSON_INIT,
});

export const success = (data) => ({
  type: Lesson.EDIT_LESSON_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.EDIT_LESSON_FAILURE,
});

const editLesson =
  (uuid, data, sort = false, lessonList = false) =>
  async (dispatch) => {
    const id = !sort && toast.loading('Trwa edycja lekcji...');
    dispatch(init());

    try {
      const response = await Lessons.Connector.editLesson(uuid, data);
      dispatch(success({ ...response.data, last: sort, data, lessonList }));
      !sort &&
        toast.update(id, {
          render: 'Pomyślnie edytowano lekcję',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
          icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
        });
      return response.data;
    } catch (err) {
      dispatch(failure());
      toast.update(id, {
        render: 'Nie udało się edytować lekcji',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
      });
      return false;
    }
  };

export default editLesson;
