import * as Tasks from 'Modules/TaskModule';
import { Task } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Task.CHANGE_ORDER_TASK_INIT,
});

export const success = (data, taskList) => ({
  type: Task.CHANGE_ORDER_TASK_SUCCESS,
  payload: {
    data,
    taskList,
  },
});

const failure = () => ({
  type: Task.CHANGE_ORDER_TASK_FAILURE,
});

const changeTaskOrder =
  (uuid, data, taskList = false) =>
  async (dispatch) => {
    dispatch(init());

    try {
      const response = await Tasks.Connector.changeTaskOrder(uuid, data);
      dispatch(success(response.data, taskList));

      return response.data;
    } catch (err) {
      dispatch(failure());
      return false;
    }
  };

export default changeTaskOrder;
