import * as QuestionModule from 'Modules/QuestionModule';
import { Question } from '../EnumTypes/ActionEnumTypes';

import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Question.EDIT_QUESTION_INIT,
});

export const success = (data) => ({
  type: Question.EDIT_QUESTION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Question.EDIT_QUESTION_FAILURE,
});

const editQuestion = (uuid, data) => async (dispatch) => {
  data?.file && data?.file.length > 0
    ? await dispatch(QuestionModule.Actions.questionFile(uuid, data?.file[0]))
    : data?.file?.id
    ? null
    : await dispatch(QuestionModule.Actions.questionFile(uuid, null));
  dispatch(init());
  const id = toast.loading('Trwa edycja...');
  try {
    const response = await QuestionModule.Connector.editQuestion(uuid, data);
    dispatch(success(response.data.question));
    toast.update(id, {
      render: 'Pomyślnie edytowano pytanie',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });

    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się edytować pytania',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default editQuestion;
