import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

export const success = (users) => ({
  type: User.SEND_MAIL_SUCCESS,
  payload: {
    users,
  },
});

const failure = () => ({
  type: User.SEND_MAIL_FAILURE,
});

const sendMail = (form) => async (dispatch) => {
  try {
    const response = await UserModule.Connector.sendMail(form);
    dispatch(success(response.data.usersSerialize));
    return response.data.user.uuid;
  } catch (err: any) {
    dispatch(failure());
    return err?.response?.data.errors;
  }
};

export default sendMail;
