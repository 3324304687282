import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

export const success = (data) => ({
  type: User.MANAGE_USER_SUSPENSION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.MANAGE_USER_SUSPENSION_FAILURE,
});

const manageUserSuspension = (uuid) => async (dispatch) => {
  try {
    const response = await UserModule.Connector.manageUserSuspension(uuid);
    dispatch(success(response.data.user));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default manageUserSuspension;
