import { Button, SVG } from 'Components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

const QUESTION_TYPE = {
  PODSTAWOWY: 'Pytanie podstawowe',
  SPECJALISTYCZNY: 'Pytanie specjalistyczne',
};

type LessonResultType = {
  summaryLesson: any;
};

const LessonResult = ({ summaryLesson }: LessonResultType) => {
  const { isPassed, isAvailableNextLesson, nextLesson, lastLesson } = summaryLesson;
  const navigate = useNavigate();

  const renderFinishButton = () => {
    if (lastLesson && isPassed) {
      null;
    } else {
      if (isPassed) {
        if (isAvailableNextLesson) {
          return (
            <Button
              label="Następna lekcja"
              onClick={() => {
                navigate({ pathname: ROUTES.LESSON.replace('uuid', nextLesson) });
                window.location.reload();
              }}
            />
          );
        } else {
          return <Button label="Wróć do działów" onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) })} />;
        }
      } else
        return (
          <Button
            label="Powtórz lekcję"
            onClick={() => {
              localStorage.removeItem('lessonPage');
              window.location.reload();
            }}
          />
        );
    }
  };

  return (
    <div className="lesson-result">
      <div className="lesson-result__content">
        <div className="lesson-result__top">
          <div className="lesson-result__icon">{summaryLesson.isPassed ? <SVG type={SVG_TYPE.SUPRISE} /> : <SVG type={SVG_TYPE.SAD} />}</div>
          <div className="lesson-result__description">
            {summaryLesson.isPassed
              ? 'Gratulacje! Wykonałeś wszystkie zadania poprawnie i zaliczyłeś lekcję.'
              : 'Niestety, nie udało Ci się. Aby zaliczyć lekcję musisz wykonać wszystkie zadania poprawnie.'}
          </div>
          <div className="lesson-result__score">
            Wynik: {summaryLesson.answerCorrectly}/{summaryLesson.tasksCount}
          </div>
          <div className="lesson-result__button">{renderFinishButton()}</div>
        </div>
        <div className="lesson-result__paragraph">Twoje odpowiedzi</div>
        <div className="answersModal__list">
          {summaryLesson.tasks.map((item) => {
            return (
              <div key={item.uuid} className={`answersModal-item`}>
                <div className="answersModal-item--left">
                  <div className="answersModal-item__question">{item.question.question}</div>
                  <div className="answersModal-item__others">
                    <div>{item.id}</div>
                    <div>{item.question.pointsNumber} pkt.</div>
                    <div>{QUESTION_TYPE[item.question.type]}</div>
                  </div>
                </div>
                <div className="answersModal-item--right">
                  {item.isAnsweredCorrectly ? <SVG type={SVG_TYPE.CIRCLE_GREEN} /> : <SVG type={SVG_TYPE.CIRCLE_RED} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LessonResult;
