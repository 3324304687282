import { connect } from 'react-redux';
import LearningPage from './Main';
import { bindActionCreators } from 'redux';
import { adminLogin } from 'Store/Actions/Auth';
import { getClientDepartments, getClientLearningInfo } from 'Store/Actions/Department';
import { getDepartment, getLearningInfo } from 'Store/Selectors/DepartmentSelector'
import { User } from 'Shared/models/UserModel';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { getSaveTasks } from 'Store/Actions/Task'
import { saveTask } from 'Store/Actions/Lesson';
import { getSaveTasks as getSaveTasksSelector } from 'Store/Selectors/TaskSelector'

type ConnectedP = {
  departments: any;
  learningInfo: any;
  authorizedUser: User | 'init';
  saveTasks: any;
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    getClientDepartments: (params) => (dispatch: any) => any;
    getSaveTasks: (page) => any;
    getClientLearningInfo: () => any;
    saveTask: (data) => (dispatch: any) => any;

  };
};
const mapStateToProps = (state) => ({
  departments: getDepartment(state),
  authorizedUser: authorizedUser(state),
  learningInfo: getLearningInfo(state),
  saveTasks: getSaveTasksSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      adminLogin: adminLogin,
      getClientDepartments: getClientDepartments,
      getSaveTasks: getSaveTasks,
      getClientLearningInfo: getClientLearningInfo,
      saveTask: saveTask,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(LearningPage);
