import { useEffect } from 'react';
import { SVG_TYPE } from 'Shared/enums';
import { SVG, Pagination } from 'Components';
import { useNavigate } from 'react-router-dom';
import { MainType } from './Container';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function AllCitiesPage(props: MainType) {
  const navigate = useNavigate();
  const { actions, allUserCities } = props;
  const { getAllUserCity } = actions;
  const skeletonList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    !allUserCities && getAllUserCity(1);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const changePage = (page) => {
    getAllUserCity(page);
  };

  return (
    <>
      <header className="dashboard__header learning-page__header">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
        <div className="dashboard__container departments-page__header-container">
          <h1 className="dashboard__title departments-page__title user-statistics--page-title">
            Wszystkie miasta
            <div onClick={goBack} className="dashboard__goBack">
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          </h1>
        </div>
      </header>
      <div className="dashboard__content learning-page">
        <div className="dashboard__container learning-page__container">
          <table className="all-cities-page__table">
            <thead className="all-cities-page__thead">
              <tr>
                <th></th>
                <th>Miasto</th>
                <th>Użytkownicy</th>
                <th>% użytkowników</th>
              </tr>
            </thead>
            <tbody className="all-cities-page__tbody">
              {allUserCities
                ? allUserCities &&
                  allUserCities.data.length > 0 &&
                  allUserCities.data.map((item) => {
                    return (
                      <tr key={item.uuid}>
                        <td className="all-cities-page__cell all-cities-page__cell--number">{item.index}</td>
                        <td className="all-cities-page__cell all-cities-page__cell--cities">{item.city}</td>
                        <td className="all-cities-page__cell all-cities-page__cell--price">{item.usersCount}</td>
                        <td className="all-cities-page__cell all-cities-page__cell--percentage">{item.userPercentage}</td>
                      </tr>
                    );
                  })
                : skeletonList.map((item) => (
                    <tr key={item}>
                      <Skeleton height={55} />
                    </tr>
                  ))}
            </tbody>
          </table>
          {allUserCities?.meta && (
            <Pagination
              className="questions-list--pagination"
              totalPages={allUserCities.meta.last_page}
              currentPage={allUserCities.meta.current_page}
              onPageChange={(page) => changePage(page)}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AllCitiesPage;
