import * as DrivingTestModule from 'Modules/DrivingTestModule';
import { DrivingTest } from '../EnumTypes/ActionEnumTypes';
import queryString from 'query-string';

const init = () => ({
  type: DrivingTest.GET_DRIVING_TEST_ANSWER_INIT,
});

export const success = (data) => ({
  type: DrivingTest.GET_DRIVING_TEST_ANSWER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DrivingTest.GET_DRIVING_TEST_ANSWER_FAILURE,
});

const getDrivingTestAnswer = (params) => async (dispatch) => {
  const queryParams = queryString.stringify(params);
  dispatch(init());
  try {
    const response = await DrivingTestModule.Connector.getDrivingTestAnswer(queryParams);
    dispatch(success(response.data));
    return response.data;
  } catch (err: any) {
    dispatch(failure());
    return err.response.status;
  }
};

export default getDrivingTestAnswer;
