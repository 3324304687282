import { connect } from 'react-redux';
import ChangePasswordPage from './Main';
import { bindActionCreators } from 'redux';
import { phonePrefixes } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { checkToken, recoverPassword } from 'Store/Actions/Auth';
type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
};

type DispatchedP = {
  actions: {
    recoverPassword: ({ newPassword, resetToken }) => (dispatch: any) => Promise<boolean>;
    checkToken: (token: string) => (dispatch: any) => Promise<boolean>;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      recoverPassword: recoverPassword,
      checkToken: checkToken,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
