import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = (data) => ({
  type: User.SET_USER_CATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.SET_USER_CATEGORY_FAILURE,
});

const setUserCategory = (categoryUuid: string, firstTime) => async (dispatch) => {
  const id = toast.loading(`${firstTime ? 'Trwa ustawianie kategorii' : 'Trwa zmiana kategorii...'}`);
  try {
    const response = await UserModule.Connector.setUserCategory(categoryUuid);
    dispatch(success(response.data.user));
    toast.update(id, {
      render: `${firstTime ? 'Pomyślnie ustawiono kategorię' : 'Pomyślnie zmieniono kategorię'}`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: `${firstTime ? 'Nie udało się ustawić kategorii' : 'Nie udało się zmienić kategorii'}`,
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default setUserCategory;
