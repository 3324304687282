import * as QuestionModule from 'Modules/QuestionModule';
import { Question } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Components';

export const success = (data) => ({
  type: Question.UPDATE_DATABASE_DATE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Question.UPDATE_DATABASE_DATE_FAILURE,
});

const updateDatabaseDate = (data) => async (dispatch) => {
  const id = toast.loading('Trwa aktualizacja daty...');

  try {
    const response = await QuestionModule.Connector.updateDatabaseDate(data);
    dispatch(success(response));
    toast.update(id, {
      render: 'Pomyślnie zmieniono datę',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się zmienić daty',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default updateDatabaseDate;
