import ValidationItem from 'Components/ValidationItem/ValidationItem';

type PasswordValidationProps = {
  password: string;
};

function PasswordValidation({ password }: PasswordValidationProps) {
  return (
    <div>
      <ValidationItem correct={/[A-Z]/.test(password)} label={'1 wielka litera'} />
      <ValidationItem correct={/[a-z]/.test(password)} label={'1 mała litera'} />
      <ValidationItem correct={/[!@#$%^&*(),.?":{}|<>]/.test(password)} label={'1 znak specjalny '} />
      <ValidationItem correct={/[0-9]/.test(password)} label={'1 liczba'} />
      <ValidationItem correct={password.replace(' ', '').length >= 8} label={'conajmniej 8 znaków'} />
    </div>
  );
}
export default PasswordValidation;
