import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getUserStore = (state: RootState) => state.userStore;
const usersList = createSelector(getUserStore, (state) => state.usersList);
const usersListMeta = createSelector(getUserStore, (state) => state.usersListMeta);
const drivingCategories = createSelector(getUserStore, (state) => state.drivingCategories);
const getUserExam = createSelector(getUserStore, (state) => state.userExam);
const getUserStats = createSelector(getUserStore, (state) => state.userStats);

export { usersList, usersListMeta, drivingCategories, getUserExam, getUserStats };
