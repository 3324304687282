import { useEffect, useState } from 'react';
import Button from 'Components/Button';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { MainType } from './Container';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';
import { Input } from 'Components/form';
import { Field, Formik } from 'formik';
import SVG from 'Components/SVG';
import { PasswordValidation } from 'Components';

function ActivateAccount(props: MainType) {
  const {
    actions: { checkToken, activateUser },
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [showError, setShowError] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const activateParam = urlParams.get('token');

  useEffect(() => {
    if (activateParam) {
      checkToken(activateParam)
        .then((response) => {
          !response && setIsLinkExpired(true);
        })
        .catch((error) => {
          error;
        });
    }
  }, [location]);

  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('passwords-match', VALIDATION_MESSAGES.PASSWORD_MISMATCH, function (value) {
        return this.parent.password === value;
      }),
    password: Yup.string()
      .min(8, '')
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('Capital Letter', '', function (value) {
        return /[A-Z]/.test(value);
      })
      .test('Lowercase Letter', '', function (value) {
        return /[a-z]/.test(value);
      })
      .test('Special Character', '', function (value) {
        return /[!@#$%^&*(),.?":{}|<>]/.test(value);
      })
      .test('Number', '', function (value) {
        return /[0-9]/.test(value);
      })
      .test('cannot-start-with-space', VALIDATION_MESSAGES.CANNOT_START_WITH_SPACE, function (value) {
        return !value?.startsWith(' ');
      }),
  });

  const onSubmit = async (values) => {
    const result = await activateUser({ password: values.password, activateToken: activateParam });
    if (result) {
      setIsPasswordSet(true);
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      {isPasswordSet ? (
        <>
          <div className="login-page page login-page--token-expired ">
            <main className="login-page__body login-page__body--without-flex">
              <>
                <div className="login-page__content-wrapper">
                  <div className="login-page__top-wrapper">
                    <h2 className="login-page__title">Hasło zostało ustawione</h2>
                  </div>
                  <p className="login-page__description">Możesz teraz zalogować się za pomocą hasła.</p>
                </div>
                <div className="login-page__buttons">
                  <Button
                    label={'Zaloguj się'}
                    onClick={() => {
                      navigate({ pathname: ROUTES.ADMIN_LOGIN_PAGE });
                    }}
                  />
                </div>
              </>
            </main>
          </div>
        </>
      ) : (
        <>
          {isLinkExpired ? (
            <div className="login-page page login-page--token-expired ">
              <main className="login-page__body login-page__body--without-flex">
                <>
                  <div className="login-page__content-wrapper">
                    <div className="login-page__top-wrapper">
                      <h2 className="login-page__title">Ten link wygasł</h2>
                    </div>
                    <p className="login-page__description">Aby otrzymać nowy link aktywacyjny skontaktuj się z administratorem serwisu.</p>
                  </div>
                  <div className="login-page__buttons">
                    <Button
                      label={'Zaloguj się'}
                      onClick={() => {
                        navigate({ pathname: ROUTES.ADMIN_LOGIN_PAGE });
                      }}
                    />
                  </div>
                </>
              </main>
            </div>
          ) : (
            <div className="login-page ">
              <Formik
                validationSchema={validationSchema}
                isInitialValid={false}
                initialValues={{
                  password: '',
                  confirmPassword: '',
                }}
                onSubmit={(values) => {
                  onSubmit(values);
                }}>
                {({ handleSubmit, isValid, errors, setFieldTouched, isSubmitting, values }) => (
                  <main className="login-page__body">
                    <div className="login-page__content-wrapper">
                      <h2 className="login-page__title">Utwórz hasło</h2>
                      <p className={`login-page__error ${showError ? 'login-page__error--show' : ''}`}>
                        <span>Błąd dodania hasła</span>
                        <SVG type={SVG_TYPE.WARNING} />
                      </p>

                      <form onSubmit={handleSubmit} className="login-page__form login-page__form--admin">
                        <Field
                          component={Input}
                          required
                          name="password"
                          onChange={() => setShowError(false)}
                          placeholder="Wprowadź hasło"
                          label="Hasło"
                          type="password"
                          errors={errors}
                          className={'login-page__input'}
                        />
                        <PasswordValidation password={values.password} />
                        <Field
                          component={Input}
                          required
                          name="confirmPassword"
                          onChange={() => setShowError(false)}
                          placeholder="Wprowadź hasło"
                          label="Powtórz hasło"
                          type="password"
                          errors={errors}
                          className={'login-page__input login-page__input-margin-top-34 '}
                        />
                      </form>
                    </div>
                    <div className="login-page__buttons">
                      <Button
                        label={'Potwierdź'}
                        // type="submit"
                        onClick={() => {
                          setFieldTouched('confirmPassword', true);
                          setFieldTouched('password', true);
                          isValid && !isSubmitting && onSubmit(values);
                        }}
                      />
                    </div>
                  </main>
                )}
              </Formik>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ActivateAccount;
