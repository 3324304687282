import 'react-responsive-carousel/lib/styles/carousel.min.css';

const GamificationPage = () => {
  return (
    <>
      <header className="dashboard__header gamification-page__header">
        <div className="dashboard__container">
          <h1 className="dashboard__title gamification-page__header--title">Grywalizacja</h1>
        </div>
      </header>
      <div className="dashboard__content gamification-page__content">
        <div className="gamification-page__empty">
          <p>Tutaj jeszcze nic nie ma, ale już wkrótce pojawi się możliwość grania i rywalizowania! 💪🏻😎</p>
          <span>Stay tuned!</span>
          <div>🎮 🏆 👑</div>
        </div>
      </div>
    </>
  );
};

export default GamificationPage;
