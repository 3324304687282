import { FieldProps } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);

import 'react-datepicker/dist/react-datepicker.css';

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value?: string;
  todayButton?: any;
  placeholderText?: any;
  shouldCloseOnSelect?: boolean;
  showPreviousMonths?: boolean;
  child?: any;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function CustomDatePicker({
  label,
  className = '',
  form,
  errors = {},
  field,
  onChange,
  todayButton,
  placeholderText,
  shouldCloseOnSelect,
  showPreviousMonths,
  child,
}: InputProps & FieldProps) {
  const handleOnChange = (e) => {
    form.setFieldValue(field.name, e);
    onChange && onChange(e);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight

  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  const tenYearsAgo = new Date(today.getTime() - 1 * 365 * 24 * 60 * 60 * 1000);

  const datePickerProps = {
    locale: 'pl',
    selected: field.value || null,
    minDate: showPreviousMonths ? tenYearsAgo : tomorrow,
    className: 'input',
    onChange: (val) => {
      handleOnChange(val);
    },
  };

  return (
    <div className={`input__wrapper ${className}`}>
      <label className="input__label" htmlFor={field?.name}>
        {label}
      </label>

      <DatePicker shouldCloseOnSelect={shouldCloseOnSelect} placeholderText={placeholderText} todayButton={todayButton} {...datePickerProps}>
        {child}
      </DatePicker>
      {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default CustomDatePicker;
