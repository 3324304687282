import * as Departments from 'Modules/DepartmentModule';
import { Department } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Department.GET_CLIENT_DEPARTMENTS_INIT,
});

export const success = (data) => ({
  type: Department.GET_CLIENT_DEPARTMENTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Department.GET_CLIENT_DEPARTMENTS_FAILURE,
});

const getClientDepartments = (params) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await Departments.Connector.getClientDepartments(params);
    dispatch(success(response.data.departments));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getClientDepartments;
