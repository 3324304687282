import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router-dom';
import { shortCategories } from 'Screens/AdminLearningPage/utils/shortCategories';

type HeaderType = {
  department: any;
};

const Header = ({ department }: HeaderType) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <header className="dashboard__header">
      <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
      <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
      <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
      <div className="admin-department-header">
        <div className="admin-department-header__content">
          <div className="admin-department-header__top">
            <p className="admin-department-header__heading">
              <span onClick={goBack}>
                <SVG type={SVG_TYPE.BACK_CHEVRON} />
              </span>
              Nauka, podgląd działu
            </p>
            <p className="admin-department-header__counters">
              Ilość lekcj: {department?.lessonsCount} Kategorie: {department?.categories && shortCategories(department?.categories)}
            </p>
          </div>
          <div className="admin-department-header__bottom">
            <div className="admin-department-header__department-name">{department?.name}</div>
            <div className="admin-department-header__department-badge">
              <div className={`department__badge ${status === 'active' ? 'department__badge--active' : 'department__badge--inactive'}`}>
                {department?.status === 'active' ? 'Opublikowany' : 'Nieopublikowany'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
