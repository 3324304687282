import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { CallToAction, AnimatedProgressProvider } from './components';
import { ROUTES, USER_TYPE, USER_ROLES, SVG_TYPE } from 'Shared/enums';
import { easeQuadInOut } from 'd3-ease';
import { useNavigate } from 'react-router';
import { MainType } from './Container';
import { Button, SVG } from 'Components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function ExamPage(props: MainType) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { actions, authorizedUser, userExam } = props;
  const { getUserExam } = actions;
  const percentage = userExam?.chanceOfPassingTheExam && userExam?.chanceOfPassingTheExam.replace(/%/g, '');
  const isAuthorizedUser = authorizedUser !== 'init' && !!authorizedUser;
  const isAvailableExam = isAuthorizedUser && authorizedUser.availableExam;
  const isExamAvailable = userExam?.availableExam;
  const isPremiumOrImpuls =
    isAuthorizedUser &&
    authorizedUser.role === USER_ROLES.CLIENT &&
    (authorizedUser.type === USER_TYPE.PREMIUM || authorizedUser.type === USER_TYPE.STUDENT_IMPULS);

  useEffect(() => {
    getUserExam();
  }, []);

  const MaxTodayExam = {
    standard: 10,
    demo: 3,
  };

  useEffect(() => {
    !userExam
      ? setTimeout(() => {
          setIsLoading(true);
        }, 1000)
      : setIsLoading(true);
  }, []);

  return (
    <>
      <div className="exam-page">
        <header className="dashboard__header">
          <div className="dashboard__container">
            <h1 className="dashboard__title dashboard__title--exam">Egzamin</h1>
          </div>
        </header>

        <div className="learning-page exam-page dashboard__content">
          <div className="dashboard__container exam-page__container">
            <CallToAction user={authorizedUser} />
            <div className="exam-page__box-wrapper">
              {isLoading && userExam ? (
                <>
                  <div className="exam-page__box">
                    <p className="exam-page__box-title">Ilość wykonanych egzaminów</p>
                    <p>{userExam?.numberOfCompletedExam}</p>
                    <div className="exam-page__box--img">📝</div>
                  </div>
                  <div className="exam-page__box">
                    <p className="exam-page__box-title">Ilość zdanych egzaminów</p>
                    <p>{userExam?.numberOfPassedExam}</p>
                    <div className="exam-page__box--img">🥳</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="exam-page__box exam-page__box--skeleton">
                    <Skeleton height="193px" />
                  </div>
                  <div className="exam-page__box exam-page__box--skeleton">
                    <Skeleton height="193px" />
                  </div>
                </>
              )}
            </div>
            {userExam && (
              <>
                <div
                  className={`exam-page__available-test ${isLoading && userExam ? '' : 'exam-page__available-test--skeleton'} ${
                    !userExam?.availableExam ? 'exam-page__available-test--disable' : ''
                  }`}>
                  {isLoading && userExam ? (
                    <>
                      <div className="exam-page__available-test__content">
                        <div className="exam-page__available-test__title">Ilość dostępnych prób dzisiaj</div>
                        {isPremiumOrImpuls ? (
                          <div className="exam-page__available-test__premium">unlimited ⭐️</div>
                        ) : (
                          <div
                            className={`exam-page__available-test__numbers ${!isExamAvailable ? 'exam-page__available-test__numbers--disable' : ''}`}>
                            {userExam?.numberOfAvailableExam}/{MaxTodayExam[isAuthorizedUser && authorizedUser?.type]}
                          </div>
                        )}
                        {isExamAvailable && (
                          <Button
                            label="Nowy egzamin"
                            disabled={!isAvailableExam}
                            onClick={() => navigate({ pathname: ROUTES.EXAMINATION })}
                            className="exam-page__button"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="exam-page__available-test__content exam-page__available-test__content--skeleton">
                        <Skeleton height="193px" />
                      </div>
                    </>
                  )}
                </div>
                {!isExamAvailable && (
                  <div className={`max-retries`}>
                    Wykorzystano dostępną ilość prób egzaminu <SVG type={SVG_TYPE.EXCLAMATION} />
                  </div>
                )}
                {userExam && isPremiumOrImpuls && percentage && (
                  <>
                    {userExam && isLoading ? (
                      <div className="exam-page__chances">
                        <div className="exam-page__chances-wrapper">
                          <p className="exam-page__descript exam-page__chances-title">Twoje szanse na zdanie egzaminu</p>
                          <AnimatedProgressProvider valueStart={0} valueEnd={percentage} duration={4} easingFunction={easeQuadInOut}>
                            {(value) => {
                              const roundedValue = Math.round(value);
                              let color;
                              if (percentage <= 50) {
                                color = 'rgba(255, 108, 108, 1)';
                              } else if (percentage <= 50 && percentage >= 80) {
                                color = `rgba(255, 146, 84, 1)`;
                              } else {
                                color = 'rgba(0, 180, 115, 1)';
                              }
                              return (
                                <CircularProgressbar
                                  value={value}
                                  text={`${roundedValue}%`}
                                  styles={buildStyles({
                                    pathTransition: 'none',
                                    pathColor: color,
                                    textColor: color,
                                    trailColor: 'rgba(216, 215, 255, 1)',
                                    textSize: '32px',
                                  })}
                                />
                              );
                            }}
                          </AnimatedProgressProvider>
                          <p className="exam-page__chances-text">
                            Niestety, prawdopodobieństwo zdania egzaminu jest niewielkie 😕 Przejdź do modułu nauki i zwiększ swoje szanse!
                          </p>
                          {userExam?.lastLessons && userExam?.lastLessons.length > 0 && (
                            <p className="exam-page__lesson-list-heading">lista lekcji które powinieneś powtórzyć:</p>
                          )}
                          <div className="exam-page__lesson-list">
                            {userExam?.lastLessons &&
                              userExam?.lastLessons.map(({ uuid, tasksCount, name }) => {
                                return (
                                  <div key={uuid} className="exam-page__lesson-item">
                                    <div className="exam-page__lesson-item__heading">{name}</div>
                                    <div className="exam-page__lesson-item__counter">ilość zadań: {tasksCount}</div>
                                    <div
                                      className="exam-page__lesson-item__chevrone"
                                      onClick={() => navigate({ pathname: ROUTES.LESSON.replace('uuid', uuid) })}>
                                      <SVG type={SVG_TYPE.CHEVRONE} />
                                    </div>
                                    <div
                                      className="exam-page__lesson-item__onclick"
                                      onClick={() => navigate({ pathname: ROUTES.LESSON.replace('uuid', uuid) })}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="exam-page__chances">
                        <div className="exam-page__chances-wrapper exam-page__chances-wrapper--skeleton">
                          <Skeleton height="607px" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExamPage;
