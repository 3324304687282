/* eslint-disable react/prop-types */
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { DropFileInput, Input, Select } from 'Components/form';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { drivingCategoryType } from 'Shared/models/UserModel';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { shortDrivingCategories } from 'Screens/AdminLearningPage/utils';

type AddDepartmentType = {
  isOpen: boolean;
  onClose: () => void;
  drivingCategories: drivingCategoryType[];
  addDepartment: (data) => any;
  addDepartmentPhoto: (uuid, file) => any;
  getDepartments: any;
};

function AddDepartment({ isOpen, onClose, drivingCategories, addDepartment, addDepartmentPhoto, getDepartments }: AddDepartmentType): JSX.Element {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .max(150, VALIDATION_MESSAGES.MAX150)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    avatar: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    status: Yup.mixed()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('arrayNotEmpty', VALIDATION_MESSAGES.REQUIRED, (value) => value && value.length > 0),
    categoryUuids: Yup.array()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('arrayNotEmpty', VALIDATION_MESSAGES.REQUIRED, (value) => value && value.length > 0),
  });

  const initialValues = {
    name: '',
    categoryUuids: [],
    status: 'inactive',
    avatar: '',
  };

  const handleOnSubmit = async (values, props) => {
    const avatar = values.avatar;
    const response = await addDepartment(values);
    if (response && avatar) {
      await addDepartmentPhoto(response.uuid, avatar);
      onClose();
      setTimeout(() => {
        props.resetForm();
      }, 400);
      getDepartments();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-mobile--full">
      <h2 className="edit-user-modal__header">Dodawanie działu </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, props) => handleOnSubmit(values, props)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field className={''} label="Nazwa działu" name="name" placeholder="Wpisz nazwę działu" required component={Input} errors={errors} />
            <Field
              className={''}
              label="Grafika"
              name="avatar"
              placeholder="Przeciągnij i upuść lub"
              required
              component={DropFileInput}
              errors={errors}
              onFileDrop={() => null}
              accept="image/*"
            />
            <Field
              component={Select}
              label="Kategoria"
              name="categoryUuids"
              options={shortDrivingCategories(drivingCategories)}
              errors={errors}
              className={'login-page__select'}
              required
              isMulti
              placeholder="Wybierz kategorię"
            />
            <Field
              component={Select}
              label="Status"
              name="status"
              options={[
                { label: 'Opublikowany', value: 'active' },
                { label: 'Nieopublikowany', value: 'inactive' },
              ]}
              errors={errors}
              className={'login-page__select'}
              required
            />

            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="edit-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Potwierdź" className="edit-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddDepartment;
