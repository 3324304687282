import { connect } from 'react-redux';
import SuccessfulPasswordResetLinkSendPage from './Main';
import { bindActionCreators } from 'redux';
import { phonePrefixes } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { adminLogin, sendPasswordResetLink } from 'Store/Actions/Auth';
type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    sendPasswordResetLink: ({ email }) => (dispatch: any) => Promise<boolean>;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      adminLogin: adminLogin,
      sendPasswordResetLink: sendPasswordResetLink,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulPasswordResetLinkSendPage);
