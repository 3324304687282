import { connect } from 'react-redux';
import AdminLearningPage from './Main';
import { bindActionCreators } from 'redux';
import { getDrivingCategory } from 'Store/Actions/User';
import { addDepartment, editDepartment, deleteDepartment, getDepartments, addDepartmentPhoto } from 'Store/Actions/Department';
import { drivingCategories} from 'Store/Selectors/UserSelector';
import { getDepartment} from 'Store/Selectors/DepartmentSelector';

type ConnectedP = {
  drivingCategories: any;
  departments: any;
};

type DispatchedP = {
  actions: {
    getDrivingCategory: () => (dispatch: any) => Promise<boolean>;
    getDepartments: (params) => any;
    editDepartment: (uuid,data) => any;
    deleteDepartment: (uuid) => any;
    addDepartment: (data) => any;
    addDepartmentPhoto: (uuid,file) => any;
  };
};
const mapStateToProps = (state) => ({
  drivingCategories: drivingCategories(state),
  departments: getDepartment(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getDrivingCategory: getDrivingCategory,
      addDepartment: addDepartment,
      getDepartments: getDepartments,
      editDepartment: editDepartment,
      deleteDepartment: deleteDepartment,
      addDepartmentPhoto: addDepartmentPhoto,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(AdminLearningPage);
