import * as AuthModule from 'Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.GET_SMS_CODE_INIT,
});

export const success = (code) => ({
  type: Auth.GET_SMS_CODE_SUCCESS,
  payload: {
    code,
  },
});

const failure = () => ({
  type: Auth.GET_SMS_CODE_FAILURE,
});

const getSmsCode =
  ({ phoneNumber, phonePrefixUuid, userUuid = false }) =>
  async (dispatch) => {
    dispatch(init());
    const id = toast.loading('Trwa wysyłanie kodu sms...');
    try {
      const response = await AuthModule.Connector.getSmsCode({ phoneNumber: phoneNumber.toString(), phonePrefixUuid, userUuid });
      dispatch(success(response.data));
      toast.update(id, {
        render: 'Kod wysłany',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
      });
      return true;
    } catch (err: any) {
      dispatch(failure());
      toast.update(id, {
        render: 'Nie udało się wysłać kodu sms',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
      return false;
    }
  };

export default getSmsCode;
