import * as LessonConnector from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Lesson.GET_LESSON_INIT,
});

export const success = (data) => ({
  type: Lesson.GET_LESSON_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.GET_LESSON_FAILURE,
});

const getLessons = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await LessonConnector.Connector.getLessons(uuid);
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getLessons;
