

import * as Types from 'Shared/models/QuestionModel';
import { Question } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.QuestionStore;

export const initialState: State = {
  questions: null,
  question: null,
  questionsByDepartment: null,
};

export function questionReducer(state: any = initialState, action) {
  switch (action.type) {
    case Question.GET_QUESTIONS_INIT: {
      return {
        ...state,
      };
    }
    case Question.GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questions: action.payload.data,
      };
    }
    case Question.GET_QUESTIONS_FAILURE: {
      return {
        ...state,
      };
    }
    case Question.EDIT_QUESTION_SUCCESS: {
      const updatedQuestion = action.payload.data;
      
      const updatedData = state.questions && state.questions.data && state.questions.data.map(question => {
        if (question.uuid === updatedQuestion.uuid) {
          return updatedQuestion;
        }
        return question;
      });
      
      return {
        ...state,
        questions: {
          ...state.questions,
          data: updatedData,
        },
        question:updatedQuestion
      };
    }
    case Question.GET_SINGLE_QUESTION_INIT: {
      return {
        ...state,
      };
    }
    case Question.GET_SINGLE_QUESTION_SUCCESS: {
      return {
        ...state,
        question: action.payload.data,
      };
    }
    case Question.GET_SINGLE_QUESTION_FAILURE: {
      return {
        ...state,
      };
    }

    case Question.GET_QUESTIONS_BY_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        questionsByDepartment: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
