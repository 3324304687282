import * as Types from 'Shared/models/PaymentModel';
import { Payment } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.PaymentStore;

export const initialState: State = {
  paymentsLabel: null,
};

export function paymentReducer(state: any = initialState, action) {
  switch (action.type) {
    case Payment.GET_PAYMENTS_LABEL_SUCCESS: {
      return {
        paymentsLabel: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
