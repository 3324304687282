import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as AuthModule from 'Modules/AuthModule';

export function login(smsCode): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/login', { smsCode });
}
export function adminLogin({ email, password }): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/login/admin', { email, password });
}
export function logout(): AxiosPromise<any> {
  return API.put('auth/logout');
  // config(null, { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` })
}

export function sendPasswordResetLink({ email }): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/recover', { email });
}

export function resetPassword({ newPassword, resetToken }): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.put('auth/recover', { password: newPassword, resetToken });
}

export function getAuthorizedUser(): AxiosPromise<AuthModule.Types.AuthorizedUserData> {
  return API.get('auth/me');
}
export function getSmsCode({ phoneNumber, phonePrefixUuid, userUuid }): AxiosPromise<any> {
  return API.post('auth/sms-code', { phoneNumber, phonePrefixUuid, userUuid });
}

export function getRefreshToken(refreshToken: string): AxiosPromise<any> {
  return API.post('auth/refresh', { refreshToken: refreshToken });
}

export function getPhonePrefixes(): AxiosPromise<any> {
  return API.get('phonePrefix/label');
}

export function changeCurrentUserPassword({ currentPassword, newPassword }): AxiosPromise<any> {
  return API.post('auth/password/change', { password: currentPassword, newPassword });
}

export function checkToken(token: string): AxiosPromise<any> {
  return API.get('auth/valid/token', { params: { token } });
}

export function resendActivateLink({ email }): AxiosPromise<any> {
  return API.post('auth/activate/resend-link', { email });
}

export function getCities(): AxiosPromise<any> {
  return API.get(`cities`);
}
export function activateUser(data): AxiosPromise<any> {
  return API.put('auth/activate', data);
}

export function loginAsDemo(userUuid): AxiosPromise<any> {
  return API.post('auth/login/demo', { userUuid });
}

export function createDemoUser(): AxiosPromise<any> {
  return API.post('users/demo');
}
