export enum Auth {
  LOG_IN_INIT = 'LOG_IN_INIT',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT_INIT = 'LOG_OUT_INIT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  // SEND_PASSWORD_RESET_LINK_INIT = 'SEND_PASSWORD_RESET_LINK_INIT',
  SEND_PASSWORD_RESET_LINK_SUCCESS = 'SEND_PASSWORD_RESET_LINK_SUCCESS',
  SEND_PASSWORD_RESET_LINK_FAILURE = 'SEND_PASSWORD_RESET_LINK_FAILURE',
  GET_REFRESH_TOKEN_INIT = 'GET_REFRESH_TOKEN_INIT',
  GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE',
  GET_SMS_CODE_INIT = 'GET_SMS_CODE_INIT',
  GET_SMS_CODE_SUCCESS = 'GET_SMS_CODE_SUCCESS',
  GET_SMS_CODE_FAILURE = 'GET_SMS_CODE_FAILURE',
  GET_PHONE_PREFIXES_INIT = 'GET_PHONE_PREFIXES_INIT',
  GET_PHONE_PREFIXES_SUCCESS = 'GET_PHONE_PREFIXES_SUCCESS',
  GET_PHONE_PREFIXES_FAILURE = 'GET_PHONE_PREFIXES_FAILURE',
  // RECOVER_PASSWORD_INIT = 'RECOVER_PASSWORD_INIT',
  RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE',

  CHANGE_CURRENT_USER_PASSWORD_SUCCESS = 'CHANGE_CURRENT_USER_PASSWORD_SUCCESS',
  CHANGE_CURRENT_USER_PASSWORD_FAILURE = 'CHANGE_CURRENT_USER_PASSWORD_FAILURE',

  CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE',

  EDIT_AVATAR_SUCCESS = 'EDIT_AVATAR_SUCCESS',
  EDIT_AVATAR_FAILURE = 'EDIT_AVATAR_FAILURE',

  RESEND_ACTIVATE_LINK_SUCCESS = 'RESEND_ACTIVATE_LINK_SUCCESS',
  RESEND_ACTIVATE_LINK_FAILURE = 'RESEND_ACTIVATE_LINK_FAILURE',

  GET_CITIES_INIT = 'GET_CITIES_INIT',
  GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS',
  GET_CITIES_FAILURE = 'GET_CITIES_FAILURE',

  ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE',

  LOGIN_AS_DEMO_SUCCESS = 'LOGIN_AS_DEMO_SUCCESS',
  LOGIN_AS_DEMO_FAILURE = 'LOGIN_AS_DEMO_FAILURE',

  CREATE_DEMO_USER_SUCCESS = 'CREATE_DEMO_USER_SUCCESS',
  CREATE_DEMO_USER_FAILURE = 'CREATE_DEMO_USER_FAILURE',

  DELETE_CURRENT_USER_SUCCESS = 'DELETE_CURRENT_USER_SUCCESS',
  DELETE_CURRENT_USER_FAILURE = 'DELETE_CURRENT_USER_FAILURE',
}

export enum ViewManagement {
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  SET_PREV_PATH = 'SET_PREV_PATH',
  EXPAND_MENU = 'EXPAND_MENU',
  EXPAND_ADD_REMINDER_VIEW = 'EXPAND_ADD_REMINDER_VIEW',
  OPEN_COMPENDIUM_PREVIEW_MOBILE = 'OPEN_COMPENDIUM_PREVIEW_MOBILE',
  CLOSE_COMPENDIUM_PREVIEW_MOBILE = 'CLOSE_COMPENDIUM_PREVIEW_MOBILE',
}

export enum User {
  GET_USERS_INIT = 'GET_USERS_INIT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',

  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',

  ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE',

  MANAGE_USER_SUSPENSION_SUCCESS = 'MANAGE_USER_SUSPENSION_SUCCESS',
  MANAGE_USER_SUSPENSION_FAILURE = 'MANAGE_USER_SUSPENSION_FAILURE',

  EDIT_AVATAR_SUCCESS = 'EDIT_AVATAR_SUCCESS',
  EDIT_AVATAR_FAILURE = 'EDIT_AVATAR_FAILURE',

  ADD_AVATAR_SUCCESS = 'ADD_AVATAR_SUCCESS',
  ADD_AVATAR_FAILURE = 'ADD_AVATAR_FAILURE',

  GET_DRIVING_CATEGORY_SUCCESS = 'GET_DRIVING_CATEGORY_SUCCESS',
  GET_DRIVING_CATEGORY_FAILURE = 'GET_DRIVING_CATEGORY_FAILURE',

  SET_USER_CATEGORY_SUCCESS = 'SET_USER_CATEGORY_SUCCESS',
  SET_USER_CATEGORY_FAILURE = 'SET_USER_CATEGORY_FAILURE',

  CHANGE_USER_NAME_SUCCESS = 'CHANGE_USER_NAME_SUCCESS',
  CHANGE_USER_NAME_FAILURE = 'CHANGE_USER_NAME_FAILURE',

  DELETE_CURRENT_USER_SUCCESS = 'DELETE_CURRENT_USER_SUCCESS',
  DELETE_CURRENT_USER_FAILURE = 'DELETE_CURRENT_USER_FAILURE',

  USER_RESTORATION_SUCCESS = 'USER_RESTORATION_SUCCESS',
  USER_RESTORATION_FAILURE = 'USER_RESTORATION_FAILURE',

  GET_USER_EXAM_INIT = 'GET_USER_EXAM_INIT',
  GET_USER_EXAM_SUCCESS = 'GET_USER_EXAM_SUCCESS',
  GET_USER_EXAM_FAILURE = 'GET_USER_EXAM_FAILURE',

  CHANGE_USER_EMAIL_INIT = 'CHANGE_USER_EMAIL_INIT',
  CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS',
  CHANGE_USER_EMAIL_FAILURE = 'CHANGE_USER_EMAIL_FAILURE',

  GET_USER_STATS_INIT = 'GET_USER_STATS_INIT',
  GET_USER_STATS_SUCCESS = 'GET_USER_STATS_SUCCESS',
  GET_USER_STATS_FAILURE = 'GET_USER_STATS_FAILURE',

  SEND_MAIL_INIT = 'SEND_MAIL_INIT',
  SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS',
  SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE',
}

export enum Order {
  CREATE_ORDER_INIT = 'CREATE_ORDER_INIT',
  CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE',
}

export enum DrivingTest {
  GET_DRIVING_TEST_INIT = 'GET_DRIVING_TEST_INIT',
  GET_DRIVING_TEST_SUCCESS = 'GET_DRIVING_TEST_SUCCESS',
  GET_DRIVING_TEST_FAILURE = 'GET_DRIVING_TEST_FAILURE',
  GET_DRIVING_TEST_ANSWER_INIT = 'GET_DRIVING_TEST_ANSWER_INIT',
  GET_DRIVING_TEST_ANSWER_SUCCESS = 'GET_DRIVING_TEST_ANSWER_SUCCESS',
  GET_DRIVING_TEST_ANSWER_FAILURE = 'GET_DRIVING_TEST_ANSWER_FAILURE',
  FINISH_DRIVING_TEST_INIT = 'FINISH_DRIVING_TEST_INIT',
  FINISH_DRIVING_TEST_SUCCESS = 'FINISH_DRIVING_TEST_SUCCESS',
  FINISH_DRIVING_TEST_FAILURE = 'FINISH_DRIVING_TEST_FAILURE',
  GET_SINGLE_SUMMARY_INIT = 'GET_SINGLE_SUMMARY_INIT',
  GET_SINGLE_SUMMARY_SUCCESS = 'GET_SINGLE_SUMMARY_SUCCESS',
  GET_SINGLE_SUMMARY_FAILURE = 'GET_SINGLE_SUMMARY_FAILURE',
}

export enum Question {
  GET_QUESTIONS_INIT = 'GET_QUESTIONS_INIT',
  GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE',
  GET_QUESTIONS_BY_DEPARTMENT_INIT = 'GET_QUESTIONS_BY_DEPARTMENT_INIT',
  GET_QUESTIONS_BY_DEPARTMENT_SUCCESS = 'GET_QUESTIONS_BY_DEPARTMENT_SUCCESS',
  GET_QUESTIONS_BY_DEPARTMENT_FAILURE = 'GET_QUESTIONS_BY_DEPARTMENT_FAILURE',
  EDIT_QUESTION_INIT = 'EDIT_QUESTION_INIT',
  EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_FAILURE = 'EDIT_QUESTION_FAILURE',
  GET_SINGLE_QUESTION_INIT = 'GET_SINGLE_QUESTION_INIT',
  GET_SINGLE_QUESTION_SUCCESS = 'GET_SINGLE_QUESTION_SUCCESS',
  GET_SINGLE_QUESTION_FAILURE = 'GET_SINGLE_QUESTION_FAILURE',
  QUESTION_FILE_SUCCESS = 'QUESTION_FILE_SUCCESS',
  QUESTION_FILE_FAILURE = 'QUESTION_FILE_FAILURE',
  UPDATE_DATABASE_DATE_SUCCESS = 'UPDATE_DATABASE_DATE_SUCCESS',
  UPDATE_DATABASE_DATE_FAILURE = 'UPDATE_DATABASE_DATE_FAILURE',
}

export enum Department { 
  GET_DEPARTMENTS_INIT = 'GET_DEPARTMENTS_INIT',
  GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE',

  GET_CLIENT_DEPARTMENTS_INIT = 'GET_CLIENT_DEPARTMENTS_INIT',
  GET_CLIENT_DEPARTMENTS_SUCCESS = 'GET_CLIENT_DEPARTMENTS_SUCCESS',
  GET_CLIENT_DEPARTMENTS_FAILURE = 'GET_CLIENT_DEPARTMENTS_FAILURE',

  ADD_DEPARTMENT_INIT = 'ADD_DEPARTMENT_INIT',
  ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS',
  ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE',

  EDIT_DEPARTMENT_INIT = 'EDIT_DEPARTMENT_INIT',
  EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS',
  EDIT_DEPARTMENT_FAILURE = 'EDIT_DEPARTMENT_FAILURE',
  
  DELETE_DEPARTMENT_INIT = 'DELETE_DEPARTMENT_INIT',
  DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE',
  
  ADD_DEPARTMENT_PHOTO_INIT = 'ADD_DEPARTMENT_PHOTO_INIT',
  ADD_DEPARTMENT_PHOTO_SUCCESS = 'ADD_DEPARTMENT_PHOTO_SUCCESS',
  ADD_DEPARTMENT_PHOTO_FAILURE = 'ADD_DEPARTMENT_PHOTO_FAILURE',

  GET_CLIENT_LEARNING_INFO_INIT = 'GET_CLIENT_LEARNING_INFO_INIT',
  GET_CLIENT_LEARNING_INFO_SUCCESS = 'GET_CLIENT_LEARNING_INFO_SUCCESS',
  GET_CLIENT_LEARNING_INFO_FAILURE = 'GET_CLIENT_LEARNING_INFO_FAILURE',
  
}


export enum Lesson {
  ADD_LESSON_INIT = 'ADD_LESSON_INIT',
  ADD_LESSON_SUCCESS = 'ADD_LESSON_SUCCESS',
  ADD_LESSON_FAILURE = 'ADD_LESSON_FAILURE',

  GET_LESSON_INIT = 'GET_LESSON_INIT',
  GET_LESSON_SUCCESS = 'GET_LESSON_SUCCESS',
  GET_LESSON_FAILURE = 'GET_LESSON_FAILURE',

  GET_CLIENT_LESSONS_INIT = 'GET_CLIENT_LESSONS_INIT',
  GET_CLIENT_LESSONS_SUCCESS = 'GET_CLIENT_LESSONS_SUCCESS',
  GET_CLIENT_LESSONS_FAILURE = 'GET_CLIENT_LESSONS_FAILURE',

  START_LESSONS_INIT = 'START_LESSONS_INIT',
  START_LESSONS_SUCCESS = 'START_LESSONS_SUCCESS',
  START_LESSONS_FAILURE = 'START_LESSONS_FAILURE',

  DELETE_LESSON_INIT = 'DELETE_LESSON_INIT',
  DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS',
  DELETE_LESSON_FAILURE = 'DELETE_LESSON_FAILURE',

  EDIT_LESSON_INIT = 'EDIT_LESSON_INIT',
  EDIT_LESSON_SUCCESS = 'EDIT_LESSON_SUCCESS',
  EDIT_LESSON_FAILURE = 'EDIT_LESSON_FAILURE',

  ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS',
  
  REDUCTION_TASK_SUCCESS = 'REDUCTION_TASK_SUCCESS',

  SAVE_TASK_INIT = 'SAVE_TASK_INIT',
  SAVE_TASK_SUCCESS = 'SAVE_TASK_SUCCESS',
  SAVE_TASK_FAILURE = 'SAVE_TASK_FAILURE',

  ANSWER_TASK_INIT = 'ANSWER_TASK_INIT',
  ANSWER_TASK_SUCCESS = 'ANSWER_TASK_SUCCESS',
  ANSWER_TASK_FAILURE = 'ANSWER_TASK_FAILURE',

  END_LESSON_INIT = 'END_LESSON_INIT',
  END_LESSON_SUCCESS = 'END_LESSON_SUCCESS',
  END_LESSON_FAILURE = 'END_LESSON_FAILURE',
  
  UPDATE_SORT_INIT = 'UPDATE_SORT_INIT',
  UPDATE_SORT_SUCCESS = 'UPDATE_SORT_SUCCESS',
  UPDATE_SORT_FAILURE = 'UPDATE_SORT_FAILURE',
}

export enum Task {
  ADD_TASK_INIT = 'ADD_TASK_INIT',
  ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS',
  ADD_TASK_FAILURE = 'ADD_TASK_FAILURE',

  GET_TASK_INIT = 'GET_TASK_INIT',
  GET_TASK_SUCCESS = 'GET_TASK_SUCCESS',
  GET_TASK_FAILURE = 'GET_TASK_FAILURE',

  GET_SAVE_TASKS_INIT = 'GET_SAVE_TASKS_INIT',
  GET_SAVE_TASKS_SUCCESS = 'GET_SAVE_TASKS_SUCCESS',
  GET_SAVE_TASKS_FAILURE = 'GET_SAVE_TASKS_FAILURE',

  GET_SINGLE_SAVE_TASK_INIT = 'GET_SINGLE_SAVE_TASK_INIT',
  GET_SINGLE_SAVE_TASK_SUCCESS = 'GET_SINGLE_SAVE_TASK_SUCCESS',
  GET_SINGLE_SAVE_TASK_FAILURE = 'GET_SINGLE_SAVE_TASK_FAILURE',

  DELETE_TASK_INIT = 'DELETE_TASK_INIT',
  DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE',

  GET_TASKS_BY_LESSON_INIT = 'GET_TASKS_BY_LESSON_INIT',
  GET_TASKS_BY_LESSON_SUCCESS = 'GET_TASKS_BY_LESSON_SUCCESS',
  GET_TASKS_BY_LESSON_FAILURE = 'GET_TASKS_BY_LESSON_FAILURE',

  EDIT_LESSON_INIT = 'EDIT_LESSON_INIT',
  EDIT_LESSON_SUCCESS = 'EDIT_LESSON_SUCCESS',
  EDIT_LESSON_FAILURE = 'EDIT_LESSON_FAILURE',

  CHANGE_ORDER_TASK_INIT = 'CHANGE_ORDER_TASK_INIT',
  CHANGE_ORDER_TASK_SUCCESS = 'CHANGE_ORDER_TASK_SUCCESS',
  CHANGE_ORDER_TASK_FAILURE = 'CHANGE_ORDER_TASK_FAILURE',
  
  SAVE_TASK_SUCCESS = 'SAVE_TASK_SUCCESS',

  ANSWER_SAVE_TASK_INIT = 'ANSWER_SAVE_TASK_INIT',
  ANSWER_SAVE_TASK_SUCCESS = 'ANSWER_SAVE_TASK_SUCCESS',
  ANSWER_SAVE_TASK_FAILURE = 'ANSWER_SAVE_TASK_FAILURE',

}

export enum Payment {
  GET_PAYMENTS_LABEL_INIT = 'GET_PAYMENTS_LABEL_INIT',
  GET_PAYMENTS_LABEL_SUCCESS = 'GET_PAYMENTS_LABEL_SUCCESS',
  GET_PAYMENTS_LABEL_FAILURE = 'GET_PAYMENTS_LABEL_FAILURE',

  GET_PAYMENTS_INIT = 'GET_PAYMENTS_INIT',
  GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE',
}


export enum City {
  GET_CITIES_BY_USERS_INIT = 'GET_CITIES_BY_USERS_INIT',
  GET_CITIES_BY_USERS_SUCCESS = 'GET_CITIES_BY_USERS_SUCCESS',
  GET_CITIES_BY_USERS_FAILURE = 'GET_CITIES_BY_USERS_FAILURE',

  GET_POPULAR_CITIES_INIT = 'GET_POPULAR_CITIES_INIT',
  GET_POPULAR_CITIES_SUCCESS = 'GET_POPULAR_CITIES_SUCCESS',
  GET_POPULAR_CITIES_FAILURE = 'GET_POPULAR_CITIES_FAILURE',

}