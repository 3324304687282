import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

export const success = (users) => ({
  type: User.GET_DRIVING_CATEGORY_SUCCESS,
  payload: {
    users,
  },
});

const failure = () => ({
  type: User.GET_DRIVING_CATEGORY_FAILURE,
});

const getDrivingCategory = () => async (dispatch) => {
  try {
    const response = await UserModule.Connector.getDrivingCategory();
    dispatch(success(response.data));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getDrivingCategory;
