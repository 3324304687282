import { connect } from 'react-redux';
import RecommendedToOthers from './Main';
import { bindActionCreators } from 'redux';
import { getPayments } from 'Store/Actions/Payment';
import { getPaymentsLabel as getPaymentsLabelSelector } from 'Store/Selectors/PaymentSelector';

type ConnectedP = {
  paymentsLabel: any;
};

type DispatchedP = {
  actions: {
    getPayments: any;
  };
};
const mapStateToProps = (state) => ({
  paymentsLabel: getPaymentsLabelSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getPayments: getPayments,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedToOthers);
