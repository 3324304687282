import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

type TaskItemType = {
  item: any;
  setRemoveTaskItem: any;
  index: any;
};
const TaskItem = ({ setRemoveTaskItem, item, index }: TaskItemType) => {
  const {
    question: { name, question },
  } = item;

  return (
    <div className="task-item">
      <div className="task-item__left">
        <div className="task-item__number">{name}</div>
        <div className="task-item__label">{question}</div>
      </div>
      <div className="task-item__right">
        <div className="task-item__icon-box" onClick={() => setRemoveTaskItem({ item, index })}>
          <SVG type={SVG_TYPE.BIN} />
        </div>
      </div>
    </div>
  );
};

export default TaskItem;
