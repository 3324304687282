import { connect } from 'react-redux';
import AllCitiesPage from './Main';
import { bindActionCreators } from 'redux';
import { adminLogin } from 'Store/Actions/Auth';
import { getDepartment } from 'Store/Selectors/DepartmentSelector'
import { getAllUserCity } from 'Store/Actions/City';
import { getAllUserCity as getAllUserCitySelector } from 'Store/Selectors/CitySelector'

type ConnectedP = {
  departments: any;
  allUserCities: any;
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    getAllUserCity: (page) => any;
  };
};
const mapStateToProps = (state) => ({
  allUserCities: getAllUserCitySelector(state),
  departments: getDepartment(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAllUserCity: getAllUserCity,
      adminLogin: adminLogin,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(AllCitiesPage);
