import * as QuestionModule from 'Modules/QuestionModule';
import { Question } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Question.GET_QUESTIONS_INIT,
});

export const success = (data) => ({
  type: Question.GET_QUESTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Question.GET_QUESTIONS_FAILURE,
});

const getQuestions = (data) => async (dispatch) => {
  dispatch(init());
  if(data.category === 'all') {
    delete data.category;
  }
  try {
    const response = await QuestionModule.Connector.getQuestions(data);
    dispatch(success(response.data.questions));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getQuestions;
