import SVG from 'Components/SVG';
import { ROUTES, SVG_TYPE, USER_ROLES, USER_TYPE } from 'Shared/enums';
import { User } from 'Shared/models/UserModel';
import { Formik } from 'formik';
import moment from 'moment';
import SingleUserMobileExpand from './SingleUserMobileExpand';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

type SingleUserType = {
  user: User;
  index: number;
  setEdittedUser: (user: User) => void;
  setRestoreUser: (user: User) => void;
  resendActivateLink: ({ email }) => (dispatch: any) => Promise<boolean>;
  indexExpandUser: any;
  setIndexExpandUser: any;
};

function SingleUser({
  user,
  index,
  setEdittedUser,
  resendActivateLink,
  setRestoreUser,
  indexExpandUser,
  setIndexExpandUser,
}: SingleUserType): JSX.Element {
  const isMobile = window.innerWidth < 1024;
  const isAdmin = user.role === USER_ROLES.ADMIN;
  const isUserArchived = user.isArchivied;
  const isUserExpand = indexExpandUser === index;
  const navigate = useNavigate();

  const expandUser = () => {
    if (isMobile) {
      if (indexExpandUser === index) {
        setIndexExpandUser(undefined);
      } else {
        if (indexExpandUser) {
          setIndexExpandUser(undefined);
          setTimeout(() => {
            setIndexExpandUser(index);
          }, 200);
        } else {
          setIndexExpandUser(index);
        }
      }
    }
  };
  const handleRedirectToUserStats = () => {
    navigate({ pathname: generatePath(ROUTES.USER_STATISTICS, { uuid: `:${user.uuid}` }) });
  };

  return (
    <tr
      className={`users-list__tr ${isAdmin ? 'users-list__tr-admin' : ''} ${
        isUserExpand ? `${user.avatar.length > 0 ? 'users-list__tr--expand users-list__tr--expand-avatar' : 'users-list__tr--expand'}` : ''
      } ${(!user.activate && user.role === USER_ROLES.ADMIN) || user.suspended || isUserArchived ? 'users-list__tr--inactive' : ''}`}
      id={user.uuid}
      onClick={() => expandUser()}
      key={`page-${index}`}>
      <Formik initialValues={{}} enableReinitialize onSubmit={async () => null}>
        {() => (
          <>
            <td className="users-list__td users-list__td--name">
              <div className="users-list__td--image">
                {user.avatar.length ? (
                  <img className="users-list__avatar" src={user.avatar[0].url} alt="avatar" />
                ) : (
                  <SVG type={SVG_TYPE.DEFAULT_AVATAR} />
                )}
              </div>
              <span>{user.name}</span>
            </td>
            <td className="users-list__td">
              <span>
                +{user.phonePrefix} {user.phoneNumber}
              </span>
            </td>
            <td className={`users-list__td ${!isAdmin && 'users-list__td--capitalize'}`}>
              <span>
                {isAdmin
                  ? user.email
                  : `${
                      (user.type === USER_TYPE.STUDENT_IMPULS || user.type === USER_TYPE.PREMIUM) && !isMobile
                        ? `${user.type === USER_TYPE.STUDENT_IMPULS ? 'Uczeń Impuls' : 'Premium'} do (${moment(user?.deadline?.deadline).format(
                            'DD.MM.YYYY',
                          )})`
                        : user.type
                    }`}
              </span>
            </td>
            <td className="users-list__td">
              {isAdmin ? (user.suspended ? 'Zawieszony' : user.activate ? 'Aktywny' : 'Nieaktywny') : user.city ? user.city : 'Brak informacji'}
            </td>
            <td className="users-list__td">
              {isAdmin ? (
                <p
                  onClick={() => resendActivateLink({ email: user.email })}
                  className={`users-list__send-activate-link ${!user.activate ? 'users-list__send-activate-link--show' : ''}`}>
                  Wyślij nowy link aktywacyjny
                </p>
              ) : isUserArchived ? (
                'Zarchiwizowany'
              ) : user.suspended ? (
                'Zawieszony'
              ) : (
                'Aktywny'
              )}
            </td>
            <td className="users-list__td users-list__menu">
              <div className={`menu ${isAdmin ? 'menu--admin' : ''}`}>
                {isAdmin ? null : (
                  <span onClick={() => handleRedirectToUserStats()}>
                    <SVG className="users-list__stats" type={SVG_TYPE.STATS} />
                  </span>
                )}
                {isUserArchived ? (
                  <span className="users-list__menu--refresh" onClick={() => setRestoreUser(user)}>
                    <SVG type={SVG_TYPE.REFRESH} />
                  </span>
                ) : (
                  <span onClick={() => setEdittedUser(user)}>
                    <SVG type={SVG_TYPE.EDIT} />
                  </span>
                )}
              </div>
            </td>
          </>
        )}
      </Formik>
      <SingleUserMobileExpand user={user} isUserExpand={isUserExpand} setEdittedUser={setEdittedUser} />
    </tr>
  );
}

export default SingleUser;
