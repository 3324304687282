import SVG from 'Components/SVG';
import { SelectOption } from 'Shared/models/SelectOptionsModel';
import Select from 'react-select';
import { FieldProps } from 'formik';
import { useEffect, useState } from 'react';

interface SelectProps {
  label: string;
  options: SelectOption[];
  placeholder: string;
  name: string;

  isMulti?: boolean;
  svgType?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  initialValue?: SelectOption;
  addMore?: boolean;
  addMoreOnClick?: () => void;
  onInputChange?: (value) => void;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: any;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function SelectComponent({
  field,
  form,
  errors,
  label,
  className,
  disabled,
  name,
  options,
  placeholder,
  required,
  isMulti,
  initialValue,
  addMore,
  onInputChange,
  isSearchable = false,
  addMoreOnClick,
  openMenuOnClick,
}: SelectProps & FieldProps) {
  const [value, setValue] = useState([]);

  const handleChange = (value) => {
    if (isMulti) {
      form.setFieldValue(field.name, value);
    } else {
      form.setFieldValue(field.name, value.value);
    }
  };

  useEffect(() => {
    const tempArray = [];
    if (isMulti && Array.isArray(value)) {
      field.value?.map((item) => {
        tempArray.push(item.value);
      });
    } else {
      tempArray.push(field.value);
    }
    setValue(options.filter((option) => tempArray.includes(option.value)));
  }, [field.value]);

  return (
    <div className="select__wrapper">
      <>
        <label className="select__label" htmlFor={field.name}>
          {label} {!required && <span className="select__required">Opcjonalne</span>}
          {addMore && !!field.value ? (
            <span className="select__add-more" onClick={addMoreOnClick}>
              + dodaj kolejny
            </span>
          ) : null}
        </label>
        <Select
          {...field}
          options={options}
          placeholder={placeholder}
          isDisabled={disabled}
          name={name}
          className={`select ${className ? className : ''} `}
          isClearable={false}
          classNamePrefix={'select'}
          // required={required}
          onChange={handleChange}
          value={value}
          isMulti={isMulti}
          openMenuOnClick={openMenuOnClick}
          defaultValue={initialValue}
          noOptionsMessage={() => 'Brak wyników'}
          onInputChange={onInputChange}
          // isOptionDisabled={() => (isMulti ? field.value?.length >= maxLimit : false)}
          isSearchable={isSearchable}
          filterOption={(option: any, input) => option.data.label.toLowerCase().includes(input.toLowerCase())}
          getOptionLabel={(props) => {
            const { label, svgType }: any = props;
            return (
              <div className="select__option-container">
                {svgType && <SVG type={svgType} />}
                <span>{label}</span>
              </div>
            ) as unknown as string;
          }}
        />
        {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
      </>
    </div>
  );
}

export default SelectComponent;
