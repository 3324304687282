import * as Tasks from 'Modules/TaskModule';
import { Task } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Task.ANSWER_SAVE_TASK_INIT,
});

export const success = (data) => ({
  type: Task.ANSWER_SAVE_TASK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Task.ANSWER_SAVE_TASK_FAILURE,
});

const answerSaveTask = (uuid, answer) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await Tasks.Connector.answerSaveTask(uuid, answer);
    dispatch(success(response.data));
    return response.data.saveTasks;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default answerSaveTask;
