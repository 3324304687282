import { PasswordValidation } from 'Components';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { Input } from 'Components/form';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

type changePasswordModalType = {
  isOpen: boolean;
  onClose: () => void;
  changeCurrentUserPassword: ({ currentPassword, newPassword }) => void;
};

function ChangePasswordModal({ isOpen, onClose, changeCurrentUserPassword }: changePasswordModalType): JSX.Element {
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    confirmPassword: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('passwords-match', VALIDATION_MESSAGES.PASSWORD_MISMATCH, function (value) {
        return this.parent.newPassword === value;
      }),
    newPassword: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('Capital Letter', '', function (value) {
        return /[A-Z]/.test(value);
      })
      .test('Lowercase Letter', '', function (value) {
        return /[a-z]/.test(value);
      })
      .test('Special Character', '', function (value) {
        return /[!@#$%^&*(),.?":{}|<>]/.test(value);
      })
      .test('Number', '', function (value) {
        return /[0-9]/.test(value);
      })
      .test('passwords-match', VALIDATION_MESSAGES.CANNOT_SET_OLD_PASSWORD, function (value) {
        return this.parent.currentPassword !== value;
      })
      .test('cannot-start-with-space', VALIDATION_MESSAGES.CANNOT_START_WITH_SPACE, function (value) {
        return !value?.startsWith(' ');
      }),
  });

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const handleOnSubmit = (values) => {
    changeCurrentUserPassword(values);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal`}>
      <h2 className="edit-user-modal__header">Zmiana hasła</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => handleOnSubmit(values)}>
        {({ errors, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field
              className={''}
              label="Poprzednie hasło"
              name="currentPassword"
              placeholder="Wpisz Poprzednie hasło"
              required
              component={Input}
              errors={errors}
              maxLength={64}
              type="password"
            />
            <Field
              className={''}
              label="Nowe hasło"
              name="newPassword"
              placeholder="Wpisz Nowe hasło"
              required
              type="password"
              component={Input}
              errors={errors}
              maxLength={64}
            />
            <PasswordValidation password={values.newPassword} />
            <Field
              className={'edit-user-modal__margin-top'}
              label="Powtórz nowe hasło"
              name="confirmPassword"
              placeholder="Powtórz nowe hasło"
              required
              component={Input}
              errors={errors}
              type="password"
              maxLength={64}
            />

            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default ChangePasswordModal;
