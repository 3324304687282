import { connect } from 'react-redux';
import UserStatistics from './Main';
import { bindActionCreators } from 'redux';
import { getUserStats } from 'Store/Actions/User';
import { getUserStats as getUserStatsSelector } from 'Store/Selectors/UserSelector';

type ConnectedP = {
  userStats:any;
};

type DispatchedP = {
  actions: {
    getUserStats:(uuid) => void;
  };
};
const mapStateToProps = (state) => ({
  userStats: getUserStatsSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getUserStats: getUserStats,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(UserStatistics);
