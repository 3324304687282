import * as PaymentsConnector from 'Modules/PaymentModule';
import { Payment } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Payment.GET_PAYMENTS_INIT,
});

export const success = (data) => ({
  type: Payment.GET_PAYMENTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Payment.GET_PAYMENTS_FAILURE,
});

const getPayments = (params) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await PaymentsConnector.Connector.getPayments(params);
    dispatch(success(response.data.paymentTypes));
    const link = response.data.sessionUrl;
    // window.open(link);
    window.location.href = link;
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getPayments;
