import { Button } from 'Components';
import { SVG_TYPE, USER_ROLES, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';

type SingleUserMobileExpandType = {
  isUserExpand: boolean;
  setEdittedUser: any;
  user: any;
};

const SingleUserMobileExpand = ({ isUserExpand, user, setEdittedUser }: SingleUserMobileExpandType) => {
  const isAdmin = user.role === USER_ROLES.ADMIN;
  const navigate = useNavigate();

  return (
    <div className={`single-user-mobile-expand ${isUserExpand ? 'single-user-mobile-expand--active' : ''}`}>
      <div className="single-user-mobile-expand__description">
        {isAdmin && <div className="single-user-mobile-expand--name">{user.email}</div>}
        {!isAdmin && <div className="single-user-mobile-expand--name">{user.name}</div>}
        {!isAdmin ? (
          <>
            {user?.city ? <div className="single-user-mobile-expand--city">・{user.city} </div> : ''}
            {user.activate ? '・Aktywny' : '・Nieaktywny'}
          </>
        ) : (
          ''
        )}
        {isAdmin ? (user.suspended ? 'Zawieszony' : user.activate ? '・Aktywny' : '・Nieaktywny') : ''}
        <div className="single-user-mobile-expand--status"></div>
      </div>
      <div className="single-user-mobile-expand__buttons">
        {!isAdmin && (
          <Button svg_type={SVG_TYPE.STATS} iconOnLeft label="Statystyki" onClick={() => navigate({ pathname: ROUTES.USER_STATISTICS })} stroke />
        )}
        <Button svg_type={SVG_TYPE.EDIT} iconOnLeft label="Edycja" onClick={() => setEdittedUser(user)} stroke />
      </div>
    </div>
  );
};

export default SingleUserMobileExpand;
