import { useEffect, useState } from 'react';
import React from 'react';
import { CategoryBox } from 'Screens/LearningPage/components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type DepartmentsType = {
  departments: any;
};

const Departments = ({ departments }: DepartmentsType) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !departments
      ? setTimeout(() => {
          setLoading(true);
        }, 500)
      : setLoading(true);
  }, []);

  return (
    <div className="departments">
      {departments && loading ? (
        departments.data.map((item) => {
          return <CategoryBox data={item} key={item.uuid} />;
        })
      ) : (
        <>
          <div className="category-box category-box--skeleton">
            <Skeleton height="154px" />
          </div>
          <div className="category-box category-box--skeleton">
            <Skeleton height="154px" />
          </div>
          <div className="category-box category-box--skeleton">
            <Skeleton height="154px" />
          </div>
          <div className="category-box category-box--skeleton">
            <Skeleton height="154px" />
          </div>
          <div className="category-box category-box--skeleton">
            <Skeleton height="154px" />
          </div>
          <div className="category-box category-box--skeleton">
            <Skeleton height="154px" />
          </div>
        </>
      )}
    </div>
  );
};

export default Departments;
