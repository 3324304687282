import React, { useState } from 'react';
// import { MainType } from './Container';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { Button, SVG } from 'Components';
import { useNavigate } from 'react-router-dom';

const RecommendedToOthers = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const isMobile = window.innerWidth < 1024;

  const goBack = () => {
    navigate({ pathname: ROUTES.MYPROFILE });
  };

  const handleCopy = () => {
    const textArea = document.createElement('textarea');
    textArea.value = process.env.REACT_APP_URL;
    textArea.style.position = 'fixed';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      setCopied(true);
    } catch (err) {
      console.error('Nie można skopiować tekstu', err);
    }

    document.body.removeChild(textArea);
    setTimeout(() => setCopied(false), 1500);
  };

  const handleShare = async () => {
    if (navigator.share) {
      navigator.share({
        title: 'Royal Academy',
        text: 'Zapraszam Cię do skorzystania z tej świetnej aplikacji!',
        url: process.env.REACT_APP_URL,
      });
    } else {
      console.error('Web Share API not supported');
    }
  };

  return (
    <>
      <header className="dashboard__header learning-page__header">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

        <div className="dashboard__container departments-page__header-container">
          <h1 className="dashboard__title departments-page__title">
            Poleć innym
            <div onClick={goBack} className="dashboard__goBack">
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          </h1>
        </div>
      </header>
      <div className="exam-page dashboard__content learning-page">
        <div className="dashboard__container recommended-to-others">
          <div className="recommended-to-others__content">
            <div className="recommended-to-others__heading">Poleć aplikację swoim znajomym 😎</div>
            <div className="recommended-to-others__description">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys since the 1500s
            </div>
            <div className="recommended-to-others__link--wrapper">
              <div className="recommended-to-others__link-heading">Udostępnij link 🔗</div>
              <div className="recommended-to-others__link" onClick={() => handleCopy()}>
                {process.env.REACT_APP_URL}
                <div className="recommended-to-others__link-copy">
                  <SVG type={SVG_TYPE.COPY} />
                </div>
              </div>
            </div>
            {<div className={`copied-alert ${copied ? 'copied-alert--active' : ''}`}>Link skopiowany</div>}
            {isMobile && <Button label="Zaproś znajomych" onClick={() => handleShare()} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendedToOthers;
