import SVG from 'Components/SVG';
import { SVG_TYPE, ROUTES, USER_ROLES } from 'Shared/enums';
import { createRef, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { ChangePasswordModal } from './components';
import { MainType } from './Container';

function Dashboard(props: MainType) {
  const { actions, authorizedUser } = props;
  const { changeCurrentUserPassword, logout, getAuthorizedUser } = actions;
  const node = createRef<any>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openedModal, setOpenedModal] = useState('');
  const navigate = useNavigate();
  // const isUserLogged = JSON.parse(localStorage.getItem('isUserLogged'));
  const isUserLogged = !!authorizedUser && authorizedUser !== 'init';
  const isChangePasswordModalOpen = openedModal === 'changePassword';
  const pathname = window.location.pathname;

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      getAuthorizedUser();
    } else {
      const handlePathnameChange = () => {
        getAuthorizedUser();
      };

      // Dodajemy listener na zmianę pathname
      window.addEventListener('popstate', handlePathnameChange);

      // Czyszczenie listenera po zakończeniu komponentu
      return () => {
        window.removeEventListener('popstate', handlePathnameChange);
      };
    }
  }, [pathname]);

  const handlePasswordChange = async (values) => {
    const response = await changeCurrentUserPassword(values);
    if (response) {
      setOpenedModal('');
    }
  };

  const handleLogout = async () => {
    localStorage.removeItem('userRole');
    if (authorizedUser === 'init') return;
    const role = authorizedUser?.role;
    await logout();
    if (role === USER_ROLES.ADMIN) {
      navigate(ROUTES.ADMIN_LOGIN_PAGE);
    } else {
      navigate(ROUTES.LOGIN_PAGE);
    }
  };

  // useEffect(() => {
  //   !isUserLogged && navigate({ pathname: ROUTES.LOGIN_PAGE });
  // }, []);

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dashboard-top__chevron')) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (authorizedUser !== 'init') {
      !authorizedUser && navigate({ pathname: ROUTES.LOGIN_PAGE });
    }
  }, [authorizedUser]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {isUserLogged && (
        <div className="dashboard" ref={node}>
          <div className="dashboard-top__header">
            <span className="dashboard-top__header-spacer" />
            <div className="dashboard-top__header-content">
              <div className="dashboard-top__menu" onClick={() => !isDropdownOpen && setIsDropdownOpen(!isDropdownOpen)}>
                {!!authorizedUser?.avatar?.length ? (
                  <img src={authorizedUser?.avatar[0].url} className="dashboard-top__avatar" alt="avatar" />
                ) : (
                  <SVG type={SVG_TYPE.DEFAULT_AVATAR} className="dashboard-top__avatar" />
                )}

                {authorizedUser.name}
                <div className={`dashboard-top__chevron ${isDropdownOpen ? 'dashboard-top__chevron--open' : ''} `}>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                  <div className={`dashboard-top__dropdown-menu ${isDropdownOpen ? 'dashboard-top__dropdown-menu--open' : ''} `}>
                    {authorizedUser.role === USER_ROLES.ADMIN && <p onClick={() => setOpenedModal('changePassword')}>Zmień hasło</p>}
                    <p onClick={() => handleLogout()}>Wyloguj</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Outlet />
          <ChangePasswordModal
            isOpen={isChangePasswordModalOpen}
            onClose={() => setOpenedModal('')}
            changeCurrentUserPassword={(values) => handlePasswordChange(values)}
          />
        </div>
      )}
    </>
  );
}

export default Dashboard;
