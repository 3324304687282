import * as Types from 'Shared/models/DrivingTestModel';
import { DrivingTest } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.DrivingTestStore;

export const initialState: State = {
  drivingTest: null,
  drivingTestSummary: null,
};

export function drivingTestReducer(state: any = initialState, action) {
  switch (action.type) {
    case DrivingTest.GET_DRIVING_TEST_INIT: {
      return {
        ...state,
      };
    }
    case DrivingTest.GET_DRIVING_TEST_SUCCESS: {
      return {
        ...state,
        drivingTest: action.payload.data,
      };
    }
    case DrivingTest.GET_DRIVING_TEST_FAILURE: {
      return {
        ...state,
      };
    }
    case DrivingTest.FINISH_DRIVING_TEST_INIT: {
      return {
        ...state,
      };
    }
    case DrivingTest.FINISH_DRIVING_TEST_SUCCESS: {
      return {
        ...state,
        drivingTestSummary: action.payload.data,
      };
    }
    case DrivingTest.FINISH_DRIVING_TEST_FAILURE: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
