import React from 'react';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Components';
import { useNavigate } from 'react-router';

type HeaderType = {
  lesson: any;
  activeTaskNumber: any;
  allTasks: any;
  endLesson: any;
  setSummaryLesson: any;
  summaryLesson: any;
};

const Header = ({ lesson, activeTaskNumber, allTasks, endLesson, setSummaryLesson, summaryLesson }: HeaderType) => {
  const isMobile = window.innerWidth < 1024;
  const pathname = window.location.pathname;
  const lessonUuid = pathname.split(':')[1];
  const navigate = useNavigate();

  const handleEndLesson = async () => {
    localStorage.setItem('lessonPage', JSON.stringify({ uuid: null, taskNumber: 0 }));
    if (summaryLesson) {
      navigate({ pathname: ROUTES.DEPARTMENTS_PAGE.replace('uuid', summaryLesson.departmentUuid) });
    } else {
      const response = await endLesson(lessonUuid);
      setSummaryLesson(response);
    }
  };

  return (
    <header className="dashboard__header lesson-page__header">
      <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
      <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
      <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

      <div className="dashboard__container lesson-page__header--container">
        {!isMobile && <h1 className="dashboard__title lesson-page__header--title">{lesson?.name}</h1>}
        <div className="lesson-page__header--bottom">
          {!summaryLesson && (
            <p className="lesson-page__header--lesson-number">
              {activeTaskNumber + 1} z {allTasks}
            </p>
          )}
          <p className="lesson-page__header--close" onClick={() => handleEndLesson()}>
            Zakończ {!isMobile && <SVG type={SVG_TYPE.CLOSE_BOLD} />}
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
