import * as LessonConnector from 'Modules/LessonModule';
import { Lesson, Task } from '../EnumTypes/ActionEnumTypes';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';
import { toast } from 'react-toastify';

const init = () => ({
  type: Lesson.SAVE_TASK_INIT,
});

export const success = (data) => ({
  type: Task.SAVE_TASK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.SAVE_TASK_FAILURE,
});

const saveTask = (data) => async (dispatch) => {
  const id = toast.loading('Trwa zapisywanie pytania...');
  dispatch(init());
  try {
    const response = await LessonConnector.Connector.saveTask(data.taskUuid);
    toast.update(id, {
      render: `${data.isQuestionSaved ? 'Pomyślnie usunięto z listy zapisanych pytań' : 'Pomyślnie zapisano pytanie'}`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    data.total && dispatch(success({ uuid: data.taskUuid }));
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: `${data.isQuestionSaved ? 'Nie udało się usunąć z zpaisanych pytań' : 'Nie udało się zapisać pytania'}`,
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default saveTask;
