import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { AdminEdit } from 'Shared/models/UserModel';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = (users) => ({
  type: User.ADD_ADMIN_SUCCESS,
  payload: {
    users,
  },
});

const failure = () => ({
  type: User.ADD_ADMIN_FAILURE,
});

const addAdmin = (userData: AdminEdit) => async (dispatch) => {
  const id = toast.loading('Trwa dodawanie admina...');
  try {
    const response = await UserModule.Connector.addAdmin(userData);
    dispatch(success(response.data.usersSerialize));
    toast.update(id, {
      render: 'Pomyślnie dodano admina',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });

    return response.data.user.uuid;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się dodać admina',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return err?.response?.data.errors;
  }
};

export default addAdmin;
