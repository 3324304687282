import { MainType } from './Container';
import Back from 'Components/Back';
import { Field, Formik } from 'formik';
import { SmsCodeInput } from 'Components/form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { generatePath } from 'react-router-dom';

function EnterSmsCodePage(props: MainType) {
  const { actions, authorizedUser } = props;
  const { login, getSmsCode } = actions;
  const params = new URLSearchParams(window.location.search);
  const phone = params.get('phoneNumber');
  const phonePrefixUuid = params.get('phonePrefixUuid');
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const demoToStandard = sessionStorage.getItem('DEMO_TO_STANDARD_SMS');
  const registerAfterExam = JSON.parse(localStorage.getItem('SUMMARY_EXAM_ID'));

  const handleCodeChange = (newCode: string) => {
    if (newCode.length === 4) {
      handleLogin(newCode);
    }
    setShowError(false);
  };

  useEffect(() => {
    if (!phone) {
      navigate(ROUTES.LOGIN_PAGE);
    }
  }, []);

  const handleResendCode = async () => {
    await getSmsCode({ phoneNumber: phone, phonePrefixUuid });
  };

  const handleLogin = async (code: string) => {
    const result = await login(code);
    if (!result) {
      setShowError(true);
    } else {
      if (authorizedUser !== 0 && authorizedUser !== 'init' && authorizedUser?.role !== 'admin') {
        if (demoToStandard) {
          sessionStorage.removeItem('DEMO_TO_STANDARD_SMS');
          if (registerAfterExam) {
            localStorage.removeItem('SUMMARY_EXAM_ID');
            navigate({ pathname: ROUTES.DRIVING_TEST_SUMMARY.replace('uuid', registerAfterExam) });
          } else {
            navigate({ pathname: ROUTES.MYPROFILE });
          }
        } else if (authorizedUser.category) {
          navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
        } else {
          navigate(ROUTES.SELECT_CATEGORY);
        }
      }
    }
  };

  useEffect(() => {
    if (!demoToStandard) {
      if (authorizedUser !== 0 && authorizedUser !== 'init') {
        if (authorizedUser.category) {
          navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
        } else {
          navigate(ROUTES.SELECT_CATEGORY);
        }
      }
    }
  }, [authorizedUser]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('DEMO_TO_STANDARD_SMS');
    };
  }, []);

  return (
    <div className="login-page page ">
      <main className="login-page__body login-page__body--without-flex">
        <div className="login-page__content-wrapper">
          <div className="login-page__top-wrapper">
            <Back />
            <h2 className="login-page__title">Wprowadź kod dostępu</h2>
          </div>
          <p className="login-page__description">Wprowadź 4 — cyfrowy kod, który przesłaliśmy Ci w&nbsp;wiadomości SMS.</p>
          <p className={`login-page__error ${showError ? 'login-page__error--show login-page__error--sms-code' : ''}`}>
            <span>Nieprawidłowy kod dostępu</span>
            <SVG type={SVG_TYPE.WARNING} />
          </p>
          <Formik
            initialValues={{
              phoneNumber: '',
            }}
            onSubmit={(values) => {
              values;
            }}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="login-page__form">
                <Field component={SmsCodeInput} onCodeChange={handleCodeChange} />
              </form>
            )}
          </Formik>
        </div>
        <div className="login-page__buttons">
          <p className="login-page__resend-code">
            Nie otrzymałeś kodu dostępu? <b onClick={() => handleResendCode()}>Wyślij ponownie</b>
          </p>
        </div>
      </main>
    </div>
  );
}

export default EnterSmsCodePage;
