import { SVG_TYPE } from 'Shared/enums';
import { SVG, Button } from 'Components';

type EmptyDepartmentType = {
  setIsLessonAddOpen: any;
};

const EmptyDepartment = ({ setIsLessonAddOpen }: EmptyDepartmentType) => {
  return (
    <div className="empty-department__container">
      <div className="empty-department__content">
        <div className="empty-department__photo">
          <SVG type={SVG_TYPE.SHERLOCK} />
        </div>
        <h3 className="empty-department__heading">W tym dziale nie ma jeszcze lekcji.</h3>
        <Button className="empty-department__button" label="Dodaj lekcję +" onClick={() => setIsLessonAddOpen(true)} />
      </div>
    </div>
  );
};

export default EmptyDepartment;
