import { SVG } from 'Components';
import { SVG_TYPE, ROUTES, USER_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router';

type CallToActionType = {
  userType: any;
};
const CallToAction = ({ userType }: CallToActionType) => {
  const navigate = useNavigate();
  const isPremiumOrImpuls = userType === USER_TYPE.PREMIUM || userType === USER_TYPE.STUDENT_IMPULS;
  const isDemo = userType === USER_TYPE.DEMO;

  return (
    <>
      {!isPremiumOrImpuls && (
        <div className="call-to-action">
          <div className="call-to-action--left">
            <SVG className="call-to-action__icon" type={SVG_TYPE.STAR} />
          </div>
          {isDemo ? (
            <div className="call-to-action--right">
              <span className="call-to-action--text">Chcesz mieć dostęp do większej ilości lekcji?</span>
              <span
                className="call-to-action--action"
                onClick={() => {
                  sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
                  navigate({ pathname: ROUTES.LOGIN_PAGE });
                }}>
                Załóż konto <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
              </span>
            </div>
          ) : (
            <div className="call-to-action--right call-to-action--standard ">
              <span className="call-to-action--text">Chcesz mieć dostęp do wszystkich lekcji?</span>
              <span
                className="call-to-action--action call-to-action--action-standard"
                onClick={() => navigate({ pathname: ROUTES.MYPROFILE_PACKET })}>
                Wykup pakiet PREMIUM <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CallToAction;
