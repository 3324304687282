import SVG from 'Components/SVG';
import { ROUTES, SVG_TYPE, USER_TYPE, USER_ROLES } from 'Shared/enums';
import { Bar, ChangeUserNameModal, DeleteCurrentUserModal, ChangeAvatarModal, ChangeUserEmailModal } from './components';
import { useState } from 'react';
import { MainType } from './Container';
import { useNavigate } from 'react-router';
import moment from 'moment';

function MyProfile(props: MainType) {
  const { actions, authorizedUser } = props;
  const { changeUserName, deleteCurrentUser, logout, editAvatar, changeUserEmail } = actions;
  const [openedModal, setOpenedModal] = useState('');
  const isChangeUserNameModalOpen = openedModal === 'changeUserName';
  const isChangeUserEmailModalOpen = openedModal === 'changeUserEmail';
  const isDeleteCurrentUserModalOpen = openedModal === 'deleteCurrentUser';
  const isChangeAvatarModalOpen = openedModal === 'changeAvatar';
  const navigate = useNavigate();
  const confirmedAuthUser = authorizedUser !== 'init' && !!authorizedUser ? authorizedUser : null;
  const isAuthorizedUser = authorizedUser !== 'init' && !!authorizedUser;
  const isUserDemo = isAuthorizedUser && authorizedUser.type === USER_TYPE.DEMO;
  const isUserAdmin = isAuthorizedUser && authorizedUser.role === USER_ROLES.ADMIN;
  const isUserStandard = isAuthorizedUser && authorizedUser.type === USER_TYPE.STANDARD;
  const isUserPremium = isAuthorizedUser && authorizedUser.type === USER_TYPE.PREMIUM;
  const isUserImpuls = isAuthorizedUser && authorizedUser.type === USER_TYPE.STUDENT_IMPULS;
  const deadline = (isUserPremium || isUserImpuls) && moment(authorizedUser.deadline.deadline).format('D.MM.YYYY');
  const updateDataBaseAt = authorizedUser?.lastUpdate;
  const isMobile = window.innerWidth < 1024;

  const userType = {
    standard: 'Standard',
    demo: 'Demo',
    impulsStudent: 'Uczeń impuls',
    premium: 'Premium',
    null: 'Admin',
  };

  return (
    <>
      <header className="dashboard__header my-profile__header">
        <div className="dashboard__container">
          <div>
            <h1 className="my-profile__title">{isAuthorizedUser && authorizedUser?.name}</h1>
            <h2 className="my-profile__subtitle">Dołączono {isAuthorizedUser && moment(authorizedUser?.createdAt).format('D.MM.YYYY')}</h2>
          </div>
          <div className="my-profile__account-type">{isAuthorizedUser && userType[authorizedUser?.type]}</div>
          <div className={`my-profile__avatar ${!isUserDemo ? 'my-profile__avatar--edit' : ''}`}>
            {isAuthorizedUser && (
              <img src={authorizedUser?.avatar.length > 0 ? authorizedUser?.avatar[0].url : ''} alt="" className="my-profile__avatar-icon" />
            )}
            {!isUserDemo && (
              <div onClick={() => confirmedAuthUser.type !== 'demo' && setOpenedModal('changeAvatar')}>
                <SVG type={SVG_TYPE.EDIT} className="my-profile__edit-avatar" />
              </div>
            )}
          </div>
        </div>
        {isMobile && <div className="my-profile__header--radius"></div>}
      </header>

      <div className=" dashboard__content my-profile__content">
        <div className="dashboard__container learning-page--dashboard-container">
          <div className="my-profile__account-type-mobile">
            <div>{isAuthorizedUser && userType[authorizedUser?.type]}</div>
          </div>
          <div className="my-profile__content--column">
            <h3 className="my-profile__content-title">Konto</h3>
            {!isUserDemo && (
              <Bar
                title="Nazwa użytkownika"
                subTitle={isAuthorizedUser ? authorizedUser.name : 'Brak danych'}
                onClick={() => confirmedAuthUser.type !== 'demo' && setOpenedModal('changeUserName')}
              />
            )}
            {isUserAdmin && (
              <Bar
                title="Adres e-mail"
                subTitle={isAuthorizedUser ? authorizedUser.email : 'Brak danych'}
                onClick={() => setOpenedModal('changeUserEmail')}
              />
            )}
            {isUserDemo && (
              <Bar
                title="Załóż konto"
                subTitle="Wybierz"
                onClick={() => {
                  navigate({ pathname: ROUTES.LOGIN_PAGE });
                  sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
                }}
              />
            )}
            {isUserStandard && (
              <Bar
                title="Twój pakiet"
                subTitle="Wybierz pakiet - przejdź na konto Premium"
                onClick={() => navigate({ pathname: ROUTES.MYPROFILE_PACKET })}
              />
            )}
            {(isUserImpuls || isUserPremium) && (
              <Bar title="Twój pakiet" subTitle={`${isUserImpuls ? 'Uczeń impuls,' : 'Pakiet premium,'} ważny do ${deadline}`} />
            )}
            {(isUserImpuls || isUserPremium) && <Bar title="Miasto" subTitle={isAuthorizedUser && authorizedUser.city} />}
            {/* <div className="bar my-profile__list">
            <h4 className="my-profile__list-title">Dzięki dostępowi premium zyskujesz:</h4>
            <ol>
              <li className="my-profile__list-item">
                <SVG type={SVG_TYPE.CHECKED} />
                <p>Nielimitowane egzaminy</p>
              </li>
              <li className="my-profile__list-item">
                <SVG type={SVG_TYPE.CHECKED} />
                <p>Dostęp do szczegółowych statystyk</p>
              </li>
              <li className="my-profile__list-item">
                <SVG type={SVG_TYPE.CHECKED} />
                <p>Nielimitowane egzaminy</p>
              </li>
            </ol>
          </div> */}
            {!isUserAdmin && (
              <Bar
                title="Kategoria prawa jazdy"
                subTitle={isAuthorizedUser ? `Kat. ${authorizedUser.category.substring(0, 1)}` : 'Brak danych'}
                onClick={() => {
                  navigate(ROUTES.SELECT_CATEGORY);
                  localStorage.setItem('CHANGE_CATEGORY', 'true');
                }}
              />
            )}
          </div>
          <div className="my-profile__content--column">
            <h3 className="my-profile__content-title">Informacje</h3>
            {!isUserAdmin && (
              <>
                <Bar
                  title="Pomoc / kontakt"
                  onClick={() => {
                    navigate(ROUTES.CONTACT);
                  }}
                />
                <Bar title="Aktualna baza pytań" subTitle={`Ważna na dzień: ${moment(updateDataBaseAt).format('D.MM.YYYY')}`} />
              </>
            )}
            <Bar title="Polityka prywatności" />
            <Bar title="Regulamin" />
          </div>
          <div className="my-profile__content--column">
            <h3 className="my-profile__content-title">Inne</h3>
            {!isUserAdmin && (
              <>
                <Bar
                  title="Poleć innym"
                  onClick={() => {
                    navigate(ROUTES.RECOMMENDED_OTHERS);
                  }}
                />
                {confirmedAuthUser.type !== 'demo' && <Bar title="Usuń konto" onClick={() => setOpenedModal('deleteCurrentUser')} />}
              </>
            )}
            <Bar title="Wyloguj się" onClick={() => logout()} />
          </div>
        </div>
      </div>
      <ChangeUserNameModal
        isOpen={isChangeUserNameModalOpen}
        currentName={isAuthorizedUser ? authorizedUser.name : ''}
        onClose={() => setOpenedModal('')}
        changeUserName={(values) => changeUserName(values)}
      />
      <ChangeUserEmailModal
        isOpen={isChangeUserEmailModalOpen}
        currentEmail={isAuthorizedUser ? authorizedUser.email : ''}
        userName={isAuthorizedUser ? authorizedUser.name : ''}
        onClose={() => setOpenedModal('')}
        changeUserEmail={(values) => changeUserEmail(values)}
      />
      <ChangeAvatarModal editAvatar={editAvatar} user={authorizedUser} isOpen={isChangeAvatarModalOpen} onClose={() => setOpenedModal('')} />
      <DeleteCurrentUserModal
        isOpen={isDeleteCurrentUserModalOpen}
        onClose={() => setOpenedModal('')}
        deleteCurrentUser={() => deleteCurrentUser()}
      />
    </>
  );
}

export default MyProfile;
