import { Button, SVG } from 'Components';
import { useNavigate } from 'react-router';
import { ROUTES, USER_ROLES, SVG_TYPE } from 'Shared/enums';
import { useEffect, useState } from 'react';
import SingleCategory from './components/SingleCategory/SingleCategory';
import { MainType } from './Container';
import { generatePath } from 'react-router-dom';

function SelectCategory(props: MainType) {
  const { actions, drivingCategories, authorizedUser } = props;
  const { getDrivingCategory, setUserCategory, getAuthorizedUser } = actions;
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showError, setShowError] = useState(false);
  //get state from useNavigate

  useEffect(() => {
    getDrivingCategory();
    getAuthorizedUser();
  }, []);

  useEffect(() => {
    if (authorizedUser.category && drivingCategories.length > 0) {
      const category = drivingCategories.find((item) => item.label === authorizedUser.category);
      setSelectedCategory(category ? category.value : '');
    }
  }, [authorizedUser, drivingCategories]);
  const changeCategory = localStorage.getItem('CHANGE_CATEGORY');

  const handleSubmit = async () => {
    if (selectedCategory) {
      let result;
      if (changeCategory) {
        result = await setUserCategory(selectedCategory, false);
      } else {
        result = await setUserCategory(selectedCategory, true);
      }
      if (result) {
        if (authorizedUser.role === USER_ROLES.ADMIN) {
          navigate({ pathname: generatePath(ROUTES.USERS, { role: 'client', page: '1' }) });
        } else {
          // sessionStorage.setItem('refreshDepartments', 'true');
          navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
        }
        getAuthorizedUser();
      }
    } else {
      setShowError(true);
    }
  };

  const handleCategoryClick = (text) => {
    setSelectedCategory(text);
    setShowError(false);
  };

  return (
    <div className="select-category__wrapper login-page ">
      <main className="login-page__body">
        <div className="login-page__content-wrapper">
          <h2 className="login-page__title">
            {changeCategory && (
              <span onClick={() => navigate(-1)}>
                <SVG type={SVG_TYPE.CHEVRONE} />
              </span>
            )}
            Wybierz kategorię
          </h2>
          <p className="login-page__description">Wybierz kategorię prawa jazdy, do której chcesz się przygotować</p>
          <div className="select-category">
            {drivingCategories?.map((category) => (
              <SingleCategory key={category.label} category={category} onClick={handleCategoryClick} selectedCategory={selectedCategory} />
            ))}
          </div>
        </div>
        <div className={`login-page__error-category ${showError ? 'login-page__error-category--visible' : ''}`}>Pole wymagane</div>
        <div className="login-page__buttons">
          <Button label={'Potwierdź'} type="submit" onClick={() => handleSubmit()} />
        </div>
      </main>
    </div>
  );
}

export default SelectCategory;
