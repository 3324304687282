import { FieldProps } from 'formik';

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  secondValue?: string;
  maxLength?: number;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function Textarea({
  label,
  className = '',
  name,
  placeholder,
  required,
  type,
  form,
  errors = {},
  field,
  maxLength,
  onChange,
}: InputProps & FieldProps) {
  const handleOnChange = (e) => {
    if (type === 'number' && e.target.value.length > maxLength) return;
    field.onChange(e);
    onChange && onChange(e.target.value);
  };
  return (
    <div className={`input__wrapper ${className}`}>
      <label className="input__label" htmlFor={field.name}>
        {label} {required ? null : <p className="input__optional">(opcjonalne)</p>}
      </label>

      <textarea
        {...field}
        name={field.name}
        className="input input__textarea"
        value={field.value}
        onChange={handleOnChange}
        id={name}
        placeholder={placeholder}
        maxLength={maxLength}
      />

      {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default Textarea;
