import { useState, useEffect } from 'react';
import { TaskItem } from 'Screens/AdminDepartmentPage/components';
import { Button } from 'Components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

type TaskListType = {
  showLessonDetails: any;
  item: any;
  setRemoveTaskItem: any;
  setIsTaskAddOpen: any;
  tasksByLesson: any;
  changeTaskOrder: any;
};

const TaskList = ({ showLessonDetails, item, setIsTaskAddOpen, setRemoveTaskItem, tasksByLesson, changeTaskOrder }: TaskListType) => {
  const isActive = showLessonDetails === item.uuid;
  const [taskList, updateTaskList] = useState(tasksByLesson?.tasks);
  const [dragElement, setDragElement] = useState(false);
  const isDragElement = dragElement ? 'task-list__content--drag' : '';

  useEffect(() => {
    updateTaskList(tasksByLesson?.tasks);
  }, [tasksByLesson]);

  function findChangedIndexesWithIndices(originalArray, copiedArray) {
    const changedIndexes = [];

    originalArray.forEach((element) => {
      if (copiedArray.includes(element)) {
        const originalIndex = originalArray.indexOf(element);
        const copiedIndex = copiedArray.indexOf(element);

        if (originalIndex !== copiedIndex) {
          changedIndexes.push({
            element: element,
            originalIndex: originalIndex,
            newIndex: copiedIndex,
          });
        }
      }
    });

    changedIndexes.map(async (item) => {
      await changeTaskOrder(item.element.uuid, { index: item.newIndex + 1 }, copiedArray);
    });
  }

  function handleOnDragEnd(result) {
    setDragElement(false);
    if (!result.destination) return;
    const items = Array.from(tasksByLesson.tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateTaskList(items);
    findChangedIndexesWithIndices(tasksByLesson?.tasks, items);
  }

  return (
    <DragDropContext onBeforeDragStart={() => setDragElement(true)} onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="tasks">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={`task-list__content  ${isActive ? 'task-list__content--active' : ''}`}>
            {taskList &&
              taskList.map((task, index) => {
                return (
                  <Draggable key={task.uuid + index} draggableId={task.uuid} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <TaskItem index={index} key={task.uuid} item={task} setRemoveTaskItem={setRemoveTaskItem} />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            <div className={`task-list__add-task ${isDragElement}`}>
              <Button label="Dodaj zadanie +" onClick={() => setIsTaskAddOpen(item)} stroke />
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TaskList;
