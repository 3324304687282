import { connect } from 'react-redux';
import DepartmentsPage from './Main';
import { bindActionCreators } from 'redux';
import { getClientLessons } from 'Store/Actions/Lesson';
import { getLesson as getLessonsSelector } from 'Store/Selectors/LessonSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { User } from 'Shared/models/UserModel';
type ConnectedP = {
  lessons: any;
  authorizedUser: User | 'init';
};

type DispatchedP = {
  actions: {
    getClientLessons: (uuid) => (dispatch: any) => any;
  };
};
const mapStateToProps = (state) => ({
  lessons: getLessonsSelector(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getClientLessons:getClientLessons,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsPage);
