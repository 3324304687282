/* eslint-disable react/prop-types */
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { User } from 'Shared/models/UserModel';

type SingleUserType = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  userRestoration: (uuid) => any;
};

function RestoreUserModal({ user, isOpen, onClose, userRestoration }: SingleUserType): JSX.Element {
  const { name, phonePrefix, phoneNumber, uuid } = user || {};

  const handleUserRestoration = async () => {
    const response = await userRestoration(uuid);
    response && onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`restore-user-modal`}>
      <h2 className="restore-user-modal__header">Przywracanie użytkownika</h2>
      <p className="restore-user-modal__description">Czy na pewno przywrócić użytkownika:</p>
      <p className="restore-user-modal__user">
        {name} +{phonePrefix} {phoneNumber}
      </p>
      <div className="restore-user-modal__buttons">
        <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
        <Button label="Przywróć" className="add-user-modal__add-button" type="submit" onClick={() => handleUserRestoration()} />
      </div>
    </Modal>
  );
}

export default RestoreUserModal;
