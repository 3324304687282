import { SVG, Button } from 'Components';
import { useEffect } from 'react';
import { ROUTES, SVG_TYPE, USER_ROLES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { MainType } from './Container';
import { generatePath } from 'react-router';

const TransactionSuccess = (props: MainType) => {
  const { authorizedUser } = props;
  const registerAfterExam = JSON.parse(localStorage.getItem('SUMMARY_EXAM_ID'));

  const navigate = useNavigate();
  useEffect(() => {
    const slideRoutesElement = document.getElementsByClassName('slide-routes')[0] as HTMLElement;
    if (slideRoutesElement) {
      slideRoutesElement.style.width = '100vw';
    }
    return () => {
      if (slideRoutesElement) {
        slideRoutesElement.style.width = 'calc(100vw - 72px)';
      }
    };
  }, []);

  const handleRedirect = () => {
    if (authorizedUser && authorizedUser != 'init') {
      if (authorizedUser.role === USER_ROLES.CLIENT) {
        navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
      } else {
        navigate({ pathname: ROUTES.USERS });
      }
    } else {
      navigate({ pathname: ROUTES.LOGIN_PAGE });
    }
  };

  return (
    <>
      <div className="transaction-success__wrapper">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

        <div className="page404__top-logo">
          <p onClick={() => handleRedirect()}>
            <SVG type={SVG_TYPE.LOGO_ROYAL} />
          </p>
        </div>

        <div className="transaction-success__content">
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--third" />
          <SVG type={SVG_TYPE.TRANSACTION_SUCCESS} />
          <p className="transaction-success__header">Transakcja przebiegła poprawnie 🎉</p>
          <p className="transaction-success__paragraph">Możesz już korzystać z nieograniczonych możliwości konta PREMIUM!</p>
          <Button
            label={`${registerAfterExam ? 'Wróć do podsumowania' : 'Wróć do profilu'}`}
            onClick={() => {
              if (registerAfterExam) {
                navigate({ pathname: ROUTES.DRIVING_TEST_SUMMARY.replace('uuid', registerAfterExam) });
              } else {
                navigate({ pathname: ROUTES.MYPROFILE });
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionSuccess;
