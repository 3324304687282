import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

type ValidationItemProps = {
  correct: boolean;
  label: string;
};

function ValidationItem({ correct, label }: ValidationItemProps) {
  return (
    <div className="validation-item">
      <SVG type={correct ? SVG_TYPE.CIRCLE_GREEN : SVG_TYPE.CIRCLE_RED} />
      <p>{label}</p>
    </div>
  );
}
export default ValidationItem;
