import { connect } from 'react-redux';
import QuestionsDatabasePage from './Main';
import { bindActionCreators } from 'redux';
import { getQuestions, editQuestion, updateDatabaseDate } from 'Store/Actions/Question';
import { getQuestions as getQuestionsSelector } from 'Store/Selectors/QuestionSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { User } from 'Shared/models/UserModel';

type ConnectedP = {
  questions: any;
  authorizedUser: User | 'init';
};

type DispatchedP = {
  actions: {
    getQuestions: (data) => void;
    editQuestion: (uuid,data) => void;
    updateDatabaseDate: (data) => any;
  };
};
const mapStateToProps = (state) => ({
  questions: getQuestionsSelector(state),
  authorizedUser: authorizedUser(state),

});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getQuestions: getQuestions,
      editQuestion: editQuestion,
      updateDatabaseDate: updateDatabaseDate,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsDatabasePage);
