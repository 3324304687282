import { Button, Modal, Select } from 'Components';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES } from 'Shared/enums';

type AddTaskType = {
  isOpen: boolean;
  onClose: () => void;
  addTask: (data) => void;
  lessonUuid: string;
  questionsByDepartment: any;
};

const AddTask = ({ isOpen, onClose, addTask, lessonUuid, questionsByDepartment }: AddTaskType) => {
  const initialValues = {
    questionUuid: '',
    lessonUuid,
  };

  const validationSchema = Yup.object().shape({
    questionUuid: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
  });

  const handleOnSubmit = async (values, { resetForm }) => {
    await addTask(values);
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-mobile--full">
      <h2 className="edit-user-modal__header">Dodawanie zadania</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => handleOnSubmit(values, actions)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="add-lesson-form">
            <Field
              className={''}
              label="Wybierz pytanie z bazy"
              name="questionUuid"
              placeholder="Szukaj pytania"
              required
              isSearchable
              options={questionsByDepartment || []}
              component={Select}
              errors={errors}
            />
            <div className="add-lesson-form__buttons">
              <Button label="Anuluj" type="button" onClick={() => onClose()} stroke />
              <Button label="Potwierdź" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTask;
