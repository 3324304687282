import * as Lessons from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Lesson.ADD_LESSON_INIT,
});

export const success = (data) => ({
  type: Lesson.ADD_LESSON_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.ADD_LESSON_FAILURE,
});

const addLesson = (data) => async (dispatch) => {
  const id = toast.loading('Trwa dodawanie lekcji...');
  dispatch(init());
  try {
    const response = await Lessons.Connector.addLesson(data);
    dispatch(success(response.data));
    toast.update(id, {
      render: 'Pomyślnie dodano lekcję',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się dodać lekcji',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default addLesson;
