import { useEffect, useState } from 'react';
import { LessonItem, TaskList } from 'Screens/AdminDepartmentPage/components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

type LessonListType = {
  lessons: any;
  handleDeleteLesson: any;
  setIsLessonEditOpen: any;
  editLesson: any;
  setIsTaskAddOpen: any;
  setRemoveTaskItem: any;
  getTasks: any;
  tasksByLesson: any;
  changeTaskOrder: any;
};

const LessonList = ({
  lessons,
  handleDeleteLesson,
  setIsLessonEditOpen,
  setIsTaskAddOpen,
  setRemoveTaskItem,
  getTasks,
  tasksByLesson,
  editLesson,
  changeTaskOrder,
}: LessonListType) => {
  const [showLessonDetails, setShowLessonDetails] = useState(null);
  const [lessonList, updateLessonList] = useState(lessons);

  useEffect(() => {
    showLessonDetails && getTasks(showLessonDetails);
  }, [showLessonDetails]);

  useEffect(() => {
    updateLessonList(lessons);
  }, [lessons]);

  const showDetails = (element) => {
    element.uuid === showLessonDetails ? setShowLessonDetails(false) : setShowLessonDetails(element.uuid);
  };

  function findChangedIndexesWithIndices(originalArray, copiedArray) {
    const changedIndexes = [];

    originalArray.forEach((element) => {
      if (copiedArray.includes(element)) {
        const originalIndex = originalArray.indexOf(element);
        const copiedIndex = copiedArray.indexOf(element);
        if (originalIndex !== copiedIndex) {
          changedIndexes.push({
            element: element,
            originalIndex: originalIndex,
            newIndex: copiedIndex,
          });
        }
      }
    });

    changedIndexes.map(async (item) => {
      // const lastElement = idx + 1 === changedIndexes.length;
      await editLesson(item.element.uuid, { name: item.element.name, index: item.newIndex + 1 }, true, copiedArray);
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(lessonList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateLessonList(items);
    findChangedIndexesWithIndices(lessons, items);
  }

  return (
    <DragDropContext onBeforeDragStart={() => setShowLessonDetails(null)} onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="lessons">
        {(provided) => {
          return (
            <div className="lesson-list lessons" {...provided.droppableProps} ref={provided.innerRef}>
              {lessonList.map((item, index) => {
                return (
                  <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                    {(provided) => {
                      return (
                        <>
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <LessonItem
                              showLessonDetails={showLessonDetails}
                              showDetails={showDetails}
                              handleDeleteLesson={handleDeleteLesson}
                              key={item.uuid}
                              item={item}
                              index={index}
                              setIsLessonEditOpen={setIsLessonEditOpen}
                            />
                          </div>
                          <TaskList
                            changeTaskOrder={changeTaskOrder}
                            tasksByLesson={tasksByLesson}
                            setRemoveTaskItem={setRemoveTaskItem}
                            setIsTaskAddOpen={setIsTaskAddOpen}
                            item={item}
                            showLessonDetails={showLessonDetails}
                          />
                        </>
                      );
                    }}
                  </Draggable>
                );
              })}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default LessonList;
