import { useState } from 'react';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { DropFileInput } from 'Components/form';
import { Field, Formik } from 'formik';

type changePasswordModalType = {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  editAvatar: (uuid, file, boolean) => void;
};

function ChangeUserNameModal({ isOpen, onClose, user, editAvatar }: changePasswordModalType): JSX.Element {
  const [isNewFile, setIsNewFile] = useState(false);

  const initialValues = {
    avatar: user?.avatar.length > 0 ? user?.avatar : null,
  };

  const handleOnSubmit = async (values) => {
    isNewFile && (await editAvatar(user.uuid, values.avatar[0], true));
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal`}>
      <h2 className="edit-user-modal__header">Zmiana avatara</h2>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={async (values) => handleOnSubmit(values)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field
              className={''}
              label="Avatar"
              name="avatar"
              placeholder="Przeciągnij i upuść lub"
              required
              component={DropFileInput}
              errors={errors}
              onFileDrop={() => setIsNewFile(true)}
              removeAvatar={() => {
                editAvatar(user.uuid, null, false);
                setIsNewFile(false);
              }}
              accept="image/*"
            />
            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default ChangeUserNameModal;
