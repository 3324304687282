import * as LessonConnector from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Lesson.START_LESSONS_INIT,
});

export const success = (data) => ({
  type: Lesson.START_LESSONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.START_LESSONS_FAILURE,
});

const startLessons = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await LessonConnector.Connector.startLessons(uuid);
    dispatch(success(response.data.lesson));
    return response.data.lesson;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default startLessons;
