import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

export const success = () => ({
  type: Auth.LOGIN_AS_DEMO_SUCCESS,
});

const failure = () => ({
  type: Auth.LOGIN_AS_DEMO_FAILURE,
});

const createDemoUser = () => async (dispatch) => {
  try {
    const result = await AuthModule.Connector.createDemoUser();
    const uuid = result.data.userUuid;
    localStorage.setItem('demoUserUuid', uuid);
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default createDemoUser;
