import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SVG } from 'Components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';

type LessonTileType = {
  item?: any;
  index?: number;
  isLoading?: boolean;
};
const LessonTile = ({ item, index, isLoading }: LessonTileType) => {
  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <div className="lesson-tile">
          <div className="lesson-tile__left">
            <Skeleton />
          </div>
          <div className="lesson-tile__mid">
            <p className="lesson-tile__title">
              <Skeleton height="14" />
            </p>
            <p className="lesson-tile__descript">
              <Skeleton height="6" />
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className={`lesson-tile ${item.isBlocked ? 'lesson-tile--blocked' : ''}`}>
            {item?.isBlocked ? (
              <div className="lesson-tile__left lesson-tile__left--blocked">
                <SVG type={SVG_TYPE.PADLOCK} />
              </div>
            ) : item.passedLesson ? (
              <div className="lesson-tile__left lesson-tile__left--completed">
                <SVG type={SVG_TYPE.CIRCLE_CHECKED} />
              </div>
            ) : (
              <div className="lesson-tile__left lesson-tile__left--open">{index + 1}</div>
            )}
            <div className="lesson-tile__mid">
              <p className="lesson-tile__title">{item.name}</p>
              <p className="lesson-tile__descript">Ilość zadań: {item.tasksCount}</p>
            </div>
            <div className="lesson-tile__right" onClick={() => navigate({ pathname: ROUTES.LESSON.replace('uuid', item.uuid) })}>
              <SVG type={SVG_TYPE.BACK_CHEVRON} />
            </div>
            <div className="lesson-tile__onclick" onClick={() => navigate({ pathname: ROUTES.LESSON.replace('uuid', item.uuid) })} />
          </div>
        </>
      )}
    </>
  );
};

export default LessonTile;
