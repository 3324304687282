import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Auth.GET_PHONE_PREFIXES_INIT,
});

export const success = (phonePrefixes) => ({
  type: Auth.GET_PHONE_PREFIXES_SUCCESS,
  payload: {
    phonePrefixes,
  },
});

const failure = () => ({
  type: Auth.GET_PHONE_PREFIXES_FAILURE,
});

const getPhonePrefixes = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.getPhonePrefixes();
    dispatch(success(response.data.phonePrefixSerialize));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getPhonePrefixes;
