import { easeQuadInOut } from 'd3-ease';
import { AnimatedProgressProvider } from 'Screens/ExamPage/components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

type StatisticBoxType = {
  heading: string;
  numbers: string;
  numberText: string;
  percentage: any;
  procentText: string;
  icon: string;
  label: string;
};

const StatisticsBox = ({ heading, numbers, numberText, percentage, procentText, icon, label }: StatisticBoxType) => {
  return (
    <div className="user-statistics">
      <div className="user-statistics__heading">{heading}</div>
      <div className={`user-statistics__box ${label === 'Egzamin' ? 'user-statistics__box--exam' : ''}`}>
        <div className="user-statistics__label">{label}</div>
        <div className={`user-statistics__numbers ${label === 'Egzamin' ? 'user-statistics__numbers--exam' : ''}`}>{numbers}</div>
        <div className={`user-statistics__number-text ${label === 'Egzamin' ? 'user-statistics__number-text--exam' : ''}`}>{numberText}</div>
        <div className="user-statistics__circle">
          <AnimatedProgressProvider valueStart={0} valueEnd={percentage} duration={4} easingFunction={easeQuadInOut}>
            {(value) => {
              const roundedValue = Math.round(value);
              let color;
              if (percentage <= 50) {
                color = 'rgba(255, 108, 108, 1)';
              } else if (percentage <= 50 && percentage >= 80) {
                color = `rgba(255, 146, 84, 1)`;
              } else {
                color = 'rgba(0, 180, 115, 1)';
              }
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  strokeWidth={10}
                  styles={buildStyles({
                    pathTransition: 'none',
                    pathColor: color,
                    textColor: color,
                    trailColor: `${label === 'Egzamin' ? 'rgba(245, 244, 255, 1)' : 'rgba(216, 215, 255, 1)'}`,
                    textSize: '32px',
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
        </div>
        <div className={`user-statistics__procent-text ${label === 'Egzamin' ? 'user-statistics__procent-text--exam' : ''}`}>{procentText}</div>
        <div className="user-statistics__number-icon">{icon}</div>
      </div>
    </div>
  );
};

export default StatisticsBox;
