type DashboardCategoryProps = {
  type: string;
  setSelectedType: (type: string) => void;
  selectedType: string;
  label: string;
  style?: 'small' | 'big';
};

function DashboardCategory({ type, setSelectedType, selectedType, label, style }: DashboardCategoryProps) {
  return (
    <p
      className={`dashboard__category admin-learning-page__dashboard__category--${style}   ${
        selectedType === type ? 'dashboard__category--active' : ''
      }`}
      onClick={() => setSelectedType(type)}>
      {label}
    </p>
  );
}
export default DashboardCategory;
