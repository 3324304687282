import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { store } from 'Store/Store';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = (token) => ({
  type: Auth.LOGIN_AS_DEMO_SUCCESS,
  payload: {
    token: token,
  },
});

const failure = () => ({
  type: Auth.LOGIN_AS_DEMO_FAILURE,
});

const loginAsDemo = (userUuid: string) => async (dispatch) => {
  const id = toast.loading('Trwa logowanie...');
  try {
    const data = await AuthModule.Connector.loginAsDemo(userUuid);
    dispatch(success(data.data.token));
    await dispatch(AuthModule.Actions.getAuthorizedUser());
    sessionStorage.setItem('category', store.getState().authStore.authorizedUser.category);
    toast.update(id, {
      render: 'Jesteś zalogowany jako użytkownik demo',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się zalogować jako user demo',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default loginAsDemo;
