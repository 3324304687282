import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { drivingCategoryType } from 'Shared/models/UserModel';

type SingleCategoryProps = {
  onClick?: (text) => void;
  selectedCategory?: string;
  category: drivingCategoryType;
};

function SingleCategory({ onClick, selectedCategory, category }: SingleCategoryProps) {
  const getSvgType = () => {
    switch (category.label[0]) {
      case 'A':
        return SVG_TYPE.MOTOR;
      case 'B':
        return SVG_TYPE.CAR;
      case 'C':
        return SVG_TYPE.DELIVERY_TRUCK;
      case 'D':
        return SVG_TYPE.BUS;
      default:
        null;
    }
  };
  return (
    <div
      className={`single-category ${selectedCategory === category.value ? 'single-category--selected' : ''}`}
      onClick={() => onClick && onClick(category.value)}>
      <p>{category.label}</p>
      <SVG type={getSvgType()} />
    </div>
  );
}

export default SingleCategory;
