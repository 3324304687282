import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { AdminEdit, UserEdit } from 'Shared/models/UserModel';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = (data) => ({
  type: User.EDIT_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.EDIT_USER_FAILURE,
});

const editUser = (userData: UserEdit | AdminEdit, uuid) => async (dispatch) => {
  const id = toast.loading('Trwa edycja użytkownika...');
  try {
    const response = await UserModule.Connector.editUser(userData, uuid);
    dispatch(success(response.data.user));
    toast.update(id, {
      render: 'Pomyślnie edytowano użytkownika',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return response;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się edytować użytkownika',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return err?.response?.data.errors;
  }
};

export default editUser;
