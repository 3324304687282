import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function addLesson(data): AxiosPromise<any> {
  return API.post(`lessons`, data);
}

export function editLesson(uuid, data): AxiosPromise<any> {
  return API.put(`lessons/${uuid}`, data);
}

export function getLessons(uuid): AxiosPromise<any> {
  return API.get(`lessons?departmentUuid=${uuid}`);
}

export function getSingleLessons(uuid): AxiosPromise<any> {
  return API.put(`departments?lessonUuid=${uuid}`);
}

export function deleteLesson(uuid): AxiosPromise<any> {
  return API.delete(`lessons/${uuid}`);
}

export function startLessons(uuid): AxiosPromise<any> {
  return API.get(`start/lessons?lessonUuid=${uuid}`);
}

export function saveTask(data): AxiosPromise<any> {
  return API.post(`save/task`, {taskUuid: data});
}

export function getClientLessons(uuid): AxiosPromise<any> {
  return API.get(`clients/lessons?departmentUuid=${uuid}`);
}

export function answerTask(data): AxiosPromise<any> {
  return API.post(`lessons/answer/task`, data);
}

export function endLesson(uuid): AxiosPromise<any> {
  return API.get(`end/lessons?lessonUuid=${uuid}`);
}
