import { AdminEdit, UserEdit } from 'Shared/models/UserModel';
import API from './Config';
import { AxiosPromise } from 'axios';

export function getUsers(role, page): AxiosPromise<any> {
  return API.get('users', { params: { role, page } });
}

export function deleteUser(uuid: string): AxiosPromise<any> {
  return API.delete(`users/${uuid}`);
}

export function addAdmin(userData: AdminEdit): AxiosPromise<any> {
  return API.post('users/admin', userData);
}

export function editUser(userData: UserEdit | AdminEdit, uuid: string): AxiosPromise<any> {
  return API.put(`users/${uuid}`, userData);
}

export function manageUserSuspension(uuid: string): AxiosPromise<any> {
  return API.put(`users/suspended/${uuid}`);
}

export function editAvatar(uuid: string, avatar: any): AxiosPromise<any> {
  return API.put(`avatar/${uuid}`, avatar);
}

export function getDrivingCategory(): AxiosPromise<any> {
  return API.get('driving-categories');
}

export function setUserCategory(categoryUuid: string): AxiosPromise<any> {
  return API.put(`users/drivingCategory`, { categoryUuid });
}

export function userRestoration(uuid: string): AxiosPromise<any> {
  return API.put(`users/restore/${uuid}`);
}

export function changeUserName(name: string): AxiosPromise<any> {
  return API.put(`users/me`, { name });
}

export function changeUserEmail(values: object): AxiosPromise<any> {
  return API.put(`users/me`,  values );
}

export function deleteCurrentUser(): AxiosPromise<any> {
  return API.delete(`users/delete`);
}

export function getUserExam(): AxiosPromise<any> {
  return API.get(`users/exam`);
}

export function getUserStats(data): AxiosPromise<any> {
  return API.get(`users/stats/${data?.userUuid}?category=${data?.category}`);
}

export function sendMail(form): AxiosPromise<any> {
  return API.post(`contact/mail`, form);
}

