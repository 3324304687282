import Button from 'Components/Button';
import { MainType } from './Container';
import { Field, Formik } from 'formik';
import { Checkbox, Input, Select } from 'Components/form';
import { phoneNumberValidation } from 'Shared/utils';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE, USER_ROLES, VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import SVG from 'Components/SVG';
import { generatePath } from 'react-router-dom';

function LoginPage(props: MainType) {
  const { actions, phonePrefixes, authorizedUser } = props;
  const { getSmsCode, getPhonePrefixes, createDemoUser, loginAsDemo, getAuthorizedUser } = actions;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const formRef = useRef(null);
  const demoToStandard = sessionStorage.getItem('DEMO_TO_STANDARD');
  const localStoragePhone = localStorage.getItem('phoneNumber');

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  useEffect(() => {
    if (!demoToStandard) {
      if (!!authorizedUser && authorizedUser !== 'init') {
        if (authorizedUser.role === USER_ROLES.ADMIN) {
          navigate({ pathname: ROUTES.STATISTICS });
        } else {
          if (authorizedUser.category) {
            navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
          } else {
            navigate(ROUTES.SELECT_CATEGORY);
          }
        }
      }
    }
  }, [authorizedUser]);

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .when('phonePrefixUuid', (phonePrefixUuid, schema) => phoneNumberValidation(phonePrefixUuid, schema)),
    terms: Yup.boolean().oneOf([true], VALIDATION_MESSAGES.TERMS),
  });
  const onSubmit = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const result = await getSmsCode({ phoneNumber: values.phoneNumber, phonePrefixUuid: values.phonePrefixUuid, userUuid: values.userUuid });
    if (result) {
      navigate({ pathname: ROUTES.ENTER_SMS_CODE_PAGE, search: `?phoneNumber=${values.phoneNumber}&phonePrefixUuid=${values.phonePrefixUuid}` });
    } else {
      setShowError(true);
      // props.setFieldError('phoneNumber', 'Błąd logowania');
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getPhonePrefixes();
  }, []);

  const phonePrefixesOptions = phonePrefixes.map((item) => {
    return {
      value: item.value,
      label: `+ ${item.phonePrefix}`,
      svgType: SVG_TYPE[`FLAG_${item.country.toUpperCase()}`],
    };
  });

  const handleDemoLogin = async () => {
    if (formRef.current.values.terms) {
      const userUuid = localStorage.getItem('demoUserUuid');
      if (!userUuid) {
        await createDemoUser();
      }
      loginAsDemo(localStorage.getItem('demoUserUuid'));
    } else {
      formRef.current.setFieldTouched('terms', true);
    }
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('DEMO_TO_STANDARD');
    };
  }, []);

  return (
    <div className="login-page ">
      <Formik
        validationSchema={validationSchema}
        isInitialValid={false}
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          phoneNumber: localStoragePhone ? localStoragePhone : '',
          terms: false,
          phonePrefixUuid: phonePrefixesOptions[0]?.value,
          userUuid: demoToStandard && authorizedUser != 'init' ? authorizedUser.uuid : '',
        }}
        onSubmit={(values) => {
          localStorage.setItem('phoneNumber', values.phoneNumber);
          onSubmit(values);
        }}>
        {({ handleSubmit, isValid, errors, setFieldTouched, isSubmitting }) => (
          <main className="login-page__body">
            <div className="login-page__content-wrapper">
              <h2 className="login-page__title">
                {demoToStandard && (
                  <span onClick={() => navigate(-1)}>
                    <SVG type={SVG_TYPE.CHEVRONE} />
                  </span>
                )}
                {demoToStandard ? 'Załóż konto' : 'Zaloguj się'}
              </h2>
              <p className="login-page__description">
                Logując się nie utracisz swoich postępów w&nbsp;nauce. Aby upewnić się, że ten numer należy do Ciebie, wyślemy Ci darmowy kod dostępu.
              </p>
              <p className={`login-page__error ${showError ? 'login-page__error--show' : ''}`}>
                <span>Błąd logowania</span>
                <SVG type={SVG_TYPE.WARNING} />
              </p>
              <form onSubmit={handleSubmit} className="login-page__form login-page__form--phone">
                <Field
                  component={Select}
                  label="Telefon"
                  name="phonePrefixUuid"
                  options={phonePrefixesOptions}
                  errors={errors}
                  className={'login-page__select'}
                  required
                  placeholder=" "
                />
                <Field component={Input} type="number" required name="phoneNumber" errors={errors} className={'login-page__input'} />
                <SVG type={SVG_TYPE.CURLY_ARROW} className="login-page__curly-arrow" />
                <div className="login-page__tooltip">
                  Potrzebujemy Twojego numeru jedynie do szybkiego i&nbsp;prostego logowania, bez podawania żadnych innych danych. Nasz system
                  logowania jest w&nbsp;<b>pełni bezpieczny</b> i&nbsp;zapewnia ochronę Twojego konta.
                  <SVG type={SVG_TYPE.INFO} />
                </div>
                <div className="login-page__buttons">
                  <Field component={Checkbox} name="terms" className={'login-page__checkbox'} errors={errors} required>
                    <p className="login-page__terms">
                      Zapoznałem się i akceptuję
                      <a target="_blank" rel="noreferrer" href="https://www.google.com/search?&q=tu%20b%C4%99dzie%20regulamin">
                        Regulamin
                      </a>
                      i
                      <a target="_blank" rel="noreferrer" href="https://www.google.com/search?&q=tu%20b%C4%99dzie%Polityka%20Prywatności">
                        Politykę prywatności
                      </a>
                    </p>
                  </Field>
                  {!demoToStandard && <Button label={'Zobacz wersję DEMO'} onClick={() => handleDemoLogin()} stroke />}
                  <Button
                    label={'Wyślij kod dostępu'}
                    type="submit"
                    onClick={() => {
                      demoToStandard && sessionStorage.setItem('DEMO_TO_STANDARD_SMS', 'true');
                      setFieldTouched('phoneNumber', true);
                      setFieldTouched('terms', true);
                      setFieldTouched('phonePrefix', true);
                      isValid && !isSubmitting && handleSubmit();
                    }}
                  />
                </div>
              </form>
            </div>
          </main>
        )}
      </Formik>
    </div>
  );
}

export default LoginPage;
