import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Auth.GET_CITIES_INIT,
});

export const success = (cities) => ({
  type: Auth.GET_CITIES_SUCCESS,
  payload: {
    cities,
  },
});

const failure = () => ({
  type: Auth.GET_CITIES_FAILURE,
});

const getcities = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.getCities();
    dispatch(success(response.data));
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getcities;
