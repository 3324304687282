import Button from 'Components/Button';
import { MainType } from './Container';

function SuccessfulPasswordResetLinkSendPage(props: MainType) {
  const { actions } = props;
  const { sendPasswordResetLink } = actions;
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');

  return (
    <div className="login-page ">
      <main className="login-page__body">
        <div className="login-page__content-wrapper">
          <h2 className="login-page__title">Wysłano link do resetu hasła</h2>
          <p className="login-page__description login-page__description--successful-password-link-send">
            Jeśli podany przez Ciebie adres mailowy jest zgodny z&nbsp;adresem wprowadzonym przy zakładaniu konta, to powinieneś otrzymać wiadomość na
            Twoją skrzynkę mailową.
            <br />
            <br />
            <b>W celu zresetowania hasła, kliknij w przesłany link.</b>
          </p>
        </div>
        <div className="login-page__buttons">
          <p className="login-page__send-link-again">Link nie dotarł?</p>
          <Button
            label={'Wyślij ponownie'}
            onClick={() => {
              sendPasswordResetLink({ email: email });
            }}
          />
        </div>
      </main>
    </div>
  );
}

export default SuccessfulPasswordResetLinkSendPage;
