/* eslint-disable react/prop-types */
import { useState } from 'react';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { DropFileInput, Input, Select } from 'Components/form';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { drivingCategoryType } from 'Shared/models/UserModel';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
// import { shortDrivingCategories } from 'Screens/AdminLearningPage/utils';

type EditDepartmentType = {
  isOpen: boolean;
  onClose: () => void;
  drivingCategories?: drivingCategoryType[];
  data: any;
  editDepartment: (uuid, data) => any;
  addDepartmentPhoto: (uuid, file, boolean) => any;
};

function EditDepartment({ isOpen, onClose, data, editDepartment, addDepartmentPhoto }: EditDepartmentType): JSX.Element {
  const [isNewFile, setIsNewFile] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .max(150, VALIDATION_MESSAGES.MAX150)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    status: Yup.mixed()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('arrayNotEmpty', VALIDATION_MESSAGES.REQUIRED, (value) => value && value.length > 0),
    categoryUuids: Yup.array()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('arrayNotEmpty', VALIDATION_MESSAGES.REQUIRED, (value) => value && value.length > 0),
    avatar: Yup.mixed().required(VALIDATION_MESSAGES.REQUIRED).nullable(),
  });

  const modifiedCategories = () => {
    const modifiedArray = data?.categories.map((item) => {
      const newItem = { ...item };
      newItem.value = newItem.uuid;
      delete newItem.uuid;
      return newItem;
    });
    return modifiedArray;
  };

  const initialValues = {
    name: data?.name,
    categoryUuids: modifiedCategories(),
    status: data?.status,
    avatar: data?.file,
  };

  const handleOnSubmit = async (values) => {
    if (isNewFile) {
      await addDepartmentPhoto(data.uuid, values.avatar, true);
    }

    await editDepartment(data.uuid, values);
    setIsNewFile(false);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`modal-mobile--full edit-department-modal`}>
      <h2 className="edit-user-modal__header">Edycja działu </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => handleOnSubmit(values)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field className={''} label="Nazwa działu" name="name" placeholder="Wpisz nazwę działu" required component={Input} errors={errors} />
            <Field
              className={''}
              label="Grafika"
              name="avatar"
              placeholder="Przeciągnij i upuść lub"
              required
              component={DropFileInput}
              errors={errors}
              accept="image/*"
              onFileDrop={() => setIsNewFile(true)}
              removeAvatar={() => setIsNewFile(true)}
            />
            {/* <Field
              component={Select}
              label="Kategoria"
              name="categoryUuids"
              options={shortDrivingCategories(drivingCategories)}
              errors={errors}
              className={'login-page__select'}
              required
              isMulti
              placeholder="Wybierz kategorię"
            /> */}
            <Field
              component={Select}
              label="Status"
              name="status"
              options={[
                { label: 'Opublikowany', value: 'active' },
                { label: 'Nieopublikowany', value: 'inactive' },
              ]}
              errors={errors}
              className={'login-page__select'}
              required
            />

            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="edit-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Potwierdź" className="edit-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditDepartment;
