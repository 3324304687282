import { useEffect, useState } from 'react';
import { SVG_TYPE, ROUTES, USER_TYPE } from 'Shared/enums';
import { SVG, Button } from 'Components';
import { MainType } from './Container';
import { useNavigate } from 'react-router-dom';
import { AnswerNotification, Question, LessonExplanation, SpecializedQuestionButtons, BasicQuestionButtons } from 'Screens/LessonPage/components';
import { generatePath } from 'react-router-dom';

function SaveTaskPage(props: MainType) {
  const { actions, singleSaveTask, authorizedUser } = props;
  const { getSingleSaveTask, saveTask, answerSaveTask } = actions;
  const [isPropertyAnswer, setIsPropertyAnswer] = useState(null);
  const [activeTask, setActiveTask] = useState(undefined);
  const [answer, setAnswer] = useState(undefined);
  const isUserLogged = !!authorizedUser && authorizedUser !== 'init';
  const userType = isPropertyAnswer && isUserLogged && authorizedUser.type;
  const isUserStandard = isUserLogged && authorizedUser.type === USER_TYPE.STANDARD;
  const isUserDemo = isUserLogged && authorizedUser.type === USER_TYPE.DEMO;
  const isSpecialized = false;
  const isBasicQuestion = activeTask && activeTask.question.type === 'PODSTAWOWY';
  const buttonLabel = isPropertyAnswer ? 'Wróć do zapisanych pytań' : 'Sprawdź';
  const isButtonShow = answer ? 'lesson-page__button--show lesson-page__button--save-task' : 'lesson-page__button--hide';
  const image = activeTask?.question?.file?.url;
  const explanation = activeTask?.question?.explanation;

  const navigate = useNavigate();
  const isMobile = window.innerWidth < 1024;
  const pathname = window.location.pathname;
  const taskUuid = pathname.split(':')[1];

  useEffect(() => {
    getSingleSaveTask(taskUuid);
  }, []);

  useEffect(() => {
    setActiveTask(singleSaveTask);
  }, [singleSaveTask]);

  const handleChoice = (choice) => {
    setAnswer(choice);
    setIsPropertyAnswer(undefined);
  };

  const handleCheckAnswer = async () => {
    if (buttonLabel === 'Sprawdź') {
      // !lastTask && localStorage.setItem('lessonPage', JSON.stringify({ uuid: lessonUuid, taskNumber: taskNumber + 1 }));
      const response = await answerSaveTask(activeTask.uuid, answer);
      setActiveTask(response);

      if (isMobile) {
        const activeBox = isBasicQuestion
          ? document.getElementsByClassName('basic-question-button--choice')[0]
          : document.getElementsByClassName('box--active')[0];
        activeBox.scrollIntoView({ behavior: 'smooth', block: 'center' });
        response.propertyAnswer === answer ? setIsPropertyAnswer('T') : setIsPropertyAnswer('N');
        setIsPropertyAnswer(response.question.propertyAnswer);
      } else {
        response.propertyAnswer === answer ? setIsPropertyAnswer('T') : setIsPropertyAnswer('N');
        setIsPropertyAnswer(response.question.propertyAnswer);
      }
    } else {
      navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'save_question' }) });
    }
  };

  return (
    <>
      <header className="dashboard__header learning-page__header">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
        <div className="dashboard__container departments-page__header-container">
          <h1 className="dashboard__title departments-page__title">
            <span
              className="dashboard__title--onclick"
              onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'save_question' }) })}>
              Wróć
            </span>
            <div onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'save_question' }) })} className="dashboard__goBack">
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          </h1>
        </div>
      </header>
      {activeTask && (
        <div className="page-wrapper page-wrapper--save-task">
          <div className={`dashboard__container lesson-page__container`}>
            <>
              <div className={`lesson-page__left `}>
                <Question saveTask={saveTask} activeTask={activeTask} />
                <div className={`lesson-page__answer-buttons ${isSpecialized ? 'lesson-page__answer-buttons--specialized' : ''}`}>
                  {isBasicQuestion ? (
                    <BasicQuestionButtons isPropertyAnswer={isPropertyAnswer} handleChoice={handleChoice} answer={answer} />
                  ) : (
                    <SpecializedQuestionButtons
                      activeTask={activeTask}
                      isPropertyAnswer={isPropertyAnswer}
                      handleChoice={handleChoice}
                      answer={answer}
                    />
                  )}
                  <AnswerNotification answer={answer} isPropertyAnswer={isPropertyAnswer} />
                  {isMobile && (image || explanation) && (
                    <LessonExplanation
                      showNotify={true}
                      isUserDemo={isUserDemo}
                      isUserStandard={isUserStandard}
                      activeTask={activeTask}
                      userType={userType}
                    />
                  )}
                  <Button label={`${buttonLabel}`} className={`${isButtonShow}`} onClick={() => handleCheckAnswer()} />
                </div>
              </div>
              <div className="lesson-page__right">
                {!isMobile && (image || explanation) && (
                  <LessonExplanation
                    showNotify={true}
                    isUserDemo={isUserDemo}
                    isUserStandard={isUserStandard}
                    userType={userType}
                    activeTask={activeTask}
                  />
                )}
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
}

export default SaveTaskPage;
