import * as Tasks from 'Modules/TaskModule';
import { Task, Lesson } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Task.DELETE_TASK_INIT,
});

export const success = (data) => ({
  type: Task.DELETE_TASK_SUCCESS,
  payload: {
    data,
  },
});

export const successLessonReductionNumber = (data) => ({
  type: Lesson.REDUCTION_TASK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Task.DELETE_TASK_FAILURE,
});

const deleteTask = (uuid, index) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa usuwanie zadania...');
  try {
    const response = await Tasks.Connector.deleteTask(uuid);
    dispatch(success(index));
    dispatch(successLessonReductionNumber(response.data.task));

    toast.update(id, {
      render: 'Pomyślnie usunięto zadanie',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się usunąć zadania',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default deleteTask;
