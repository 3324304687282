import { connect } from 'react-redux';
import  AdminDepartmentPage from'./Main';
import { bindActionCreators } from 'redux';
import { getLesson as getLessonsSelector} from 'Store/Selectors/LessonSelector';
import { getQuestionsByDepartment as getQuestionsByDepartmentSelector} from 'Store/Selectors/QuestionSelector';
import { addLesson, getLessons, deleteLesson, editLesson } from 'Store/Actions/Lesson';
import { addTask, deleteTask, getTasks, changeTaskOrder } from 'Store/Actions/Task';
import { getQuestionsByDepartment } from 'Store/Actions/Question';
import { getTasksByLesson } from 'Store/Selectors/TaskSelector';

type ConnectedP = {
  tasksByLesson: any;
  lessons: any;
  questionsByDepartment: any;
};

type DispatchedP = {
  actions: {
    addLesson: (data) => (dispatch: any) => any;
    getLessons: (uuid) => (dispatch: any) => any;
    deleteLesson: (uuid) => (dispatch: any) => any;
    editLesson: (uuid, data) => (dispatch: any) => any;
    addTask: (data) => (dispatch: any) => any;
    deleteTask: (uuid, index) => (dispatch: any) => any;
    getQuestionsByDepartment: (uuid) => (dispatch: any) => any;
    getTasks: (uuid) => (dispatch: any) => any;
    changeTaskOrder: (uuid, data) => (dispatch: any) => any;
  };
};
const mapStateToProps = (state) => ({
  tasksByLesson: getTasksByLesson(state),
  lessons: getLessonsSelector(state),
  questionsByDepartment: getQuestionsByDepartmentSelector(state)
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addLesson: addLesson,
      getLessons: getLessons,
      deleteLesson: deleteLesson,
      editLesson: editLesson,
      addTask: addTask,
      deleteTask: deleteTask,
      getQuestionsByDepartment: getQuestionsByDepartment,
      getTasks: getTasks,
      changeTaskOrder: changeTaskOrder,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(AdminDepartmentPage);
