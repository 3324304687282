import { connect } from 'react-redux';
import Page404 from './Main';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { getAuthorizedUser, logout, changeCurrentUserPassword } from 'Store/Actions/Auth';

type ConnectedP = {
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    getAuthorizedUser: () => (dispatch: any) => Promise<any>;
    logout: () => (dispatch: any, getState) => Promise<any>;
    changeCurrentUserPassword: ({ currentPassword, newPassword }) => (dispatch: any) => Promise<boolean>;

  };
};
const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: getAuthorizedUser,
      changeCurrentUserPassword: changeCurrentUserPassword,
      logout: logout,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
