import * as UserModule from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = (uuid) => ({
  type: User.USER_RESTORATION_SUCCESS,
  payload: {
    uuid,
  },
});

const failure = () => ({
  type: User.USER_RESTORATION_FAILURE,
});

const userRestoration = (uuid) => async (dispatch) => {
  const id = toast.loading('Trwa przywracanie użytkownika...');
  try {
    const response = await UserModule.Connector.userRestoration(uuid);
    if (response) {
      dispatch(success(uuid));
    }

    toast.update(id, {
      render: 'Pomyślnie przywrócono użytkownika',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się przywrócić użytkownika',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default userRestoration;
