import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function getAllUserCity(page): AxiosPromise<any> {
  return API.get(`cities/users?page=${page}`);
}

export function getPopularCities(): AxiosPromise<any> {
  return API.get(`cities/popular`);
}
