import Button from 'Components/Button';
import { MainType } from './Container';
import { Field, Formik } from 'formik';
import { Input } from 'Components/form';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';
import { useState } from 'react';
import SVG from 'Components/SVG';

function ResetPasswordPage(props: MainType) {
  const { actions } = props;
  const { sendPasswordResetLink } = actions;
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
  });

  const onSubmit = async (values) => {
    const result = await sendPasswordResetLink({ email: values.email });
    if (result) {
      navigate({ pathname: ROUTES.SUCCESSFUL_PASSWORD_RESET_LINK_SEND, search: `?email=${values.email}` });
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="login-page ">
      <Formik
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={{
          email: '',
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {({ handleSubmit, isValid, errors, setFieldTouched, isSubmitting, values }) => (
          <main className="login-page__body">
            <div className="login-page__content-wrapper">
              <h2 className="login-page__title">Nie pamiętasz hasła?</h2>
              <p className="login-page__description">Wprowadź swój adres e-mail, aby otrzymać instrukcje dotyczące resetowania hasła.</p>
              <p className={`login-page__error ${showError ? 'login-page__error--show' : ''}`}>
                <span>Błąd resetu hasła</span>
                <SVG type={SVG_TYPE.WARNING} />
              </p>

              <form onSubmit={handleSubmit} className="login-page__form login-page__form--admin">
                <Field
                  component={Input}
                  required
                  name="email"
                  placeholder="Wpisz Email"
                  label="Email"
                  onChange={() => setShowError(false)}
                  errors={errors}
                  className={'login-page__input'}
                />
              </form>
            </div>
            <div className="login-page__buttons">
              <Button
                label={'Wyślij'}
                onClick={() => {
                  setFieldTouched('email', true);
                  setFieldTouched('password', true);
                  isValid && !isSubmitting && onSubmit(values);
                }}
              />
            </div>
          </main>
        )}
      </Formik>
    </div>
  );
}

export default ResetPasswordPage;
