import * as DrivingTestModule from 'Modules/DrivingTestModule';
import { DrivingTest } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: DrivingTest.GET_DRIVING_TEST_INIT,
});

export const success = (data) => ({
  type: DrivingTest.GET_DRIVING_TEST_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DrivingTest.GET_DRIVING_TEST_FAILURE,
});

const getDrivingTest = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await DrivingTestModule.Connector.getDrivingTest();
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getDrivingTest;
