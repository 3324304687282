import { useState, useEffect, useRef } from 'react';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

type LessonItemType = {
  item: any;
  index: number;
  handleDeleteLesson: any;
  showDetails: any;
  showLessonDetails: any;
  setIsLessonEditOpen: any;
};

const LessonItem = ({ item, index, handleDeleteLesson, showDetails, showLessonDetails, setIsLessonEditOpen }: LessonItemType) => {
  const { name, tasksCount } = item;
  const isMobile = window.innerWidth < 1024;
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const menuRef = useRef(null);

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpenMenu(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="lesson-item__wrapper">
      <div className="lesson-item__counter">{index + 1}.</div>
      <div className="lesson-item__content">
        <div className="lesson-item--left">
          {isMobile && (
            <div className="lesson-item__ellipsis" ref={menuRef} onClick={() => handleOpenMenu()}>
              <SVG type={SVG_TYPE.ELLIPSIS} />
              <div className={`lesson-item__menu ${isOpenMenu ? 'lesson-item__menu--active' : ''}`}>
                <div className="lesson-item__menu--item" onClick={() => setIsLessonEditOpen(item)}>
                  Edytuj
                </div>
                <div className="lesson-item__menu--item" onClick={() => handleDeleteLesson(item)}>
                  Usuń
                </div>
              </div>
            </div>
          )}
          <p className="lesson-item__name">{name}</p>
        </div>
        <div className="lesson-item--right">
          {!isMobile && (
            <div className="lesson-item__actions">
              <div className="lesson-item__actions--single" onClick={() => setIsLessonEditOpen(item)}>
                <SVG className="edit" type={SVG_TYPE.EDIT} />
              </div>
              <div className="lesson-item__actions--single" onClick={() => handleDeleteLesson(item)}>
                <SVG type={SVG_TYPE.BIN} />
              </div>
            </div>
          )}
          <div className="lesson-item__counter">
            <div className="lesson-item__counter-number">{tasksCount ? tasksCount : 0}</div>
            <div
              className={`lesson-item__counter-chevron ${item.uuid === showLessonDetails ? 'lesson-item__counter-chevron--active' : ''}`}
              onClick={() => showDetails(item)}>
              <SVG type={SVG_TYPE.BACK_CHEVRON} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LessonItem;
