import { Field, Formik } from 'formik';
import { DatePicker } from 'Components/form';
import { Button } from 'Components';
import { useState } from 'react';

type CurrentDateFormType = {
  updateDatabaseDate: any;
  data: any;
};

const CurrentDateForm = ({ updateDatabaseDate, data }: CurrentDateFormType) => {
  const [isClose, setIsClose] = useState(false);

  const handleSubmit = (values) => {
    updateDatabaseDate(values);
    setIsClose(true);
    const container = document.getElementsByClassName('react-datepicker__day--selected')[0] as HTMLButtonElement;
    setTimeout(() => {
      container.click();
      setIsClose(false);
    }, 100);
  };
  const handleClose = (setFieldValue) => {
    setIsClose(true);
    const container = document.getElementsByClassName('react-datepicker__day--selected')[0] as HTMLButtonElement;
    setTimeout(() => {
      container.click();
      setIsClose(false);
      setFieldValue('date', new Date(data?.lastUpdate));
    }, 100);
  };

  return (
    <Formik
      isInitialValid={false}
      enableReinitialize
      initialValues={{ date: new Date(data?.lastUpdate) }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ handleSubmit, errors, setFieldValue }) => {
        return (
          <form className="current-date-form" onSubmit={handleSubmit}>
            <Field
              className="question-date"
              label="Data aktualnej bazy pytań"
              name="date"
              placeholderText="Wybierz datę"
              required
              component={DatePicker}
              errors={errors}
              shouldCloseOnSelect={isClose}
              showPreviousMonths={true}
              child={
                <div className="question-date__buttons">
                  <Button label={'Anuluj'} stroke onClick={() => handleClose(setFieldValue)} />
                  <Button label={'Potwierdź'} onClick={() => handleSubmit()} />
                </div>
              }
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default CurrentDateForm;
