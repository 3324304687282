import { connect } from 'react-redux';
import TokenExpired from './Main';
import { bindActionCreators } from 'redux';
import { login } from 'Store/Actions/Auth';
import getSmsCode from 'Store/Actions/Auth/getSmsCode';

type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    login: (smsCode: string) => (dispatch: any) => Promise<boolean>;
    getSmsCode: ({ phoneNumber, phonePrefixUuid }) => any;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      login: login,
      getSmsCode: getSmsCode,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TokenExpired);
