/* eslint-disable react/prop-types */
import Button from 'Components/Button';
import Modal from 'Components/Modal';

type DeleteDepartmentType = {
  data: any;
  isOpen: boolean;
  onClose: () => void;
  getDepartments: () => void;
  deleteDepartment: (uuid) => any;
};

function DeleteDepartment({ isOpen, onClose, data, deleteDepartment, getDepartments }: DeleteDepartmentType): JSX.Element {
  const handleDeleteDepartment = async (uuid) => {
    await deleteDepartment(uuid);
    onClose();
    await getDepartments();
  };

  return (
    <>
      <Modal short isOpen={isOpen} onClose={onClose}>
        {data && (
          <>
            <h2 className="edit-user-modal__header">Usuwanie działu</h2>
            <p className="delete-department__descript">Czy na pewno chcesz usunąć dział:</p>
            <p className="delete-department__descript delete-department__descript--bold">Nazwa działu: {data.name}</p>
            <p className="delete-department__descript delete-department__descript--bold">Liczba lekcji: {data.lessonsCount}</p>
            <div className="delete-department__actions">
              <Button label="Anuluj" onClick={() => onClose()} stroke />
              <Button label="Usuń" onClick={() => handleDeleteDepartment(data.uuid)} />
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export default DeleteDepartment;
