import React, { useState, useRef, useEffect } from 'react';
import { SVG, Button } from 'Components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MainType } from './Container';
import { CityModal } from './components';

const MyProfilePacket = (props: MainType) => {
  const { actions, paymentsLabel, cities, authorizedUser } = props;
  const { getPaymentsLabel, getPayments, getCities } = actions;
  const [isOpenCityModal, setIsOpenCityModal] = useState(false);
  const [activePacket, setActivePacket] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(1);
  const isUserLogged = !!authorizedUser && authorizedUser !== 'init';
  const userCity = isUserLogged && authorizedUser?.city;
  const isFromFailedPayments = sessionStorage.getItem('isFromFailedPayments') === 'true';

  const isMobile = window.innerWidth < 1024;
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const handleSwipe = (e, direction) => {
    if (direction === 'LEFT') {
      setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
    } else if (direction === 'RIGHT') {
      setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
    }
  };

  const carouselOptions = {
    showThumbs: false,
    showStatus: false,
    showArrows: false,
    centerMode: true,
    centerSlidePercentage: 50,
    swipeable: true,
    showIndicators: false,
    swipeScrollTolerance: 1,
    infiniteLoop: false,
    emulateTouch: true,
    selectedItem,
    onSwipe: handleSwipe,
    autoFocus: true,
  };

  useEffect(() => {
    getPaymentsLabel();
  }, []);

  useEffect(() => {
    getCities();
  }, []);

  const Packet = ({ data: { days, oldPrice, price, value } }: any) => {
    const isActive = activePacket === value;
    return (
      <div className={`myProfilePacket__item ${isActive ? 'myProfilePacket__item--active' : ''}`}>
        <div className="time">
          {days === 'unlimited' ? <SVG type={SVG_TYPE.STAR} /> : ''}
          {days === 'unlimited' ? 'Bez limitu' : `${days} dni`}
        </div>
        {oldPrice != 0 && (
          <div className="oldPrice">
            <span>{oldPrice} zł</span>
          </div>
        )}
        <div className="price">{price} zł</div>
        {isActive ? (
          <div className="active">
            <SVG type={SVG_TYPE.CIRCLE_CHECKED} />
          </div>
        ) : (
          <Button
            label="Wybierz"
            className="btn--small"
            stroke
            onClick={() => {
              setActivePacket(value);
            }}
          />
        )}
      </div>
    );
  };

  const getBack = () => {
    if (isFromFailedPayments) {
      sessionStorage.removeItem('isFromFailedPayments');
      navigate({ pathname: ROUTES.MYPROFILE });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CityModal
        getPayments={getPayments}
        paymentTypesUuid={activePacket}
        isOpen={isOpenCityModal}
        onClose={() => setIsOpenCityModal(false)}
        cities={cities}
        cityUser={userCity}
      />
      <div className="myProfilePacket">
        <div className="myProfilePacket__top">
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
          {isMobile && (
            <span
              className="myProfilePacket__top--back"
              onClick={() => {
                getBack();
              }}>
              <SVG type={SVG_TYPE.CHEVRONE} />
            </span>
          )}
          {!isMobile && (
            <span
              className="myProfilePacket__top--logo--back"
              onClick={() => {
                getBack();
              }}>
              <SVG type={SVG_TYPE.CHEVRONE} />
            </span>
          )}
          <div className="myProfilePacket__top--logo">
            <SVG type={SVG_TYPE.LOGO_GROUP} />
          </div>
        </div>
        <div className="myProfilePacket__content">
          <div className="header">Kup pakiet Premium</div>
          <ul className="list">
            <li className="list__item">
              <SVG type={SVG_TYPE.CHECKED} />
              Nielimitowane egzaminy wraz z wyjaśnieniem błędów
            </li>
            <li className="list__item">
              <SVG type={SVG_TYPE.CHECKED} />
              Szczegółowe statystyki nauki i egzaminów + procent szans na zdanie egzaminu państwowego
            </li>
            <li className="list__item">
              <SVG type={SVG_TYPE.CHECKED} />
              Nielimitowana ilość zadań w module nauki
            </li>
            <li className="list__item">
              <SVG type={SVG_TYPE.CHECKED} />
              Informacje o tym, z jakimi działami słabiej sobie radzisz{' '}
            </li>
          </ul>
          <div className="myProfilePacket__items">
            {paymentsLabel && paymentsLabel.length > 0 && (
              <>
                {isMobile ? (
                  <Carousel ref={carouselRef} {...carouselOptions}>
                    {paymentsLabel.map((item) => {
                      return <Packet key={item.value} data={item} />;
                    })}
                  </Carousel>
                ) : (
                  <div className="myProfilePacket__items-desktop">
                    {paymentsLabel.map((item) => {
                      return <Packet key={item.value} data={item} />;
                    })}
                  </div>
                )}
              </>
            )}
          </div>
          <div className={`myProfilePacket__buttons ${activePacket ? 'myProfilePacket__buttons--active' : ''}`}>
            <Button label="Kup pakiet" className="btn--small buy" onClick={() => setIsOpenCityModal(true)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfilePacket;
