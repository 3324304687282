import { useEffect, useState } from 'react';
import { Button } from 'Components';
import { MainType } from './Container';
import { Header, EmptyDepartment, AddLesson, LessonList, RemoveLesson, EditLesson, AddTask, RemoveTask } from './components';

function AdminDepartmentPage(props: MainType) {
  const { actions, lessons, questionsByDepartment, tasksByLesson } = props;
  const { addLesson, getLessons, deleteLesson, editLesson, deleteTask, getQuestionsByDepartment, getTasks, changeTaskOrder } = actions;
  const [isLessonAddOpen, setIsLessonAddOpen] = useState(false);
  const [isTaskAddOpen, setIsTaskAddOpen] = useState(null);
  const [isLessonEditOpen, setIsLessonEditOpen] = useState(false);
  const [removeLessonItem, setRemoveLessonItem] = useState(null);
  const [removeTaskItem, setRemoveTaskItem] = useState(false);
  const pathname = window.location.pathname;
  const departmentUuid = pathname.split(':')[1];
  const moreThanZeroLesson = lessons?.lessons.length > 0;

  useEffect(() => {
    getLessons(departmentUuid);
  }, []);

  useEffect(() => {
    isTaskAddOpen && lessons?.department?.uuid && getQuestionsByDepartment(lessons.department.uuid);
  }, [lessons, isTaskAddOpen]);

  return (
    <>
      <>
        <Header department={lessons?.department || {}} />
        <div className="admin-department-page__container">
          <div className="admin-department-page__content">
            <div className="admin-department-page__content__top">
              <h2 className="admin-department-page__content-header">Lekcje</h2>
              {moreThanZeroLesson && <Button label="Dodaj lekcję" onClick={() => setIsLessonAddOpen(true)} />}
            </div>
            {moreThanZeroLesson ? (
              <LessonList
                changeTaskOrder={changeTaskOrder}
                tasksByLesson={tasksByLesson}
                getTasks={getTasks}
                editLesson={editLesson}
                setIsLessonEditOpen={setIsLessonEditOpen}
                handleDeleteLesson={setRemoveLessonItem}
                lessons={lessons?.lessons}
                setIsTaskAddOpen={setIsTaskAddOpen}
                setRemoveTaskItem={setRemoveTaskItem}
              />
            ) : (
              <EmptyDepartment setIsLessonAddOpen={setIsLessonAddOpen} />
            )}
          </div>
        </div>
        <AddTask
          questionsByDepartment={questionsByDepartment}
          lessonUuid={isTaskAddOpen ? isTaskAddOpen.uuid : ''}
          addTask={actions.addTask}
          isOpen={isTaskAddOpen}
          onClose={() => setIsTaskAddOpen(false)}
        />
        <AddLesson
          departmentUuid={lessons?.department.uuid}
          addLesson={addLesson}
          isOpen={isLessonAddOpen}
          onClose={() => setIsLessonAddOpen(false)}
        />
        <EditLesson data={isLessonEditOpen} editLesson={editLesson} isOpen={isLessonEditOpen} onClose={() => setIsLessonEditOpen(false)} />
        <RemoveLesson deleteLesson={deleteLesson} lesson={removeLessonItem} isOpen={removeLessonItem} onClose={() => setRemoveLessonItem(false)} />
        <RemoveTask task={removeTaskItem} deleteTask={deleteTask} isOpen={removeTaskItem} onClose={() => setRemoveTaskItem(false)} />
      </>
    </>
  );
}

export default AdminDepartmentPage;
