import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { createRef } from 'react';
import { Outlet } from 'react-router';

function AdminLoginScreens() {
  const node = createRef<any>();

  return (
    <div className="admin-login-screens" ref={node}>
      <main className="admin-login-screens__left">
        <Outlet />
      </main>
      <aside className="admin-login-screens__right">
        <SVG type={SVG_TYPE.BIG_LOGO} className="admin-login-screens__logo" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="admin-login-screens__animated-element admin-login-screens__animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="admin-login-screens__animated-element admin-login-screens__animated-element--second" />
      </aside>
    </div>
  );
}

export default AdminLoginScreens;
