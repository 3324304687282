import * as UserModule from 'Modules/UserModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.GET_USERS_INIT,
});

export const success = (users) => ({
  type: User.GET_USERS_SUCCESS,
  payload: {
    users,
  },
});

const failure = () => ({
  type: User.GET_USERS_FAILURE,
});

const getUsers = (role, page) => async (dispatch) => {
  dispatch(init());
  try {
    // dispatch(ViewManagementModule.Actions.showLoader());
    const response = await UserModule.Connector.getUsers(role, page);
    dispatch(success(response.data.usersSerialize));
    // dispatch(ViewManagementModule.Actions.hideLoader());
    return true;
  } catch (err) {
    // dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    return false;
  }
};

export default getUsers;
