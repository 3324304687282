import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function addTask(data): AxiosPromise<any> {
  return API.post(`tasks`, data);
}

export function getTasks(uuid): AxiosPromise<any> {
  return API.get(`lessons/${uuid}`);
}

export function changeTaskOrder(uuid, data): AxiosPromise<any> {
  return API.put(`tasks/${uuid}`, data);
}

export function deleteTask(uuid): AxiosPromise<any> {
  return API.delete(`tasks/${uuid}`);
}

export function getSaveTasks(page): AxiosPromise<any> {
  return API.get(`save/tasks${page ? `?page=${page}` : ''}`);
}

export function getSingleSaveTask(uuid): AxiosPromise<any> {
  return API.get(`save/tasks/${uuid}`);
}

export function answerSaveTask(uuid, answer): AxiosPromise<any> {
  return API.get(`save/tasks/answer/${uuid}?answer=${answer}`);
}
