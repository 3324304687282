import { useEffect } from 'react';
import Button from 'Components/Button';
import { MainType } from './Container';
import { Field, Formik } from 'formik';
import { Input } from 'Components/form';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES, USER_ROLES } from 'Shared/enums';
import * as Yup from 'yup';
import { useState } from 'react';
import SVG from 'Components/SVG';
import { generatePath } from 'react-router-dom';

function LoginAdminPage(props: MainType) {
  const { actions, authorizedUser } = props;
  const { adminLogin, getAuthorizedUser } = actions;
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
    password: Yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH).required(VALIDATION_MESSAGES.REQUIRED),
  });

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  useEffect(() => {
    if (!!authorizedUser && authorizedUser !== 'init') {
      if (authorizedUser.role === USER_ROLES.ADMIN) {
        navigate({ pathname: ROUTES.STATISTICS });
      } else {
        if (authorizedUser.category) {
          navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
        } else {
          navigate(ROUTES.SELECT_CATEGORY);
        }
      }
    }
  }, [authorizedUser]);

  const onSubmit = async (values) => {
    const result = await adminLogin({ email: values.email, password: values.password });
    if (result) {
      navigate({ pathname: ROUTES.STATISTICS });
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="login-page ">
      <Formik
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {({ handleSubmit, errors, setFieldTouched }) => (
          // {({ handleSubmit, isValid, errors, setFieldTouched, isSubmitting, values }) => (
          <main className="login-page__body">
            <div className="login-page__content-wrapper">
              <h2 className="login-page__title">Zaloguj się</h2>
              <p className="login-page__description">Logowanie do panelu admina</p>
              <p className={`login-page__error ${showError ? 'login-page__error--show' : ''}`}>
                <span>Błąd logowania</span>
                <SVG type={SVG_TYPE.WARNING} />
              </p>

              <form onSubmit={handleSubmit} className="login-page__form login-page__form--admin">
                <Field
                  component={Input}
                  required
                  name="email"
                  placeholder="Wpisz Email"
                  label="Email"
                  onChange={() => setShowError(false)}
                  errors={errors}
                  className={'login-page__input'}
                />
                <div className="login-page__reset-password-wrapper">
                  <p onClick={() => navigate(ROUTES.RESET_PASSWORD_PAGE)} className="login-page__reset-password">
                    Nie pamiętasz hasła?
                  </p>
                  <Field
                    component={Input}
                    required
                    name="password"
                    onChange={() => setShowError(false)}
                    placeholder="Wpisz hasło"
                    label="Hasło"
                    type="password"
                    errors={errors}
                    className={'login-page__input'}
                  />
                </div>
                <div className="login-page__buttons">
                  <Button
                    type="submit"
                    label={'Zaloguj się'}
                    onClick={() => {
                      setFieldTouched('email', true);
                      setFieldTouched('password', true);
                      // isValid && !isSubmitting && onSubmit(values);
                    }}
                  />
                </div>
              </form>
            </div>
          </main>
        )}
      </Formik>
    </div>
  );
}

export default LoginAdminPage;
