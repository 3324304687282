
const nineDigitUuids = ['b5e4fc5b-57f5-4ae9-8eb4-bdaaf8fdeb41','f36b9faa-c5f2-4402-ac9d-f31f8c78f6aa','337bdd5e-5b3c-4011-88d8-d94b3ee18058', '95d96dba-e0bb-4c25-ba52-ba8c975c5627','4113f86d-ff9f-41f0-b545-9b9d5a4a0e0d']

const phoneNumberValidation = (phonePrefixUuid, schema) => {
  // Polska
  if (nineDigitUuids.includes(phonePrefixUuid)) {
    return schema.matches(/^\d{9}$/, 'wymagane 9 cyfr');
  }
  // Norwegia
  if (phonePrefixUuid === '59b5385f-f250-4d10-b251-cf56a22965f6') {
    return schema.matches(/^\d{8}$/, 'wymagane 8 cyfr');
  }
  // Niemcy
  if (phonePrefixUuid === 'd1bba838-a968-4d46-b4f8-0385ca2f3fe9') {
    return schema.matches(/^\d{11}$/, 'wymagane 11 cyfr');
  }
  // Szwecja
  return schema;
}
export default phoneNumberValidation