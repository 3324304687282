import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export function getQuestions(data): AxiosPromise<any> {
  const queryString = qs.stringify(data);
  return API.get(`questions?${queryString}`);
}

export function getSingleQuestion(uuid): AxiosPromise<any> {
  return API.get(`questions/${uuid}`);
}

export function editQuestion(uuid,data): AxiosPromise<any> {
  return API.put(`questions/${uuid}`, data);
}

export function questionFile(uuid,data): AxiosPromise<any> {
  return API.put(`questions/file/${uuid}`, data);
}

export function getQuestionsByDepartment(uuid): AxiosPromise<any> {
  return API.get(`questions/label/list?departmentUuid=${uuid}`);
}

export function updateDatabaseDate(data): AxiosPromise<any> {
  return API.put(`questions/database/update`, data);
}
