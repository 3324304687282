import { connect } from 'react-redux';
import QuestionPage from './Main';
import { bindActionCreators } from 'redux';
import { getQuestion, editQuestion } from 'Store/Actions/Question';
import { getQuestion as getQuestionSelector } from 'Store/Selectors/QuestionSelector';

type ConnectedP = {
  question: any;
};

type DispatchedP = {
  actions: {
    getQuestion: (uuid) => void;
    editQuestion: (uuid,data) => void;
  };
};
const mapStateToProps = (state) => ({
  question: getQuestionSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      editQuestion: editQuestion,
      getQuestion: getQuestion,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
