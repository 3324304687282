import Button from 'Components/Button';
import { useNavigate } from 'react-router';
import { ROUTES } from 'Shared/enums';

function TokenExpired() {
  const navigate = useNavigate();

  return (
    <div className="login-page page login-page--token-expired ">
      <main className="login-page__body login-page__body--without-flex">
        <div className="login-page__content-wrapper">
          <div className="login-page__top-wrapper">
            <h2 className="login-page__title">Ten link wygasł</h2>
          </div>
          <p className="login-page__description">Aby zresetować hasło wyślij prośbę ponownie.</p>
        </div>
        <div className="login-page__buttons">
          <Button
            label={'Wyślij nowy link'}
            onClick={() => {
              navigate({ pathname: ROUTES.RESET_PASSWORD_PAGE });
            }}
          />
        </div>
      </main>
    </div>
  );
}

export default TokenExpired;
