import { useState } from 'react';
import { SVG, Button } from 'Components';
import { SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { EditQuestionModal } from '../QuestionsDatabasePage/components';

function QuestionPage(props: MainType) {
  const {
    actions: { getQuestion, editQuestion },
    question,
  } = props;
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const isMobile = window.innerWidth < 1024;
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const id = parts[parts.length - 1].substring(1);

  useEffect(() => {
    getQuestion(id);
  }, []);

  const link = question?.filePath;
  const segments = link?.split('/');
  const noMedia = segments && segments[segments.length - 1] === 'null';
  const mediaName = segments && segments[segments.length - 1];
  const mediaVideo = mediaName?.substring(mediaName.lastIndexOf('.') + 1) === 'mp4';
  const [edittedQuestion, setEdittedQuestion] = useState(null);

  const media = () => {
    if (noMedia) {
      return null;
    } else if (mediaVideo) {
      return (
        <div className="question__item question__item--media">
          <div className="question__label">Media</div>
          <ReactPlayer url={question?.filePath} controls={true} width="100%" height="100%" />
        </div>
      );
    } else {
      return (
        <div className="question__item question__item--media">
          <div className="question__label">Media</div>
          <img src={link} alt="" />
        </div>
      );
    }
  };

  return (
    <>
      <EditQuestionModal
        editQuestion={editQuestion}
        onClose={() => setEdittedQuestion(null)}
        isOpen={edittedQuestion !== null}
        question={edittedQuestion}
      />
      <header className="dashboard__header">
        <div className="dashboard__container">
          <h1 className="dashboard__title dashboard__title--database">
            Podgląd pytania <span>{question?.name}</span>
            {question?.isDifficult && <SVG type={SVG_TYPE.BULB} />}
          </h1>
          <div onClick={goBack} className="dashboard__goBack">
            <SVG type={SVG_TYPE.CHEVRONE} />
          </div>
        </div>
      </header>
      <div className="questions-list questions-list--single">
        <div className="dashboard__container ">
          <div className="question">
            <div className="question__left">
              <div className="question__item">
                <div className="question__label">Nazwa</div>
                <div className="question__paragraph">{question?.name}</div>
              </div>
              <div className="question__item">
                <div className="question__label">Typ</div>
                <div className="question__paragraph">{question?.type}</div>
              </div>
              <div className="question__item">
                <div className="question__label">Kategoria</div>
                <div className="question__paragraph">{question?.categories}</div>
              </div>
              <div className="question__item">
                <div className="question__label">Treść pytania</div>
                <div className="question__paragraph question__paragraph--max500">{question?.question}</div>
              </div>
              {question?.type === 'SPECJALISTYCZNY' && (
                <>
                  <div className="question__item">
                    <div className="question__label">Odpowiedź A</div>
                    <div className="question__paragraph question__paragraph--max500">{question?.answerA}</div>
                  </div>
                  <div className="question__item">
                    <div className="question__label">Odpowiedź B</div>
                    <div className="question__paragraph question__paragraph--max500">{question?.answerB}</div>
                  </div>
                  <div className="question__item">
                    <div className="question__label">Odpowiedź C</div>
                    <div className="question__paragraph question__paragraph--max500">{question?.answerC}</div>
                  </div>
                </>
              )}
              <div className="question__item">
                <div className="question__label">Prawidłowa odpowiedź</div>
                <div className="question__paragraph">{question?.propertyAnswer}</div>
              </div>
            </div>
            <div className="question__right">
              <div className="question__item">
                <div className="question__label">Źródło pytania</div>
                <div className="question__paragraph  question__paragraph--max500">{question?.source}</div>
              </div>
              {media()}
              <div className="question__item question__item--explanation">
                <div className="question__label">
                  Wyjaśnienie
                  {!isMobile && (
                    <span className="question__label--icon" onClick={() => setEdittedQuestion(question)}>
                      <SVG type={SVG_TYPE.EDIT} />
                    </span>
                  )}
                </div>
                <div className="question__paragraph">{question?.explanation}</div>
                {question?.youtubeLink && (
                  <div className="question__paragraph question__paragraph--link">
                    <a className="question__link" target="_blank" href={question?.youtubeLink} rel="noreferrer">
                      link
                    </a>
                  </div>
                )}
                {question?.file && (
                  <div className="question__paragraph">
                    <img className="question__paragraph--file" src={question?.file?.url} />
                  </div>
                )}
                {isMobile && (
                  // <span className="question--icon" onClick={() => setEdittedQuestion(question)}>
                  //   <SVG type={SVG_TYPE.EDIT} />
                  // </span>
                  <Button stroke svg_type={SVG_TYPE.EDIT} iconOnLeft label="Edytuj" onClick={() => setEdittedQuestion(question)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionPage;
