import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function getDrivingTest(): AxiosPromise<any> {
  return API.get('driving-test');
}

export function getDrivingTestAnswer(params): AxiosPromise<any> {
  return API.get(`driving-test/answer?${params}`);
}

export function finishDrivingTest(uuid): AxiosPromise<any> {
  return API.get(`driving-test/finish?drivingTestUuid=${uuid}`);
}

export function getSingleSummary(uuid): AxiosPromise<any> {
  return API.get(`users/driving-tests/${uuid}`);
}
