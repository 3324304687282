import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

export const success = (phonePrefixes) => ({
  type: Auth.RESEND_ACTIVATE_LINK_SUCCESS,
  payload: {
    phonePrefixes,
  },
});

const failure = () => ({
  type: Auth.RESEND_ACTIVATE_LINK_FAILURE,
});

const resendActivateLink = (email) => async (dispatch) => {
  const id = toast.loading('Trwa wysyłanie linku...');
  try {
    await AuthModule.Connector.resendActivateLink(email);
    toast.update(id, {
      render: 'Wysłano link aktywacyjny',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });

    return true;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się wysłać linku aktywacyjnego',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });
    return false;
  }
};

export default resendActivateLink;
