import { useEffect, useState } from 'react';
import { Button } from 'Components';
import {
  Header,
  AnswerNotification,
  Question,
  LessonExplanation,
  SpecializedQuestionButtons,
  BasicQuestionButtons,
  Dailylimit,
  LessonResult,
} from './components';
import { MainType } from './Container';
import { USER_TYPE } from 'Shared/enums';

function LessonPage(props: MainType) {
  const { actions, startLessons, authorizedUser } = props;
  const { saveTask, answerTask, endLesson } = actions;
  const isMobile = window.innerWidth < 1024;
  const pathname = window.location.pathname;
  const lessonUuid = pathname.split(':')[1];
  const browserStorage = JSON.parse(localStorage.getItem('lessonPage'));
  const [answer, setAnswer] = useState(undefined);
  const [summaryLesson, setSummaryLesson] = useState(undefined);
  const [isPropertyAnswer, setIsPropertyAnswer] = useState(null);
  const [tasks, setTasks] = useState(undefined);
  const [taskNumber, setTaskNumber] = useState(0);
  const [activeTask, setActiveTask] = useState(undefined);
  const lessonCount = startLessons?.tasks?.length;
  const lastTask = lessonCount === taskNumber + 1;
  const buttonLabel = isPropertyAnswer ? `${lastTask ? 'Zakończ lekcję' : 'Następne pytanie'}` : 'Sprawdź';
  const isButtonShow = answer ? 'lesson-page__button--show' : 'lesson-page__button--hide';
  const isSpecialized = false;
  const isBasicQuestion = activeTask && activeTask.question.type === 'PODSTAWOWY';
  const isUserLogged = !!authorizedUser && authorizedUser !== 'init';
  const userType = isPropertyAnswer && isUserLogged && authorizedUser.type;
  const isUserStandard = isUserLogged && authorizedUser.type === USER_TYPE.STANDARD;
  const isUserDemo = isUserLogged && authorizedUser.type === USER_TYPE.DEMO;
  const [showNextQuestion, setShowNextQuestion] = useState(false);
  const isAvailableTasks = isUserLogged && authorizedUser?.availableTasks;
  const [isActiveTask, setIsActiveTask] = useState(isAvailableTasks);
  const [isTaskAvailable, setIsTaskAvailable] = useState(true);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const showNotify = (isPropertyAnswer && isMobile) || !isMobile;
  const image = activeTask?.question?.file?.url;
  const explanation = activeTask?.question?.explanation;

  useEffect(() => {
    !isAvailableTasks && setIsTaskAvailable(false);
  }, []);

  useEffect(() => {
    if (browserStorage?.uuid === lessonUuid) {
      setTaskNumber(browserStorage?.taskNumber);
    } else {
      localStorage.setItem('lessonPage', JSON.stringify({ uuid: lessonUuid, taskNumber: 0 }));
    }
  }, []);

  const handleChoice = (choice) => {
    setAnswer(choice);
    setIsPropertyAnswer(undefined);
  };

  const handleCheckAnswer = async () => {
    // Dodaj zmienną flagową do śledzenia, czy przycisk został już kliknięty
    if (buttonLabel === 'Sprawdź') {
      setIsButtonClicked(true); // Ustaw flagę na true, aby zablokować kolejne kliknięcia

      !lastTask && localStorage.setItem('lessonPage', JSON.stringify({ uuid: lessonUuid, taskNumber: taskNumber + 1 }));
      if (!isButtonClicked) {
        const response = await answerTask({ lessonUuid, taskUuid: activeTask.uuid, answer });
        setActiveTask(response);
        if (!response.availableTasks) {
          setIsActiveTask(false);
        }
        if (isMobile) {
          const activeBox = isBasicQuestion
            ? document.getElementsByClassName('basic-question-button--choice')[0]
            : document.getElementsByClassName('box--active')[0];
          activeBox.scrollIntoView({ behavior: 'smooth', block: 'center' });
          response.propertyAnswer === answer ? setIsPropertyAnswer('T') : setIsPropertyAnswer('N');
          setIsPropertyAnswer(response.question.propertyAnswer);
        } else {
          response.propertyAnswer === answer ? setIsPropertyAnswer('T') : setIsPropertyAnswer('N');
          setIsPropertyAnswer(response.question.propertyAnswer);
        }
      }
    } else {
      setIsButtonClicked(false);
      setTimeout(() => {
        const activeBox = document.getElementsByClassName('lesson-page')[0];
        activeBox && activeBox.scrollIntoView();
      }, 300);

      if (isActiveTask) {
        setShowNextQuestion(true);
        setTimeout(() => {
          setShowNextQuestion(false);
        }, 300);
        setIsPropertyAnswer(null);
        setAnswer(undefined);
        if (buttonLabel !== 'Zakończ lekcję') {
          setTaskNumber(taskNumber + 1);
        } else if (buttonLabel === 'Zakończ lekcję') {
          const response = await endLesson(lessonUuid);
          localStorage.setItem('lessonPage', JSON.stringify({ uuid: null, taskNumber: 0 }));
          setSummaryLesson(response);
        }
      } else {
        setIsTaskAvailable(false);
      }
    }
  };

  useEffect(() => {
    actions.startLessons(lessonUuid);
  }, []);

  useEffect(() => {
    startLessons && setTasks(startLessons?.tasks);
  }, [startLessons]);

  useEffect(() => {
    if (tasks) {
      setActiveTask(tasks[taskNumber]);
    }
  }, [tasks, taskNumber]);

  return (
    <>
      <Dailylimit isUserStandard={isUserStandard} isUserDemo={isUserDemo} isOpen={!isTaskAvailable} />
      <div className="lesson-page">
        <Header
          summaryLesson={summaryLesson}
          setSummaryLesson={setSummaryLesson}
          endLesson={endLesson}
          activeTaskNumber={taskNumber}
          allTasks={lessonCount}
          lesson={startLessons}
        />
        <div className="page-wrapper">
          <div className={`dashboard__container lesson-page__container ${summaryLesson ? 'lesson-page__container--summary' : ''}`}>
            {activeTask && !summaryLesson && (
              <>
                <div className={`lesson-page__left ${showNextQuestion ? 'lesson-page__question--hide' : 'lesson-page__question--show'}`}>
                  <Question saveTask={saveTask} activeTask={activeTask} />
                  <div className={`lesson-page__answer-buttons ${isSpecialized ? 'lesson-page__answer-buttons--specialized' : ''}`}>
                    {isBasicQuestion ? (
                      <BasicQuestionButtons isPropertyAnswer={isPropertyAnswer} handleChoice={handleChoice} answer={answer} />
                    ) : (
                      <SpecializedQuestionButtons
                        activeTask={activeTask}
                        isPropertyAnswer={isPropertyAnswer}
                        handleChoice={handleChoice}
                        answer={answer}
                      />
                    )}
                    <AnswerNotification answer={answer} isPropertyAnswer={isPropertyAnswer} />
                    {isMobile && (image || explanation) && (
                      <LessonExplanation
                        showNotify={showNotify}
                        isUserDemo={isUserDemo}
                        isUserStandard={isUserStandard}
                        activeTask={activeTask}
                        userType={userType}
                      />
                    )}
                    <Button label={`${buttonLabel}`} className={`${isButtonShow}`} onClick={() => handleCheckAnswer()} />
                  </div>
                </div>
                <div className="lesson-page__right">
                  {!isMobile && (image || explanation) && (
                    <LessonExplanation
                      showNotify={showNotify}
                      isUserDemo={isUserDemo}
                      isUserStandard={isUserStandard}
                      userType={userType}
                      activeTask={activeTask}
                    />
                  )}
                </div>
              </>
            )}
            {summaryLesson && <LessonResult summaryLesson={summaryLesson} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default LessonPage;
