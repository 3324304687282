import Button from 'Components/Button';
import { MainType } from './Container';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import isDesktop from 'Shared/utils/isDesktop';
import { useEffect } from 'react';

function HomePage(props: MainType) {
  const {} = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (isDesktop) {
      navigate(ROUTES.LOGIN_PAGE);
    }
  }, []);
  return (
    <div className="home-page ">
      <aside className="home-page__right">
        <div className="home-page__right-container">
          <SVG type={SVG_TYPE.BIG_LOGO} className="home-page__logo" />
          <h2 className="home-page__title">Przygotuj się do egzaminu z&nbsp;Royal Academy!</h2>
          <ul className="home-page__list">
            <li className="home-page__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              <p>Ścieżka nauki z postępami</p>
            </li>
            <li className="home-page__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              <p>Widoczny % szans na zdanie egzaminu państwowego</p>
            </li>
            <li className="home-page__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              <p>Nielimitowana ilość podejść do egzaminu</p>
            </li>
            <li className="home-page__list-item">
              <SVG type={SVG_TYPE.CHECKED} />
              <p>Szczegółowe statystyki dotyczące nauki oraz egzaminu</p>
            </li>
          </ul>
        </div>
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="home-page__animated-element home-page__animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="home-page__animated-element home-page__animated-element--second" />
        <div className="home-page__buttons">
          {/* <Button label={'Rozpocznij bez logowania'} onClick={() => navigate(ROUTES.LEARNING)} stroke /> */}
          <Button label={'Dalej'} onClick={() => navigate(ROUTES.LOGIN_PAGE)} />
        </div>
      </aside>
    </div>
  );
}

export default HomePage;
