import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { MainType } from './Container';
import { useEffect, useState } from 'react';
import Button from 'Components/Button';
import { AddDepartment, DashboardCategory, Department, EditDepartment, DeleteDepartment } from './components';
import { Pagination } from 'Components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DATABASE_CATEGORY = ['Wszystkie', 'A', 'B', 'C', 'D'];

function AdminLearningPage(props: MainType) {
  const { actions, drivingCategories, departments } = props;
  const { getDrivingCategory, addDepartment, editDepartment, deleteDepartment, getDepartments, addDepartmentPhoto } = actions;
  const [selectedType, setSelectedType] = useState('Wszystkie');
  const [currentPage, setCurrentPage] = useState(1);
  const [openedModal, setOpenedModal] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [deletedData, setDeletedData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedType]);

  useEffect(() => {
    getDrivingCategory();
  }, []);

  useEffect(() => {
    setLoading(false);
    const drivingCategoriesType = drivingCategories.find((item) => item.label.includes(selectedType));
    if (drivingCategoriesType) {
      getDepartments({ categoryUuid: drivingCategoriesType.value, page: currentPage });
    } else {
      getDepartments({ page: currentPage });
    }
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, [selectedType, currentPage]);

  // useEffect(() => {
  //   if (currentPage != 0) {
  //     getDepartments({ page: currentPage });
  //   }
  // }, [currentPage]);

  const updateAfterAddDepartment = () => {
    const drivingCategoriesType = drivingCategories.find((item) => item.label.includes(selectedType));
    getDepartments({ categoryUuid: drivingCategoriesType.value, page: currentPage });
  };

  return (
    <>
      <header className="dashboard__header admin-learning-page-container">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
        <div className="dashboard__container">
          <h1 className="dashboard__title">Nauka</h1>
          <div className="dashboard__categories dashboard__categories--users">
            {DATABASE_CATEGORY.map((item) => {
              return (
                <DashboardCategory key={item} type={item} selectedType={selectedType} setSelectedType={setSelectedType} label={item} style="big" />
              );
            })}
          </div>
        </div>
      </header>
      <div className="admin-learning-page admin-learning-page-container">
        <div className="dashboard__container ">
          <div className="admin-learning-page__top">
            <p>Działy</p>
            <Button label={'Dodaj +'} className="admin-learning-page__add-button" onClick={() => setOpenedModal('addUser')} />
          </div>
          <div className="admin-learning-page__departments">
            {departments && loading ? (
              <>
                {departments &&
                  departments.data.length > 0 &&
                  departments.data.map((department) => (
                    <Department setDeletedData={setDeletedData} data={department} key={department.uuid} setEditedData={setEditedData} />
                  ))}
              </>
            ) : (
              <>
                <div className="department department--skeleton">
                  <Skeleton height={175} />
                </div>
                <div className="department department--skeleton">
                  <Skeleton height={175} />
                </div>
                <div className="department department--skeleton">
                  <Skeleton height={175} />
                </div>
                <div className="department department--skeleton">
                  <Skeleton height={175} />
                </div>
                <div className="department department--skeleton">
                  <Skeleton height={175} />
                </div>
                <div className="department department--skeleton">
                  <Skeleton height={175} />
                </div>
              </>
            )}
          </div>
          <DeleteDepartment
            data={deletedData}
            deleteDepartment={deleteDepartment}
            isOpen={deletedData !== null}
            onClose={() => setDeletedData(null)}
            getDepartments={updateAfterAddDepartment}
          />
          <AddDepartment
            addDepartmentPhoto={addDepartmentPhoto}
            addDepartment={addDepartment}
            isOpen={openedModal === 'addUser'}
            drivingCategories={drivingCategories}
            onClose={() => setOpenedModal(null)}
            getDepartments={updateAfterAddDepartment}
          />
          <EditDepartment
            addDepartmentPhoto={addDepartmentPhoto}
            editDepartment={editDepartment}
            isOpen={editedData !== null}
            drivingCategories={drivingCategories}
            onClose={() => setEditedData(null)}
            data={editedData}
          />
          {departments && departments.meta.last_page > 1 ? (
            <Pagination
              className="order-list__pagination"
              totalPages={departments.meta.last_page}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default AdminLearningPage;
