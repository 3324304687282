import * as UserModule from 'Modules/UserModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.GET_USER_EXAM_INIT,
});

export const success = (data) => ({
  type: User.GET_USER_EXAM_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_USER_EXAM_FAILURE,
});

const getUserExam = () => async (dispatch) => {
  dispatch(init());
  try {
    // dispatch(ViewManagementModule.Actions.showLoader());
    const response = await UserModule.Connector.getUserExam();
    dispatch(success(response.data));
    // dispatch(ViewManagementModule.Actions.hideLoader());
    return true;
  } catch (err) {
    // dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    return false;
  }
};

export default getUserExam;
