import { SVG } from 'Components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';

type SingleTaskType = {
  deleteSaveQuestion: any;
  data: any;
  totalTask: any;
  getSaveTasks: any;
};

const SingleTask = ({ data, deleteSaveQuestion, totalTask, getSaveTasks }: SingleTaskType) => {
  const navigate = useNavigate();
  const questionTypeDesktop = {
    PODSTAWOWY: 'Podstawowy',
    SPECJALISTYCZNY: 'Specjalistyczny',
  };
  const questionTypeMobile = {
    PODSTAWOWY: 'Pytanie podstawowe',
    SPECJALISTYCZNY: 'Pytanie specjalistyczne',
  };
  const isQuestionSaved = true;

  const handleUnsaveTask = async () => {
    await deleteSaveQuestion({ taskUuid: data.uuid, isQuestionSaved, total: totalTask - 1 });
    await getSaveTasks(1);
  };

  const handleRedirectToSaveTask = () => {
    navigate({ pathname: ROUTES.SAVE_TASK.replace('uuid', data.uuid) });
  };

  return (
    <>
      <tr className="single-task">
        <td className="single-task__td single-task--name">{data?.question?.name}</td>
        <td className="single-task__td">{questionTypeDesktop[data?.question?.type]}</td>
        <td className="single-task__td">{data?.question?.pointsNumber} pkt.</td>
        <td className="single-task__td single-task__td--question">{data?.question?.question}</td>
        <td className="single-task__td single-task__td--icon">
          <div className="single-task__icon" onClick={() => handleUnsaveTask()}>
            <SVG type={SVG_TYPE.SAVE_LABEL} />
          </div>
        </td>
        <td className="single-task--onclick">
          <div className="single-task--onclick" onClick={() => handleRedirectToSaveTask()}></div>
        </td>
      </tr>
      <div className="single-task-mobile">
        <div className="single-task-mobile__top">
          <div className="single-task-mobile__heading">{data?.question?.question}</div>
          <div className="single-task-mobile__icon" onClick={() => handleUnsaveTask()}>
            <SVG type={SVG_TYPE.SAVE_LABEL} />
          </div>
        </div>
        <div className="single-task-mobile__bottom">
          <div className="single-task-mobile__number">{data?.question?.name}</div>
          <div className="single-task-mobile__points">{data?.question?.pointsNumber} pkt.</div>
          <div className="single-task-mobile__type">{questionTypeMobile[data?.question?.type]}</div>
        </div>
      </div>
    </>
  );
};

export default SingleTask;
