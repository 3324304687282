import { Button, Modal, Input } from 'Components';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES } from 'Shared/enums';

type EditLessonType = {
  isOpen: boolean;
  onClose: () => void;
  editLesson: (uuid, data) => void;
  data: any;
};

const EditLesson = ({ isOpen, onClose, editLesson, data }: EditLessonType) => {
  const initialValues = {
    name: data.name || '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
  });

  const handleOnSubmit = async (values, { resetForm }) => {
    await editLesson(data.uuid, { ...values, sort: true });
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-mobile--full">
      <h2 className="edit-user-modal__header">Edycja lekcji</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => handleOnSubmit(values, actions)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="add-lesson-form">
            <Field className={''} label="Nazwa" name="name" placeholder="Wpisz nazwę lekcji" required component={Input} errors={errors} />
            <div className="add-lesson-form__buttons">
              <Button label="Anuluj" type="button" onClick={() => onClose()} stroke />
              <Button label="Potwierdź" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditLesson;
