import React, { useEffect, useState } from 'react';
import { MainType } from './Container';
import { SVG_TYPE, ROUTES, VALIDATION_MESSAGES, USER_TYPE } from 'Shared/enums';
import { SVG, Button, Input, Select, Textarea, Checkbox } from 'Components';
import { useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { phoneNumberValidation } from 'Shared/utils';

const Contact = (props: MainType) => {
  const { actions, phonePrefixes, authorizedUser } = props;
  const { getPhonePrefixes, sendMail } = actions;
  const [isMessageSend, setIsMessageSend] = useState(false);
  const navigate = useNavigate();
  const isUserDemo = authorizedUser.type === USER_TYPE.DEMO;

  useEffect(() => {
    getPhonePrefixes();
  }, []);

  const goBack = () => {
    navigate({ pathname: ROUTES.MYPROFILE });
  };

  const handleOnSubmit = async (values, { resetForm }) => {
    sendMail(values);
    setIsMessageSend(true);
    resetForm();
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    information: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    phoneNumber: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .nullable()
      .when('phonePrefixUuid', (phonePrefixUuid, schema) => phoneNumberValidation(phonePrefixUuid, schema)),
    terms: Yup.boolean().oneOf([true], VALIDATION_MESSAGES.TERMS),
  });

  const phonePrefixesOptions = phonePrefixes.map((item) => {
    return {
      value: item.value,
      label: `+ ${item.phonePrefix}`,
      svgType: SVG_TYPE[`FLAG_${item.country.toUpperCase()}`],
    };
  });

  const initialValues = {
    firstName: !isUserDemo ? authorizedUser.name : '',
    phonePrefixUuid: phonePrefixesOptions[0]?.value,
    phoneNumber: authorizedUser.phoneNumber || '',
    information: '',
    terms: false,
  };

  return (
    <>
      <header className="dashboard__header learning-page__header">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

        <div className="dashboard__container departments-page__header-container">
          <h1 className="dashboard__title departments-page__title contact__title">
            Pomoc / Kontakt
            <div onClick={goBack} className="dashboard__goBack">
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          </h1>
        </div>
      </header>
      <div className="dashboard__content contact__container">
        <div className="dashboard__container contact">
          {isMessageSend ? (
            <div className="contact__send-wrapper">
              <div className="contact__send-content">
                <div className="contact__send-icon">📬</div>
                <div className="contact__send-header">Twoja wiadomość została wysłana 📩</div>
                <div className="contact__send-paragraph">Odpowiedź wyślemy na podany przez Ciebie numer telefonu.</div>
                <div className="contact__send-button">
                  <Button label="Wróć do profilu" onClick={() => goBack()} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="contact__paragraph">
                Masz jakieś pytania, wątpliwości, uwagi? Napisz do nas, udzielimy pomocy najszybciej jak to możliwe!
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnBlur={false}
                // validateOnChange={false}
                onSubmit={async (values, actions) => await handleOnSubmit(values, actions)}>
                {({ errors, handleSubmit, isValid, setFieldTouched, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} className="contact__form">
                      <div className="contact__form-top">
                        {isUserDemo && (
                          <>
                            <Field className={''} label="Imię" name="firstName" placeholder="Imię" required component={Input} errors={errors} />
                            <div className="phone-cell">
                              <Field
                                component={Select}
                                label="Telefon"
                                name="phonePrefixUuid"
                                options={phonePrefixesOptions}
                                errors={errors}
                                className={'phone-cell--prefix'}
                                required
                                placeholder=" "
                              />
                              <Field
                                placeholder=" "
                                component={Input}
                                type="number"
                                required
                                name="phoneNumber"
                                errors={errors}
                                className={'phone-cell--number'}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <Field
                        label="Wiadomość"
                        name="information"
                        placeholder="Wpisz treść wiadomości"
                        required
                        component={Textarea}
                        errors={errors}
                        treshold={0}
                        className="contact--message"
                      />
                      <Field component={Checkbox} name="terms" className={'contact__checkbox'} errors={errors} required>
                        <p className="contact__terms">
                          Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w ramach kontaktu.
                          Administratorem danych osobowych jest Royal Academy.
                        </p>
                      </Field>
                      <div className="contact__buttons">
                        <Button
                          label="Wyślij"
                          onClick={() => {
                            setFieldTouched('terms', true);
                            setFieldTouched('phonePrefix', true);
                            setFieldTouched('phoneNumber', true);
                            setFieldTouched('name', true);
                            setFieldTouched('information', true);
                            isValid && !isSubmitting && handleSubmit();
                          }}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Contact;
