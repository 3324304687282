import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { phonePrefixes } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { sendPasswordResetLink } from 'Store/Actions/Auth';
import ResetPasswordPage from './Main';
type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
};

type DispatchedP = {
  actions: {
    sendPasswordResetLink: ({ email }) => (dispatch: any) => Promise<boolean>;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      sendPasswordResetLink: sendPasswordResetLink,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
