import { useEffect, useState } from 'react';
import { MainType } from './Container';
import { SingleQuestion, EditQuestionModal, CurrentDateForm } from './components';
import { Pagination } from 'Components';
import { useNavigate } from 'react-router';
import { useLocation, generatePath } from 'react-router-dom';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Components';

enum DATABASE_CATEGORY {
  ALL = 'all',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

function QuestionDatabasePage(props: MainType) {
  const {
    actions: { getQuestions, editQuestion, updateDatabaseDate },
    questions,
    authorizedUser,
  } = props;
  const [edittedQuestion, setEdittedQuestion] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const lastElement = parts.pop();
  const categoryLink = parts[parts.length - 1];
  const [selectedType, setSelectedType] = useState(categoryLink || DATABASE_CATEGORY.ALL);

  const [currentPage, setCurrentPage] = useState(parseInt(lastElement));

  useEffect(() => {
    if (currentPage !== 1 || selectedType) {
      getQuestions({ category: selectedType, page: currentPage });
    }
  }, [currentPage, selectedType]);

  useEffect(() => {
    if (currentPage.toString() !== lastElement) {
      setCurrentPage(parseInt(lastElement));
    }
  }, [lastElement]);

  const changePage = (page) => {
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.QUESTIONS_DATABASE, { category: selectedType, page }) });
    }, 200);
  };

  const changeCategory = (category) => {
    setSelectedType(category);
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.QUESTIONS_DATABASE, { category: category, page: '1' }) });
    }, 200);
  };

  return (
    <>
      <header className="dashboard__header question-database">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
        <div className="dashboard__container">
          <h1 className="dashboard__title">Baza pytań</h1>
          <div className="dashboard__categories">
            <p
              className={`dashboard__category ${selectedType === DATABASE_CATEGORY.ALL ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(DATABASE_CATEGORY.ALL)}>
              Wszystkie
            </p>
            <p
              className={`dashboard__category ${selectedType === DATABASE_CATEGORY.A ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(DATABASE_CATEGORY.A)}>
              A
            </p>
            <p
              className={`dashboard__category ${selectedType === DATABASE_CATEGORY.B ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(DATABASE_CATEGORY.B)}>
              B
            </p>
            <p
              className={`dashboard__category ${selectedType === DATABASE_CATEGORY.C ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(DATABASE_CATEGORY.C)}>
              C
            </p>
            <p
              className={`dashboard__category ${selectedType === DATABASE_CATEGORY.D ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(DATABASE_CATEGORY.D)}>
              D
            </p>
          </div>
        </div>
      </header>
      <div className="questions-list question-database">
        <div className="dashboard__container">
          <div className="questions-list__data-bar">
            <CurrentDateForm data={authorizedUser} updateDatabaseDate={updateDatabaseDate} />
          </div>
          <table className="questions-list__table">
            <thead className="questions-list__thead">
              <tr>
                <th>Nazwa</th>
                <th>Typ</th>
                <th>Kategoria</th>
                <th>Treść pytania</th>
                <th>Wyjaśnienie</th>
              </tr>
            </thead>
            <tbody className="questions-list__tbody">
              {questions &&
                questions?.data.length > 0 &&
                questions?.data.map((question, index) => (
                  <SingleQuestion setEdittedQuestion={setEdittedQuestion} index={index} key={question.uuid} question={question} />
                ))}
            </tbody>
          </table>
          <EditQuestionModal
            editQuestion={editQuestion}
            onClose={() => setEdittedQuestion(null)}
            isOpen={edittedQuestion !== null}
            question={edittedQuestion}
          />
          {questions?.meta.last_page > 1 ? (
            <Pagination
              className="questions-list--pagination"
              totalPages={questions.meta?.last_page || 0}
              currentPage={currentPage}
              onPageChange={(page) => changePage(page)}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default QuestionDatabasePage;
