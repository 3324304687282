import { ANSWER_TYPE } from '../enums/answer_type';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

type BasicQuestionButtonsType = {
  answer: string;
  handleChoice: (string) => void;
  isPropertyAnswer: string;
};

const BasicQuestionButtons = ({ answer, handleChoice, isPropertyAnswer }: BasicQuestionButtonsType) => {
  const isButtonDisabled = isPropertyAnswer && answer ? 'basic-question-button--disabled' : '';
  const isChoiceTrue = isPropertyAnswer
    ? answer === ANSWER_TYPE.T
      ? `basic-question-button--choice ${isPropertyAnswer === ANSWER_TYPE.T ? 'basic-question-button--green' : 'basic-question-button--red'}`
      : ''
    : answer === ANSWER_TYPE.T
    ? `basic-question-button--choice`
    : null;
  const isChoiceFalse = isPropertyAnswer
    ? answer === ANSWER_TYPE.N
      ? `basic-question-button--choice ${isPropertyAnswer === ANSWER_TYPE.N ? 'basic-question-button--green' : 'basic-question-button--red'}`
      : ''
    : answer === ANSWER_TYPE.N
    ? `basic-question-button--choice`
    : '';

  return (
    <div className="basic-question-buttons">
      <div className={`basic-question-button ${isButtonDisabled} ${isChoiceTrue}`} onClick={() => handleChoice(ANSWER_TYPE.T)}>
        TAK
        {answer === ANSWER_TYPE.T ? !isPropertyAnswer && <SVG type={SVG_TYPE.CIRCLE_CHECKED} /> : null}
      </div>
      <div className={`basic-question-button ${isButtonDisabled} ${isChoiceFalse}`} onClick={() => handleChoice(ANSWER_TYPE.N)}>
        NIE
        {answer === ANSWER_TYPE.N ? !isPropertyAnswer && <SVG type={SVG_TYPE.CIRCLE_CHECKED} /> : null}
      </div>
    </div>
  );
};

export default BasicQuestionButtons;
