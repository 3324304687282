import { connect } from 'react-redux';
import MyProfile from './Main';
import { bindActionCreators } from 'redux';
import { authorizedUser, phonePrefixes } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { adminLogin, logout } from 'Store/Actions/Auth';
import { changeUserName, deleteCurrentUser, editAvatar, changeUserEmail } from 'Store/Actions/User';
type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
  authorizedUser: any | 'init';
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    deleteCurrentUser: () => (dispatch: any) => Promise<boolean>;
    changeUserName: (name: string) => (dispatch: any) => Promise<boolean>;
    changeUserEmail: (email: string) => (dispatch: any) => Promise<boolean>;
    logout: () => (dispatch: any, getState) => Promise<any>;
    editAvatar: (uuid, file, boolean) => void;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      editAvatar:editAvatar,
      adminLogin: adminLogin,
      changeUserName: changeUserName,
      deleteCurrentUser: deleteCurrentUser,
      logout: logout,
      changeUserEmail: changeUserEmail,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
