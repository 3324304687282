import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Components';
import { ANSWER_TYPE } from '../enums/answer_type';

type SpecializedQuestionButtonsType = {
  answer: string;
  handleChoice: (string) => void;
  isPropertyAnswer: string;
  activeTask: any;
};

const SpecializedQuestionButtons = ({ answer, handleChoice, isPropertyAnswer, activeTask }: SpecializedQuestionButtonsType) => {
  const isPropertyAnswered = isPropertyAnswer && answer === isPropertyAnswer;
  const isPropertyAnsweredClass = isPropertyAnswered ? 'box--correct' : 'box--wrong';
  const isCheckedA = answer === ANSWER_TYPE.A ? `box--active ${isPropertyAnswer ? isPropertyAnsweredClass : ''}` : '';
  const isCheckedB = answer === ANSWER_TYPE.B ? `box--active ${isPropertyAnswer ? isPropertyAnsweredClass : ''}` : '';
  const isCheckedC = answer === ANSWER_TYPE.C ? `box--active ${isPropertyAnswer ? isPropertyAnsweredClass : ''}` : '';
  const isBoxDisabled = isPropertyAnswer && answer ? 'box--disabled' : '';
  const checkPropertyAnswer = !isPropertyAnswered && isPropertyAnswer;
  const checkPropertyAnswerA = checkPropertyAnswer === ANSWER_TYPE.A;
  const checkPropertyAnswerB = checkPropertyAnswer === ANSWER_TYPE.B;
  const checkPropertyAnswerC = checkPropertyAnswer === ANSWER_TYPE.C;

  return (
    <div className="specialized-question-buttons">
      <div className="item">
        <div className={`box ${isBoxDisabled} ${isCheckedA}`} onClick={() => handleChoice(ANSWER_TYPE.A)}>
          A{checkPropertyAnswerA && <SVG className="checked" type={SVG_TYPE.CIRCLE_GREEN} />}
        </div>
        <div className="description">{activeTask.question.answerA}</div>
      </div>
      <div className="item">
        <div className={`box ${isBoxDisabled} ${isCheckedB}`} onClick={() => handleChoice(ANSWER_TYPE.B)}>
          B{checkPropertyAnswerB && <SVG className="checked" type={SVG_TYPE.CIRCLE_GREEN} />}
        </div>
        <div className="description">{activeTask.question.answerB}</div>
      </div>
      <div className="item">
        <div className={`box ${isBoxDisabled} ${isCheckedC}`} onClick={() => handleChoice(ANSWER_TYPE.C)}>
          C{checkPropertyAnswerC && <SVG className="checked" type={SVG_TYPE.CIRCLE_GREEN} />}
        </div>
        <div className="description">{activeTask.question.answerC}</div>
      </div>
    </div>
  );
};

export default SpecializedQuestionButtons;
