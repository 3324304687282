import { connect } from 'react-redux';
import LoginPage from './Main';
import { bindActionCreators } from 'redux';
import getSmsCode from 'Store/Actions/Auth/getSmsCode';
import getPhonePrefixes from 'Store/Actions/Auth/getPhonePrefix';
import { authorizedUser, phonePrefixes } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { createDemoUser, loginAsDemo } from 'Store/Actions/Auth';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { User } from 'Shared/models/UserModel';
type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
  authorizedUser: User | 'init';
};

type DispatchedP = {
  actions: {
    getSmsCode: ({ phoneNumber, phonePrefixUuid, userUuid }) => (dispatch: any) => Promise<boolean>;
    getPhonePrefixes: () => (dispatch: any) => Promise<boolean>;
    createDemoUser: () => (dispatch: any) => Promise<boolean>;
    loginAsDemo: (userUuid: string) => (dispatch: any) => Promise<boolean>;
    getAuthorizedUser: () => any;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getSmsCode: getSmsCode,
      getPhonePrefixes: getPhonePrefixes,
      createDemoUser: createDemoUser,
      loginAsDemo: loginAsDemo,
      getAuthorizedUser: AuthActions.getAuthorizedUser,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
