import { useState, useEffect } from 'react';
import { MainType } from './Container';
import { SVG, Button } from 'Components';
import { SVG_TYPE, ROUTES, USER_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { AnswersModal } from './components';

const Question = ({ questions, isActive, result, setActiveQuestion, setIsAnswersModalOpen, setStartIndex, setIsClickBox }: any) => {
  return (
    <div className="questions__content">
      {questions.map((item, idx) => {
        const handleClick = () => {
          setActiveQuestion(result.question[idx]);
          setIsAnswersModalOpen(true);
          setStartIndex(idx);
          setIsClickBox(true);
        };

        return (
          <div
            onClick={() => (isActive ? handleClick() : null)}
            key={item.uuid}
            className={`questions__item ${isActive ? 'questions__item--active' : ''} ${
              item.isAnsweredCorrectly ? 'questions__item--green' : 'questions__item--red'
            }`}>
            {idx + 1}
          </div>
        );
      })}
    </div>
  );
};

const DrivingTestSummaryPage = (props: MainType) => {
  const [startIndex, setStartIndex] = useState(0);
  const [isAnswersModalOpen, setIsAnswersModalOpen] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(undefined);
  const [isClickBox, setIsClickBox] = useState(false);
  const [page, setPage] = useState(0);
  const [result, setResult] = useState(undefined);
  const navigate = useNavigate();
  const {
    drivingTestSummary,
    authorizedUser,
    actions: { getAuthorizedUser, getSingleSummary },
  } = props;
  const summary = JSON.parse(localStorage.getItem('EXAM_SUMMARY'));
  const pathname = window.location.pathname;
  const examUuid = pathname.split(':')[1];

  useEffect(() => {
    const fetchData = async () => {
      if (examUuid !== 'uuid') {
        const response = await getSingleSummary(examUuid);
        sessionStorage.removeItem('DEMO_TO_STANDARD');
        localStorage.removeItem('EXAM_SUMMARY');
        localStorage.removeItem('SUMMARY_EXAM_ID');
        if (response) {
          setResult(response);
        } else {
          navigate({ pathname: ROUTES.PAGE_404 });
        }
      } else {
        if (summary || drivingTestSummary) {
          setResult(summary || drivingTestSummary);
        } else {
          navigate({ pathname: ROUTES.EXAM });
        }
      }
    };
    fetchData();
  }, []);

  const { examPassed, pointsGet, question, anseredIncorrectly, answeredCorrectly, examTime, availableExam, user } = result || {};
  const impulsOrPremium = user?.type === USER_TYPE.STUDENT_IMPULS || user?.type === USER_TYPE.PREMIUM;
  const standard = user?.type === USER_TYPE.STANDARD;

  useEffect(() => {
    authorizedUser && getAuthorizedUser();
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      setPage(0);
      event.preventDefault();
      if (activeQuestion && !isClickBox) {
        setIsClickBox(false);
        setActiveQuestion(undefined);
      } else {
        if (isAnswersModalOpen) {
          setIsClickBox(false);
          setIsAnswersModalOpen(false);
          setActiveQuestion(undefined);
        } else {
          navigate({ pathname: ROUTES.EXAM });
        }
      }
    };
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [isAnswersModalOpen, activeQuestion]);

  return (
    <>
      {result && (
        <>
          <AnswersModal
            setIsClickBox={setIsClickBox}
            standard={standard}
            isClickBox={isClickBox}
            startIndex={startIndex}
            setStartIndex={setStartIndex}
            userRole={authorizedUser?.type}
            impulsOrPremium={impulsOrPremium}
            result={result}
            isOpen={isAnswersModalOpen}
            onClose={() => setIsAnswersModalOpen(false)}
            activeQuestion={activeQuestion}
            setActiveQuestion={setActiveQuestion}
            page={page}
            setPage={setPage}
          />
          <div className={`drivingTestSummaryPage ${isAnswersModalOpen ? 'drivingTestSummaryPage--disable' : ''}`}>
            <div
              className="exit"
              onClick={() => {
                navigate({ pathname: ROUTES.EXAM });
                setTimeout(() => {
                  localStorage.removeItem('EXAM_SUMMARY');
                }, 1000);
              }}>
              Zamknij
              <SVG type={SVG_TYPE.EXIT} />
            </div>
            <div className="content">
              <div className="container">
                {(impulsOrPremium || standard) && (
                  <div className="buttons buttons--mobile">
                    {!availableExam ? (
                      <div className="max-retries">
                        Wykorzystano dostępną ilość prób egzaminu <SVG type={SVG_TYPE.EXCLAMATION} />
                      </div>
                    ) : (
                      <>
                        <Button
                          label="Zobacz odpowiedzi"
                          className="btn btn--orange btn--small"
                          type="button"
                          onClick={() => setIsAnswersModalOpen(true)}
                        />
                        <Button
                          label="Nowy egzamin"
                          className="btn--stroke--orange btn--small"
                          type="button"
                          onClick={() => {
                            navigate({ pathname: ROUTES.EXAMINATION });
                            localStorage.removeItem('EXAM_SUMMARY');
                          }}
                          stroke
                        />
                      </>
                    )}
                  </div>
                )}
                {!impulsOrPremium && (
                  <>
                    {availableExam && !standard && (
                      <Button
                        label="Nowy egzamin"
                        className="btn--mobile btn--stroke--orange btn--small"
                        type="button"
                        onClick={() => {
                          navigate({ pathname: ROUTES.EXAMINATION });
                          localStorage.removeItem('EXAM_SUMMARY');
                        }}
                        stroke
                      />
                    )}
                    {!availableExam && (
                      <div className="max-retries max-retries--mobile">
                        Wykorzystano dostępną ilość prób egzaminu <SVG type={SVG_TYPE.EXCLAMATION} />
                      </div>
                    )}
                    <div className="sidebar-invitation">
                      {!standard && <SVG type={SVG_TYPE.ENTRY} className="sidebar-invitation__icon" />}
                      {standard && <SVG type={SVG_TYPE.STAR} className="sidebar-invitation__icon sidebar-invitation__icon--star" />}
                      {!standard && (
                        <div
                          className="sidebar-invitation__paragraph"
                          onClick={() => {
                            navigate({ pathname: ROUTES.LOGIN_PAGE });
                            sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
                            localStorage.removeItem('EXAM_SUMMARY');
                            localStorage.setItem('SUMMARY_EXAM_ID', JSON.stringify(result?.uuid));
                          }}>
                          Jeśli chcesz mieć możliwość podglądu pytań oraz większą ilość prób egzaminu - załóż konto
                        </div>
                      )}
                      {standard && (
                        <div className="sidebar-invitation__paragraph">
                          Jeśli chcesz mieć możliwość podglądu odpowiedzi i wyjaśnienia oraz nieograniczoną ilość prób egzaminu — wykup pakiet
                          PREMIUM.
                        </div>
                      )}
                      {!standard && (
                        <div
                          className="sidebar-invitation__call-to-action"
                          onClick={() => {
                            navigate({ pathname: ROUTES.LOGIN_PAGE });
                            sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
                            localStorage.removeItem('EXAM_SUMMARY');
                            localStorage.setItem('SUMMARY_EXAM_ID', JSON.stringify(result?.uuid));
                          }}>
                          Załóż konto <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
                        </div>
                      )}
                      {standard && (
                        <div
                          className="sidebar-invitation__call-to-action"
                          onClick={() => {
                            navigate({ pathname: ROUTES.MYPROFILE_PACKET });
                            localStorage.setItem('SUMMARY_EXAM_ID', JSON.stringify(result?.uuid));
                            sessionStorage.setItem('STANDARD_TO_PREMIUM', 'true');
                          }}>
                          Dowiedz się więcej <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="stats">
                  <div className="stats__icon">{examPassed ? '🥳' : '😔'}</div>
                  <div className="stats__result">
                    Wynik egzaminu:
                    {examPassed ? <p className="paragraph paragraph--green">POZYTYWNY</p> : <p className="paragraph paragraph--red">NEGATYWNY</p>}
                  </div>
                  <div className="stats__score">
                    <div className="stats__score-item">
                      Zdobyte punkty:<span>{pointsGet}/74</span>
                    </div>
                    <div className="stats__score-item">
                      Odpowiedzi poprawne:<span>{answeredCorrectly}/32</span>
                    </div>
                    <div className="stats__score-item">
                      Odpowiedzi błędne:<span>{anseredIncorrectly}/32</span>
                    </div>
                    <div className="stats__score-item">
                      Czas trwania egzaminu:<span>{examTime} min</span>
                    </div>
                  </div>
                  {question && (
                    <Question
                      setIsClickBox={setIsClickBox}
                      setStartIndex={setStartIndex}
                      setIsAnswersModalOpen={setIsAnswersModalOpen}
                      result={result}
                      setActiveQuestion={setActiveQuestion}
                      isActive={impulsOrPremium}
                      questions={question}
                    />
                  )}
                  {!impulsOrPremium && !standard && (
                    <>
                      {availableExam && (
                        <Button
                          label="Nowy egzamin"
                          className="btn--stroke--orange btn--small"
                          type="button"
                          onClick={() => {
                            navigate({ pathname: ROUTES.EXAMINATION });
                            localStorage.removeItem('EXAM_SUMMARY');
                          }}
                          stroke
                        />
                      )}
                      {!availableExam && (
                        <div className="max-retries">
                          Wykorzystano dostępną ilość prób egzaminu <SVG type={SVG_TYPE.EXCLAMATION} />
                        </div>
                      )}
                    </>
                  )}
                  {(impulsOrPremium || standard) && (
                    <div className="buttons">
                      {!availableExam ? (
                        <div className="drivingTestSummaryPage__max-retries-btn">
                          <div className="max-retries">
                            Wykorzystano dostępną ilość prób egzaminu
                            <SVG type={SVG_TYPE.EXCLAMATION} />
                          </div>
                          <Button
                            label="Zobacz odpowiedzi"
                            className="btn btn--orange btn--small"
                            type="button"
                            onClick={() => setIsAnswersModalOpen(true)}
                          />
                        </div>
                      ) : (
                        <>
                          <Button
                            label="Zobacz odpowiedzi"
                            className="btn btn--orange btn--small"
                            type="button"
                            onClick={() => setIsAnswersModalOpen(true)}
                          />
                          <Button
                            label="Nowy egzamin"
                            className="btn--stroke--orange btn--small"
                            type="button"
                            onClick={() => {
                              navigate({ pathname: ROUTES.EXAMINATION });
                              localStorage.removeItem('EXAM_SUMMARY');
                            }}
                            stroke
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DrivingTestSummaryPage;
