import * as Types from 'Shared/models/DepartmentModel';
import { Department } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.DepartmentStore;

export const initialState: State = {
  departments: null,
  learningInfo: null,
};

export function departmentReducer(state: any = initialState, action) {
  switch (action.type) {
    case Department.GET_DEPARTMENTS_INIT: {
      return {
        departments: undefined,
      };
    }
    case Department.GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.payload.data,
      };
    }
    case Department.EDIT_DEPARTMENT_SUCCESS: {
      const departmentsState = state.departments.data
      const element = action.payload.data.department
      const updatedData = departmentsState.map((item) => {
        if (item.uuid === element.uuid) {
          return {...item,name: element.name, status: element.status, categories: element.categories}; 
        }
        return item;
      });
      return {
        ...state,
        departments: {
          ...state.departments,
          data: updatedData,
        },
      };
    }

    case Department.ADD_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        departments: {...state.departments, data:[...state.departments.data, action.payload.data.department]},
      };
    }
    case Department.DELETE_DEPARTMENT_SUCCESS: {
      const element = action.payload.data.department;
      const departmentsState = state.departments.data;
    
      const updatedData = departmentsState.filter(item => item.uuid !== element.uuid);
    
      return {
        ...state,
        departments: { ...state.departments, data: updatedData },
      };
    }

    case Department.ADD_DEPARTMENT_PHOTO_SUCCESS: {
      const element = action.payload.data.department;
      const departmentsState = state.departments.data;
    
      const updatedData = departmentsState.map((item) => {
        if (item.uuid === element.uuid) {
          return {...item, file: [{...element.file[0]}]}; 
        }
        return item;
      });
    
      return {
        ...state,
        departments: { ...state.departments, data: updatedData },
      };
    }

    case Department.GET_CLIENT_LEARNING_INFO_SUCCESS: {
      return {
        ...state,
        learningInfo: action.payload.data,
      };
    }

    case Department.GET_CLIENT_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.payload.data,
      };
    }
    
    default: {
      return state;
    }
  }
}
