import * as PaymentsConnector from 'Modules/PaymentModule';
import { Payment } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Payment.GET_PAYMENTS_LABEL_INIT,
});

export const success = (data) => ({
  type: Payment.GET_PAYMENTS_LABEL_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Payment.GET_PAYMENTS_LABEL_FAILURE,
});

const getPaymentsLabel = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await PaymentsConnector.Connector.getPaymentsLabel();
    dispatch(success(response.data.paymentTypes));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getPaymentsLabel;
