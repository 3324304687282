// import { MainType } from './Container';
import { useEffect, useState } from 'react';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router-dom';
import { StatisticBox } from './components';
import { MainType } from './Container';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

enum CATEGORY {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

function UserStatistics(props: MainType) {
  const [selectedType, setSelectedType] = useState(CATEGORY.A);
  const [isLoading, setIsLoading] = useState(false);
  const { actions, userStats } = props;
  const { getUserStats } = actions;
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const userUuid = pathname.split(':')[1];

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getUserStats({ userUuid, category: selectedType });
      setIsLoading(false);
    };
    fetchData();
  }, [selectedType]);

  const changeCategory = (category) => {
    setSelectedType(category);
  };

  console.log(userStats);

  return (
    <>
      <header className="dashboard__header learning-page__header">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
        <div className="dashboard__container departments-page__header-container">
          <h1 className="dashboard__title departments-page__title user-statistics--page-title">
            {userStats?.userName} - statystyki
            <div onClick={goBack} className="dashboard__goBack">
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          </h1>
          <div className="dashboard__categories user-statistics__categories">
            <p
              className={`dashboard__category ${selectedType === CATEGORY.A ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(CATEGORY.A)}>
              A
            </p>
            <p
              className={`dashboard__category ${selectedType === CATEGORY.B ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(CATEGORY.B)}>
              B
            </p>
            <p
              className={`dashboard__category ${selectedType === CATEGORY.C ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(CATEGORY.C)}>
              C
            </p>
            <p
              className={`dashboard__category ${selectedType === CATEGORY.D ? 'dashboard__category--active' : ''}`}
              onClick={() => changeCategory(CATEGORY.D)}>
              D
            </p>
          </div>
        </div>
      </header>
      <div className="users-list question-database user-statistics__wrapper">
        <div className="dashboard__container ">
          <div className="user-statistics__content">
            {userStats && !isLoading ? (
              <>
                <StatisticBox
                  heading="Status nauki"
                  label="Pytania"
                  numbers={`${userStats?.questionAnsweredCorrectly}/${userStats?.questionAll}`}
                  numberText="Odpowiedziane pytania"
                  percentage={userStats?.questionAnsweredPercentage ? Number(userStats.questionAnsweredPercentage.replace(/%/g, '')) : ''}
                  procentText="Procent przerobienia bazy"
                  icon="🚀"
                />
                <StatisticBox
                  heading=""
                  label="Lekcje"
                  numbers={`${userStats?.lessonsPassed}/${userStats?.lessonsAll}`}
                  numberText="Zaliczone lekcje"
                  percentage={userStats?.lessonsPassedPercentage ? Number(userStats.lessonsPassedPercentage.replace(/%/g, '')) : ''}
                  procentText="Procent zaliczonych lekcji"
                  icon="🚀"
                />
                <StatisticBox
                  heading="Zdawalność egzaminu"
                  label="Egzamin"
                  numbers={`${userStats?.numberOfPassedLast10Exam}/${userStats?.numberOfLastExams}`}
                  numberText="Procent zdawalności"
                  percentage={
                    userStats?.numberOfPassedLast10ExamPercentage ? Number(userStats.numberOfPassedLast10ExamPercentage.replace(/%/g, '')) : ''
                  }
                  procentText="Procent zdawalności"
                  icon="📝"
                />
              </>
            ) : (
              <>
                <div className="user-statistics user-statistics--skeleton">
                  <div className="user-statistics__heading">Status nauki</div>
                  <div className="user-statistics__box">
                    <Skeleton height={432} />
                  </div>
                </div>
                <div className="user-statistics user-statistics--skeleton">
                  <div className="user-statistics__heading">{``}</div>
                  <div className="user-statistics__box">
                    <Skeleton height={432} />
                  </div>
                </div>
                <div className="user-statistics user-statistics--skeleton">
                  <div className="user-statistics__heading">Zdawalność egzaminu</div>
                  <div className="user-statistics__box">
                    <Skeleton height={432} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserStatistics;
