import { connect } from 'react-redux';
import Navigation from './Navigation';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    getAuthorizedUser: () => any;
  };
};
const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: AuthActions.getAuthorizedUser,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
