import { connect } from 'react-redux';
import ActivateAccount from './Main';
import { bindActionCreators } from 'redux';
import { checkToken, activateUser } from 'Store/Actions/Auth';
import * as Types from 'Shared/models/AuthModel';
import { phonePrefixes } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
};

type DispatchedP = {
  actions: {
    checkToken: (token: string) => any;
    activateUser: (data) => any;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      checkToken: checkToken,
      activateUser: activateUser,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
