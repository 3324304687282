import * as Tasks from 'Modules/TaskModule';
import { Task } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Task.GET_SINGLE_SAVE_TASK_INIT,
});

export const success = (data) => ({
  type: Task.GET_SINGLE_SAVE_TASK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Task.GET_SINGLE_SAVE_TASK_FAILURE,
});

const getSingleSaveTask = (page) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await Tasks.Connector.getSingleSaveTask(page);
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getSingleSaveTask;
