enum SVG_TYPE {
  ARROW_RIGHT = 'ARROW_RIGHT',
  CIRCLE = 'CIRCLE',
  CHECKED = 'CHECKED',
  SEND_FILE = 'SEND_FILE',
  BACK_CHEVRON = 'BACK_CHEVRON',
  TIES = 'TIES',
  CLOSE = 'CLOSE',
  CLOSE_BOLD = 'CLOSE_BOLD',
  SEARCH = 'SEARCH',
  PERSON = 'PERSON',
  AWARD = 'AWARD',
  USER_CHECK = 'USER_CHECK',
  RECOMMENDATION = 'RECOMMENDATION',
  HAMBURGER = 'HAMBURGER',
  LOGO_GROUP = 'LOGO_GROUP',
  LOGO = 'LOGO',
  STUDY = 'STUDY',
  EXAM = 'EXAM',
  TROPHY = 'TROPHY',
  EDIT = 'EDIT',
  BIG_LOGO = 'BIG_LOGO',
  ADMIN_ANIMATED_ELEMENT = 'ADMIN_ANIMATED_ELEMENT',
  EYE = 'EYE',
  EYE_OFF = 'EYE_OFF',
  WARNING = 'WARNING',
  LOGO_ROYAL = 'LOGO_ROYAL',
  STATS = 'STATS',
  USERS = 'USERS',
  FOLDER_QUESTION = 'FOLDER_QUESTION',
  CURLY_ARROW = 'CURLY_ARROW',
  INFO = 'INFO',
  CIRCLE_CHECKED = 'CIRCLE_CHECKED',
  DEFAULT_AVATAR = 'DEFAULT_AVATAR',
  BULB = 'BULB',
  CHEVRONE = 'CHEVRONE',
  MOTOR = 'MOTOR',
  CAR = 'CAR',
  DELIVERY_TRUCK = 'DELIVERY_TRUCK',
  BUS = 'BUS',
  CIRCLE_GREEN = 'CIRCLE_GREEN',
  CIRCLE_RED = 'CIRCLE_RED',
  CAMERA='CAMERA',
  VIDEO="VIDEO",
  SHORT_ARROW_RIGHT= "SHORT_ARROW_RIGHT",
  ENTRY="ENTRY",
  STAR="STAR",
  SUPRISE="SUPRISE",
  SAD="SAD",
  EXCLAMATION="EXCLAMATION",
  EXIT="EXIT",
  WHITE_LOGO="WHITE_LOGO",
  REFRESH="REFRESH",
  PADLOCK="PADLOCK",
  SAVE_LABEL="SAVE_LABEL",
  BIN = 'BIN',
  SHERLOCK = 'SHERLOCK',
  ELLIPSIS = 'ELLIPSIS',
  PAGE_404 = 'PAGE_404',
  CLOUD="CLOUD",
  LOGO_WHITE="LOGO_WHITE",
  TRANSACTION_SUCCESS="TRANSACTION_SUCCESS",
  TRANSACTION_FAILED="TRANSACTION_FAILED",
  MEDAL="MEDAL",
  COPY="COPY",
  //flags
  FLAG_POLAND = 'FLAG_PL',
  FLAG_GERMANY = 'FLAG_DE',
  FLAG_FRANCE = 'FLAG_FR',
  FLAG_NORWAY = 'FLAG_NO',
  FLAG_SWEDEN = 'FLAG_SE',
  FLAG_BELGIUM = 'FLAG_BE',
  FLAG_NETHERLANDS = 'FLAG_NL',
}

export default SVG_TYPE;
