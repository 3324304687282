import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import qs from 'qs';


export function getPayments(params): AxiosPromise<any> {
  const queryString = qs.stringify(params);
  return API.get(`payments?${queryString}`,);
}

export function getPaymentsLabel(): AxiosPromise<any> {
  return API.get(`paymentsTypes/label`,);
}
