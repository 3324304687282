import * as Lessons from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Lesson.DELETE_LESSON_INIT,
});

export const success = (data) => ({
  type: Lesson.DELETE_LESSON_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.DELETE_LESSON_FAILURE,
});

const deleteLesson = (uuid) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa usuwanie Lekcji...');
  try {
    const response = await Lessons.Connector.deleteLesson(uuid);
    dispatch(success(response.data));
    toast.update(id, {
      render: 'Pomyślnie usunięto lekcję',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się usunąć lekcji',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default deleteLesson;
