import { Button, Modal, Input } from 'Components';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES } from 'Shared/enums';

type AddLessonType = {
  isOpen: boolean;
  onClose: () => void;
  addLesson: (data) => void;
  departmentUuid: string;
};

const AddLesson = ({ isOpen, onClose, addLesson, departmentUuid }: AddLessonType) => {
  const initialValues = {
    name: '',
    departmentUuid,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .max(150, VALIDATION_MESSAGES.MAX150)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
  });

  const handleOnSubmit = async (values, { resetForm }) => {
    await addLesson(values);
    onClose();
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-mobile--full">
      <h2 className="edit-user-modal__header">Dodawanie lekcji</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={async (values, actions) => await handleOnSubmit(values, actions)}>
        {({ errors, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="add-lesson-form">
              <Field className={''} label="Nazwa" name="name" placeholder="Wpisz nazwę lekcji" required component={Input} errors={errors} />
              <div className="add-lesson-form__buttons">
                <Button label="Anuluj" type="button" onClick={() => onClose()} stroke />
                <Button label="Potwierdź" type="submit" onClick={null} />
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddLesson;
