import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type CompletedLessonsType = {
  department: any;
  isLoading: boolean;
};
const CompletedLessons = ({ department, isLoading }: CompletedLessonsType) => {
  const step = department?.passedLessons;
  const maxStep = department?.lessonsCount;
  const procent = (step / maxStep) * 100;
  return (
    <div className="completed-lessons">
      <div className="completed-lessons__title">
        {!isLoading && (
          <>
            Ukończone lekcje {step}/{maxStep}
          </>
        )}
      </div>
      {isLoading ? (
        <Skeleton height="9px" />
      ) : (
        <>
          <div className="completed-lessons__progress-bar">
            <div className="completed-lessons__progress-bar__item" style={{ width: `${procent}%` }}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompletedLessons;
