import { connect } from 'react-redux';
import SelectCategory from './Main';
import { bindActionCreators } from 'redux';
import getSmsCode from 'Store/Actions/Auth/getSmsCode';
import getPhonePrefixes from 'Store/Actions/Auth/getPhonePrefix';
import { authorizedUser, phonePrefixes } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { User, drivingCategoryType } from 'Shared/models/UserModel';
import { getDrivingCategory, setUserCategory } from 'Store/Actions/User';
import { drivingCategories } from 'Store/Selectors/UserSelector';
import { getAuthorizedUser } from 'Store/Actions/Auth';

type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
  authorizedUser: User;
  drivingCategories: Array<drivingCategoryType>;
};

type DispatchedP = {
  actions: {
    getSmsCode: ({ phoneNumber, phonePrefixUuid }) => (dispatch: any) => Promise<boolean>;
    getPhonePrefixes: () => (dispatch: any) => Promise<boolean>;
    getDrivingCategory: () => (dispatch: any) => Promise<boolean>;
    setUserCategory: (categoryUuid: string, boolean) => (dispatch: any) => Promise<boolean>;
    getAuthorizedUser: () => (dispatch: any) => Promise<any>;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
  authorizedUser: authorizedUser(state),
  drivingCategories: drivingCategories(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getSmsCode: getSmsCode,
      getPhonePrefixes: getPhonePrefixes,
      getDrivingCategory: getDrivingCategory,
      setUserCategory: setUserCategory,
      getAuthorizedUser: getAuthorizedUser,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(SelectCategory);
