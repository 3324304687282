import Button from 'Components/Button';
import { MainType } from './Container';
import { Field, Formik } from 'formik';
import { Input } from 'Components/form';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import SVG from 'Components/SVG';
import { PasswordValidation } from 'Components';

function ChangePasswordPage(props: MainType) {
  const { actions } = props;
  const { recoverPassword, checkToken } = actions;
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const validationSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test('passwords-match', VALIDATION_MESSAGES.PASSWORD_MISMATCH, function (value) {
        return this.parent.password === value;
      }),
    password: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(8, '')
      .test('Capital Letter', '', function (value) {
        return /[A-Z]/.test(value);
      })
      .test('Lowercase Letter', '', function (value) {
        return /[a-z]/.test(value);
      })
      .test('Special Character', '', function (value) {
        return /[!@#$%^&*(),.?":{}|<>]/.test(value);
      })
      .test('Number', '', function (value) {
        return /[0-9]/.test(value);
      })
      .test('cannot-start-with-space', VALIDATION_MESSAGES.CANNOT_START_WITH_SPACE, function (value) {
        return !value?.startsWith(' ');
      }),
  });

  const onSubmit = async (values) => {
    const result = await recoverPassword({ newPassword: values.password, resetToken: token });
    if (result) {
      navigate({ pathname: ROUTES.SUCCESSFUL_PASSWORD_CHANGE });
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    const checkTokenValidity = async () => {
      const result = await checkToken(token);
      if (!result) {
        navigate({ pathname: ROUTES.TOKEN_EXPIRED });
      }
    };
    if (token) {
      checkTokenValidity();
    }
  }, [token]);

  return (
    <div className="login-page ">
      <Formik
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {({ handleSubmit, isValid, errors, setFieldTouched, isSubmitting, values }) => (
          <main className="login-page__body">
            <div className="login-page__content-wrapper">
              <h2 className="login-page__title">Utwórz nowe hasło</h2>
              <p className={`login-page__error ${showError ? 'login-page__error--show' : ''}`}>
                <span>Nowe hasło musi być inne od aktualnego</span>
                <SVG type={SVG_TYPE.WARNING} />
              </p>

              <form onSubmit={handleSubmit} className="login-page__form login-page__form--admin">
                <Field
                  component={Input}
                  required
                  name="password"
                  onChange={() => setShowError(false)}
                  placeholder="Wprowadź hasło"
                  label="Hasło"
                  type="password"
                  errors={errors}
                  className={'login-page__input'}
                />
                <PasswordValidation password={values.password} />
                <Field
                  component={Input}
                  required
                  name="confirmPassword"
                  onChange={() => setShowError(false)}
                  placeholder="Wprowadź hasło"
                  label="Powtórz hasło"
                  type="password"
                  errors={errors}
                  className={'login-page__input'}
                />
              </form>
            </div>
            <div className="login-page__buttons">
              <Button
                label={'Potwierdź'}
                // type="submit"
                onClick={() => {
                  setFieldTouched('confirmPassword', true);
                  setFieldTouched('password', true);
                  isValid && !isSubmitting && onSubmit(values);
                }}
              />
            </div>
          </main>
        )}
      </Formik>
    </div>
  );
}

export default ChangePasswordPage;
