/* eslint-disable react/prop-types */
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { DropFileInput, Input, Select } from 'Components/form';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { phoneNumberValidation } from 'Shared/utils';
import { AdminEdit, User, UserEdit } from 'Shared/models/UserModel';
import { Field, Formik } from 'formik';
import * as Types from 'Shared/models/AuthModel';
import * as Yup from 'yup';
import { useRef } from 'react';

type SingleUserType = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  addAvatar: (uuid, file) => any;
  phonePrefixes: Types.PhonePrefix[];
  editUser: (userData: UserEdit | AdminEdit, uuid: string) => (dispatch: any) => Promise<boolean>;
  getUsersList: () => void;
  addAdmin: (userData: AdminEdit) => (dispatch: any) => Promise<boolean>;
};

function AddUserModal({ isOpen, onClose, phonePrefixes, addAdmin, addAvatar, getUsersList }: SingleUserType): JSX.Element {
  const formRef = useRef(null);
  const phonePrefixesOptions = phonePrefixes.map((item) => {
    return {
      value: item.value,
      label: `+ ${item.phonePrefix}`,
      svgType: SVG_TYPE[`FLAG_${item.country.toUpperCase()}`],
    };
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .test('spaceCout', VALIDATION_MESSAGES.MAX_ONE_SPACE, (value) => {
        const spaceCount = value?.split(' ')?.length - 1;
        return spaceCount <= 1;
      }),
    phoneNumber: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .when('phonePrefixUuid', (phonePrefixUuid, schema) => phoneNumberValidation(phonePrefixUuid, schema)),
    phonePrefixUuid: Yup.mixed().required(VALIDATION_MESSAGES.REQUIRED),
    email: Yup.string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED),
  });

  const initialValues = {
    name: '',
    phonePrefixUuid: phonePrefixesOptions[0]?.value,
    phoneNumber: '',
    email: '',
  };

  const handleOnSubmit = async (values, props) => {
    const response = await addAdmin({ ...values, phoneNumber: values.phoneNumber.toString() });

    if (Array.isArray(response)) {
      response.map((item) => {
        item.field === 'email' && props.setFieldError(item.field, 'Email jest zajęty');
        item.fields === 'phoneNumber, phonePrefix' && props.setFieldError('phoneNumber', 'Nr telefonu jest zajęty');
        item.field === 'name' && props.setFieldError(item.field, 'Nazwa użytkownika jest zajęta');
      });
    } else {
      !!values.avatar && (await addAvatar(response, values.avatar[0]));
      formRef.current.resetForm();
      await getUsersList();
      onClose();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal add-user-modal modal-mobile--full`}>
      <h2 className="edit-user-modal__header">Dodawanie użytkownika </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formRef}
        onSubmit={async (values, props) => handleOnSubmit(values, props)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field
              className={''}
              label="Nazwa użytkownika"
              name="name"
              placeholder="Wpisz nazwę użytkownika"
              required
              component={Input}
              errors={errors}
              treshold={0}
              maxLength={50}
            />
            <Field
              className={''}
              label="Avatar"
              name="avatar"
              placeholder="Przeciągnij i upuść lub"
              required
              component={DropFileInput}
              errors={errors}
              onFileDrop={() => null}
              accept="image/*"
            />
            <div className="edit-user-modal__phone login-page__form--phone">
              <Field
                component={Select}
                label="Telefon"
                name="phonePrefixUuid"
                options={phonePrefixesOptions}
                errors={errors}
                className={'login-page__select'}
                required
                placeholder=" "
                maxLength={9}
              />
              <Field component={Input} required name="phoneNumber" type="number" errors={errors} className={'login-page__input'} />
            </div>
            <Field label="Email" name="email" placeholder="Wpisz email użytkownika" required component={Input} errors={errors} />

            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="edit-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Zapisz" className="edit-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddUserModal;
