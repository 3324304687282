import SVG from 'Components/SVG';

interface ButtonProps {
  label: string;
  onClick: () => void;

  type?: 'button' | 'submit' | 'reset';
  className?: string;
  svg_type?: string;
  iconClass?: string;
  disabled?: boolean;
  iconOnLeft?: boolean;
  stroke?: boolean;
}

function Button({ label, onClick, className, svg_type, iconClass, disabled, iconOnLeft, stroke, type = 'button' }: ButtonProps) {
  return (
    <button
      type={type}
      className={`btn ${className ? className : ''} ${disabled ? 'btn--disabled' : ''} ${stroke ? 'btn--stroke' : ''}`}
      onClick={onClick}>
      {iconOnLeft && svg_type ? <SVG type={svg_type} className={iconClass} /> : null}
      {label}
      {!iconOnLeft && svg_type ? <SVG type={svg_type} className={iconClass} /> : null}
    </button>
  );
}

export default Button;
