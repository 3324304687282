import SVG from 'Components/SVG';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';

type CategoryBoxType = {
  data: any;
};

function CategoryBox({ data }: CategoryBoxType) {
  const { lessonsCount, passedLessons, name, uuid } = data;

  const isFinishedDepartment = lessonsCount === passedLessons;
  const navigate = useNavigate();
  const step = passedLessons;
  const maxStep = lessonsCount;
  const procent = (step / maxStep) * 100;

  return (
    <div className={`category-box ${isFinishedDepartment ? 'category-box--finished' : ''}`}>
      {isFinishedDepartment && <div className="category-box--finished-medal">🥇</div>}
      <div className="category-box__top">
        <img className="category-box__image" src={data.file[0].url} alt="" />
        <div className="category-box__title">
          <h3>{name}</h3>
        </div>
      </div>
      <div className="category-box__bottom">
        <div className="category-box__bottom--progress">
          <ul className="category-box__progress-bar">
            <div className="category-box__progress-bar--item" style={{ width: `${procent}%` }}></div>
          </ul>
          <div className="category-box__button" onClick={() => navigate({ pathname: ROUTES.DEPARTMENTS_PAGE.replace('uuid', uuid) })}>
            <SVG type={SVG_TYPE.BACK_CHEVRON} />
          </div>
        </div>
        <p className="category-box__steps-title">
          Zaliczone lekcje {step}/{maxStep}
        </p>
      </div>
      <div className="category-box__onclick" onClick={() => navigate({ pathname: ROUTES.DEPARTMENTS_PAGE.replace('uuid', uuid) })} />
    </div>
  );
}

export default CategoryBox;
