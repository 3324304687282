import SVG from 'Components/SVG';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';

type SingleQuestionType = {
  question: any;
  index: number;
  setEdittedQuestion: any;
};

function SingleQuestion({ question, index, setEdittedQuestion }: SingleQuestionType): JSX.Element {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 1024;
  return (
    <tr className={`questions-list__tr`} key={`page-${index}`}>
      <td className="questions-list__td">{question.name}</td>
      <td className="questions-list__td">{question.type}</td>
      <td className="questions-list__td">{question.categories}</td>
      <td className="questions-list__td">
        <p className="questions-list__td--breake">{question.question}</p>
      </td>
      <td className="questions-list__td">
        <p className="questions-list__td--breake">{question.explanation}</p>
      </td>
      <div onClick={() => navigate({ pathname: ROUTES.QUESTION.replace('uuid', question.uuid) })} className="questions-list--redirector"></div>
      <td className="questions-list__td questions-list__menu">
        {question?.isDifficult && <SVG className="questions-list__stats" type={SVG_TYPE.BULB} />}
        {!isMobile && (
          <span onClick={() => setEdittedQuestion(question)}>
            <SVG type={SVG_TYPE.EDIT} />
          </span>
        )}
      </td>
    </tr>
  );
}

export default SingleQuestion;
