import * as Types from 'Shared/models/AuthModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';

export type State = Types.AuthStore;

export const initialState: State = {
  token: null,
  authorizedUser: 'init',
  code: null,
  phonePrefixes: [],
  cities: [],
};

export function authReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.Auth.LOG_IN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      localStorage.removeItem('EXAM_TIME_TO_END');
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.LOG_OUT_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(false));
      localStorage.removeItem('CHANGE_CATEGORY');
      localStorage.removeItem('EXAM_TIME_TO_END');
      localStorage.removeItem('DEMO_TO_STANDARD');
      localStorage.removeItem('adminLogin');
      return {
        ...state,
        authorizedUser: 0,
      };
    }
    case ActionTypes.Auth.LOG_OUT_FAILURE: {
      localStorage.setItem('isUserLogged', JSON.stringify(false));
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_FAILURE: {
      return {
        ...state,
        authorizedUser: null,
        devicesError: action.payload.devicesError,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: action.payload.authorizedUserData.user,
      };
    }
    case ActionTypes.Auth.SET_REFRESH_TOKEN: {
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.GET_REFRESH_TOKEN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case ActionTypes.Auth.GET_SMS_CODE_SUCCESS: {
      return {
        ...state,
        code: action.payload.code,
      };
    }
    case ActionTypes.Auth.GET_PHONE_PREFIXES_SUCCESS: {
      return {
        ...state,
        phonePrefixes: action.payload.phonePrefixes,
      };
    }
    case ActionTypes.Auth.SEND_PASSWORD_RESET_LINK_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.SEND_PASSWORD_RESET_LINK_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.CHANGE_CURRENT_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.CHANGE_CURRENT_USER_PASSWORD_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload.cities,
      };
    }
    case ActionTypes.Auth.GET_CITIES_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.CHECK_TOKEN_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.LOGIN_AS_DEMO_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case ActionTypes.Auth.LOGIN_AS_DEMO_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.CREATE_DEMO_USER_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.CREATE_DEMO_USER_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.DELETE_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: 'init',
        token: null,
      };
    }
    case ActionTypes.Auth.DELETE_CURRENT_USER_FAILURE: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.EDIT_AVATAR_SUCCESS: {
      const avatar = action.payload.data;
      const user = state.authorizedUser
      const modiefiedUser = {...user, avatar}
      return {
        ...state,
        authorizedUser: modiefiedUser,
      };
    }

    default: {
      return state;
    }
  }
}
