import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FieldProps } from 'formik';

interface InputProps {
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  secondValue?: string;
  maxLength?: number;
  children?: any;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function Checkbox({ className = '', name, required, form, errors = {}, field, children }: InputProps & FieldProps) {
  return (
    <div className={`input__wrapper checkbox ${className}`}>
      <label className="input__label" htmlFor={name}>
        <input {...field} name={field.name} className="checkbox-input" checked={field.value} id={name} type="checkbox" />
        <span className="checkbox__checkmark">
          <SVG type={SVG_TYPE.CHECKED} />
        </span>
        {children} {required ? null : <p className="input__optional">(opcjonalne)</p>}
      </label>

      {errors && form.touched && <div className="checkbox__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default Checkbox;
