import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { Input } from 'Components/form';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

type changePasswordModalType = {
  isOpen: boolean;
  onClose: () => void;
  changeUserEmail: (email: string) => (dispatch: any) => Promise<boolean>;
  currentEmail: string;
  userName: string;
};

function ChangeUserEmail({ isOpen, onClose, changeUserEmail, currentEmail, userName }: changePasswordModalType): JSX.Element {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
  });

  const initialValues = {
    email: currentEmail,
    name: userName,
  };

  const handleOnSubmit = (values) => {
    changeUserEmail(values);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-user-modal modal-mobile--full`}>
      <h2 className="edit-user-modal__header">Zmiana nazwy użytkownika</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values) => handleOnSubmit(values)}>
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="edit-user-modal__form">
            <Field component={Input} required name="email" placeholder="Wpisz Email" label="Email" errors={errors} />
            <div className="edit-user-modal__buttons">
              <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default ChangeUserEmail;
