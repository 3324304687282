import { Route, Routes } from 'react-router-dom';
import HomePage from 'Screens/HomePage/Container';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ROUTES, USER_ROLES } from 'Shared/enums';
import LoginPage from 'Screens/LoginScreens/screens/LoginPage/Container';
import EnterSmsCodePage from 'Screens/LoginScreens/screens/EnterSmsCodePage/Container';
import Dashboard from 'Screens/Dashboard/Container';
import LearningPage from 'Screens/LearningPage/Container';
import SaveTaskPage from 'Screens/SaveTaskPage/Container';
import StatisticsPage from 'Screens/StatisticsPage/Container';
import AllCitiesPage from 'Screens/AllCitiesPage/Container';
import ExamPage from 'Screens/ExamPage/Container';
import ExaminationPage from 'Screens/ExaminationPage/Container';
import DrivingTestSummaryPage from 'Screens/DrivingTestSummaryPage/Container';
import QuestionsDatabasePage from 'Screens/QuestionsDatabasePage/Container';
import Question from 'Screens/QuestionPage/Container';
import SvgView from 'Screens/SvgView/Main';
import MyProfile from 'Screens/MyProfile/Container';
import DepartmentsPage from 'Screens/DepartmentsPage/Container';
import LessonPage from 'Screens/LessonPage/Container';
import MyProfilePacket from 'Screens/MyProfilePacket/Container';
import RecommendedToOthers from 'Screens/RecommendedToOthers/Container';
import Contact from 'Screens/Contact/Container';
import GamificationPage from 'Screens/GamificationPage/Container';
import Page404 from 'Screens/Page404/Container';
import TransactionSuccess from 'Screens/TransactionSuccess/Container';
import TransactionFailed from 'Screens/TransactionFailed/Container';
import Users from 'Screens/Users/Container';
import UserStatistics from 'Screens/UserStatistics/Container';
import AdminLoginScreens from 'Screens/AdminLoginScreens/Main';
import {
  LoginAdminPage,
  SetPasswordPage,
  ChangePasswordPage,
  ResetPasswordPage,
  SuccessfulPasswordResetLinkSendPage,
  SuccessfulPasswordChangePage,
  TokenExpired,
  ActivateAccount,
} from 'Screens/AdminLoginScreens/screens';
import { Navigation } from 'Components';
import LoginScreens from 'Screens/LoginScreens/Main';
import SelectCategory from 'Screens/LoginScreens/screens/SelectCategory/Container';
import ProtectedRoute from './protectedRoute';
import AdminLearningPage from 'Screens/AdminLearningPage/Container';
import AdminDepartmentPage from 'Screens/AdminDepartmentPage/Container';
import { AnimatePresence } from 'framer-motion';
import { AnimatedRoute, ScrollToTop } from 'Shared/utils';

export const Router = () => {
  return (
    <AnimatePresence>
      <ScrollToTop>
        <div className="router">
          <div className="slide slide-routes">
            <Routes location={location} key={location.pathname}>
              <Route
                path={ROUTES.HOME_PAGE}
                element={
                  <AnimatedRoute>
                    <HomePage />
                  </AnimatedRoute>
                }
              />
              <Route
                path={ROUTES.EXAMINATION}
                element={
                  <AnimatedRoute>
                    <ExaminationPage />
                  </AnimatedRoute>
                }
              />
              <Route
                path={ROUTES.DRIVING_TEST_SUMMARY}
                element={
                  <AnimatedRoute>
                    <DrivingTestSummaryPage />
                  </AnimatedRoute>
                }
              />
              <Route
                path={ROUTES.SVG_VIEW}
                element={
                  <AnimatedRoute>
                    <SvgView />
                  </AnimatedRoute>
                }
              />
              <Route path={ROUTES.ADMIN_LOGIN_SCREENS} element={<AdminLoginScreens />}>
                <Route path={ROUTES.ADMIN_LOGIN_PAGE} element={<LoginAdminPage />} />
                <Route
                  path={ROUTES.SET_PASSWORD_PAGE}
                  element={
                    <AnimatedRoute>
                      <SetPasswordPage />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.CHANGE_PASSWORD_PAGE}
                  element={
                    <AnimatedRoute>
                      <ChangePasswordPage />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.RESET_PASSWORD_PAGE}
                  element={
                    <AnimatedRoute>
                      <ResetPasswordPage />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.SUCCESSFUL_PASSWORD_RESET_LINK_SEND}
                  element={
                    <AnimatedRoute>
                      <SuccessfulPasswordResetLinkSendPage />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.SUCCESSFUL_PASSWORD_CHANGE}
                  element={
                    <AnimatedRoute>
                      <SuccessfulPasswordChangePage />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.TOKEN_EXPIRED}
                  element={
                    <AnimatedRoute>
                      <TokenExpired />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.CHECK_TOKEN}
                  element={
                    <AnimatedRoute>
                      <ActivateAccount />
                    </AnimatedRoute>
                  }
                />
              </Route>

              <Route path={ROUTES.DASHBOARD} element={<Dashboard />}>
                <Route
                  path={ROUTES.STATISTICS}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <StatisticsPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.ALL_CITIES}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <AllCitiesPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.LEARNING}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <LearningPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.SAVE_TASK}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <SaveTaskPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.STATS}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <LearningPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.EXAM}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <ExamPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.GAMIFICATION}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <GamificationPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.MYPROFILE}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={'ALL'} redirectPath={ROUTES.LOGIN_PAGE}>
                        <MyProfile />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.MYPROFILE_PACKET}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.MYPROFILE_PACKET}>
                        <MyProfilePacket />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.DEPARTMENTS_PAGE}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <DepartmentsPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.LESSON}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <LessonPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.RECOMMENDED_OTHERS}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <RecommendedToOthers />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />

                <Route
                  path={ROUTES.CONTACT}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.CLIENT} redirectPath={ROUTES.LOGIN_PAGE}>
                        <Contact />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />

                <Route
                  path={ROUTES.USERS}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <Users />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.USER_STATISTICS}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <UserStatistics />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_LEARNING}
                  element={
                    <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                      <AdminLearningPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_DEPARTMENT}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <AdminDepartmentPage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.QUESTIONS_DATABASE}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <QuestionsDatabasePage />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path={ROUTES.QUESTION}
                  element={
                    <AnimatedRoute>
                      <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.ADMIN_LOGIN_PAGE}>
                        <Question />
                      </ProtectedRoute>
                    </AnimatedRoute>
                  }
                />
              </Route>
              <Route path={ROUTES.LOGIN_SCREENS} element={<LoginScreens />}>
                <Route path={ROUTES.LOGIN_PAGE} element={<LoginPage />} />
                <Route path={ROUTES.ENTER_SMS_CODE_PAGE} element={<EnterSmsCodePage />} />
                <Route path={ROUTES.SELECT_CATEGORY} element={<SelectCategory />} />
              </Route>
              <Route
                path={ROUTES.TRANSACTION_SUCCESS}
                element={
                  <AnimatedRoute>
                    <TransactionSuccess />
                  </AnimatedRoute>
                }
              />
              <Route
                path={ROUTES.TRANSACTION_FAILED}
                element={
                  <AnimatedRoute>
                    <TransactionFailed />
                  </AnimatedRoute>
                }
              />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </div>
          <Navigation />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </ScrollToTop>
    </AnimatePresence>
  );
};
