import { SVG_TYPE, USER_ROLES, USER_TYPE, ROUTES } from 'Shared/enums';
import { SVG } from 'Components';
import { useNavigate } from 'react-router';

type CallToActionType = {
  user: any;
};

const CallToAction = ({ user }: CallToActionType) => {
  const isDemo = user.role === USER_ROLES.CLIENT && user.type === USER_TYPE.DEMO;
  const isStandard = user.role === USER_ROLES.CLIENT && user.type === USER_TYPE.STANDARD;
  const isPremiumOrImpuls = user.role === USER_ROLES.CLIENT && (user.type === USER_TYPE.PREMIUM || user.type === USER_TYPE.STUDENT_IMPULS);
  const navigate = useNavigate();

  return (
    <>
      {!isPremiumOrImpuls && (
        <div className="exam-call-to-action">
          <SVG className="star" type={SVG_TYPE.STAR} />
          {isDemo && (
            <div className="exam-call-to-action__paragraph">
              Chcesz mieć dostęp do większej ilości prób egzaminu?{' '}
              <strong
                onClick={() => {
                  sessionStorage.setItem('DEMO_TO_STANDARD', 'true');
                  navigate({ pathname: ROUTES.LOGIN_PAGE });
                }}>
                {' '}
                Załóż konto <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
              </strong>
            </div>
          )}
          {isStandard && (
            <div className="exam-call-to-action__paragraph">
              Chcesz mieć nieograniczony dostęp do egzaminów oraz bardziej szczegółowe statystyki?{' '}
              <strong
                onClick={() => {
                  navigate({ pathname: ROUTES.MYPROFILE_PACKET });
                }}>
                Wykup pakiet PREMIUM <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
              </strong>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CallToAction;
