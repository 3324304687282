import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

type BarType = {
  title: string;
  subTitle?: string;
  onClick?: () => void;
};

function Bar({ title, subTitle, onClick }: BarType) {
  return (
    <div className={`bar ${onClick ? 'bar--onClick' : ''}`} onClick={onClick}>
      <div>
        <h4 className="bar__title">{title}</h4>
        {subTitle ? <p className="bar__sub-title">{subTitle}</p> : null}
      </div>
      {onClick && <SVG type={SVG_TYPE.CHEVRONE} className="svg--chevron" />}
    </div>
  );
}

export default Bar;
