import { useEffect, useState } from 'react';
import { Departments, Statistic, SaveTasks } from './components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { SVG } from 'Components';
import { MainType } from './Container';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

const LEARNING_TYPES = {
  DEPARTMENTS: 'departments',
  STATISTICS: 'statistics',
  SAVE_QUESTION: 'save_question',
};

function LearningPage(props: MainType) {
  const { DEPARTMENTS, STATISTICS, SAVE_QUESTION } = LEARNING_TYPES;
  const [selectedType, setSelectedType] = useState('');
  const { actions, departments, authorizedUser, learningInfo, saveTasks } = props;
  const { getClientDepartments, getSaveTasks, getClientLearningInfo, saveTask } = actions;
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const lastElement = parts.pop();
  const navigate = useNavigate();
  const isDepartmentPage = selectedType === DEPARTMENTS;
  const isStatisticPage = selectedType === STATISTICS;
  const isSaveTasksPage = selectedType === SAVE_QUESTION;

  useEffect(() => {
    getClientDepartments({ page: 1 });
  }, []);

  useEffect(() => {
    isStatisticPage && getClientLearningInfo();
  }, [isStatisticPage]);

  useEffect(() => {
    setSelectedType(lastElement);
  }, [pathname]);

  // console.log(saveTasks?.data);

  return (
    <>
      <header className="dashboard__header learning-page__header">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

        <div className="dashboard__container dashboard__container--learning">
          <h1 className="dashboard__title">Nauka</h1>
          <div className="dashboard__categories learning-page__categories learning-page__categories-user">
            <p
              className={`dashboard__category learning-page__categories-item ${isDepartmentPage ? 'dashboard__category--active' : ''}`}
              onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: LEARNING_TYPES.DEPARTMENTS }) })}>
              Działy
            </p>
            <p
              className={`dashboard__category learning-page__categories-item ${isStatisticPage ? 'dashboard__category--active' : ''}`}
              onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: LEARNING_TYPES.STATISTICS }) })}>
              Statystyki
            </p>
            <p
              className={`dashboard__category learning-page__categories-item ${isSaveTasksPage ? 'dashboard__category--active' : ''}`}
              onClick={() => navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: LEARNING_TYPES.SAVE_QUESTION }) })}>
              Zapisane pytania
            </p>
          </div>
        </div>
      </header>
      <div className="dashboard__content learning-page">
        <div className="dashboard__container learning-page__container">
          <div className={` ${isStatisticPage ? 'learning-page--statistics' : ''}`}>
            {isDepartmentPage && <Departments departments={departments} />}
            {isStatisticPage && <Statistic user={authorizedUser} learningInfo={learningInfo} />}
            {isSaveTasksPage && (
              <SaveTasks deleteSaveQuestion={saveTask} getSaveTasks={getSaveTasks} data={saveTasks?.data} pagination={saveTasks?.meta} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LearningPage;
