import { useEffect } from 'react';
import { SVG, Modal } from 'Components';
import { SVG_TYPE } from 'Shared/enums';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';
import { ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';

type AnswersModalType = {
  isOpen: boolean;
  onClose: () => void;
  setActiveQuestion: (question) => void;
  activeQuestion: any;
  result: any;
  impulsOrPremium: boolean;
  userRole: string;
  startIndex: any;
  setStartIndex: (numb) => void;
  isClickBox: any;
  standard: any;
  page: any;
  setPage: (numb) => void;
  setIsClickBox: (boolean) => void;
};

const QUESTION_TYPE = {
  PODSTAWOWY: 'Pytanie podstawowe',
  SPECJALISTYCZNY: 'Pytanie specjalistyczne',
};

const AnswersModal = ({
  isOpen,
  startIndex,
  setStartIndex,
  onClose,
  result,
  impulsOrPremium,
  activeQuestion,
  setActiveQuestion,
  isClickBox,
  standard,
  page,
  setPage,
  setIsClickBox,
}: AnswersModalType) => {
  const { question } = result;
  const totalPages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  const { explanation, answer, type, pointsNumber, filePath, youtubeLink, answerA, answerB, answerC, propertyAnswer, file } = activeQuestion || {};
  const noExplanationInfo = !explanation && !file;
  const smallScreen = window.innerWidth < 765;
  const navigate = useNavigate();

  const optionsYoutube = {
    height: '100%',
    width: '100%',
  };

  const qetAnswers = () => {
    if ('PODSTAWOWY' === type) {
      return (
        <div className="singleAnswer--basic">
          <div
            className={`singleAnswer__box singleAnswer__box--simple ${
              answer === 'T' ? 'singleAnswer__box--answer' : 'singleAnswer__box--wrong-answer'
            }`}>
            TAK
            {!answer ? (
              propertyAnswer == 'T' ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : null
            ) : answer === 'T' ? (
              answer === propertyAnswer ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : (
                <SVG type={SVG_TYPE.CIRCLE_RED} />
              )
            ) : answer === 'N' ? (
              answer === propertyAnswer ? null : (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              )
            ) : null}
          </div>
          <div
            className={`singleAnswer__box singleAnswer__box--simple ${
              answer === 'N' ? 'singleAnswer__box--answer' : 'singleAnswer__box--wrong-answer'
            }`}>
            NIE
            {!answer ? (
              propertyAnswer == 'N' ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : null
            ) : answer === 'N' ? (
              answer === propertyAnswer ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : (
                <SVG type={SVG_TYPE.CIRCLE_RED} />
              )
            ) : answer === 'T' ? (
              answer === propertyAnswer ? null : (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              )
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="singleAnswer--special">
          <div className="singleAnswer-special--item">
            <div className={`singleAnswer__box singleAnswer__box--special ${answer === 'A' ? 'singleAnswer__box--special--checked' : ''} `}>A</div>
            {answerA}
            {answer === 'A' ? (
              propertyAnswer === 'A' ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : (
                <SVG type={SVG_TYPE.CIRCLE_RED} />
              )
            ) : propertyAnswer === 'A' ? (
              <SVG type={SVG_TYPE.CIRCLE_GREEN} />
            ) : null}
          </div>
          <div className="singleAnswer-special--item">
            <div className={`singleAnswer__box singleAnswer__box--special ${answer === 'B' ? ` singleAnswer__box--special--checked` : ''}`}>B</div>
            {answerB}
            {answer === 'B' ? (
              propertyAnswer === 'B' ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : (
                <SVG type={SVG_TYPE.CIRCLE_RED} />
              )
            ) : propertyAnswer === 'B' ? (
              <SVG type={SVG_TYPE.CIRCLE_GREEN} />
            ) : null}
          </div>
          <div className="singleAnswer-special--item">
            <div className={`singleAnswer__box singleAnswer__box--special ${answer === 'C' ? 'singleAnswer__box--special--checked' : ''}`}>C</div>
            {answerC}
            {answer === 'C' ? (
              propertyAnswer === 'C' ? (
                <SVG type={SVG_TYPE.CIRCLE_GREEN} />
              ) : (
                <SVG type={SVG_TYPE.CIRCLE_RED} />
              )
            ) : propertyAnswer === 'C' ? (
              <SVG type={SVG_TYPE.CIRCLE_GREEN} />
            ) : null}
          </div>
        </div>
      );
    }
  };

  const handleNextClick = () => {
    setStartIndex(startIndex + 1);
    if (!(startIndex == 0 || startIndex <= 8) && !smallScreen) {
      if (page < 13) {
        setPage(page + 1);
      }
    }
  };

  const handlePrevious = () => {
    setStartIndex(startIndex - 1);
    if ((startIndex == 0 || startIndex <= 22) && !smallScreen) {
      if (page > 0) {
        setPage(page - 1);
      }
    }
  };
  useEffect(() => {
    activeQuestion && setActiveQuestion(question[startIndex]);

    if (smallScreen) {
      if (startIndex == 0 || startIndex <= 5) {
        setPage(0);
      } else if (startIndex == 6 || startIndex <= 11) {
        setPage(6);
      } else if (startIndex == 12 || startIndex <= 17) {
        setPage(12);
      } else if (startIndex == 18 || startIndex <= 23) {
        setPage(18);
      } else if (startIndex == 23 || startIndex <= 28) {
        setPage(24);
      } else if (startIndex == 28 || startIndex <= 32) {
        setPage(27);
      }
    } else {
      if (page === 0 && startIndex <= 9) {
        setPage(0);
      } else if (page === 0 && startIndex == 10) {
        setPage(1);
      } else if (page === 0 && startIndex == 11) {
        setPage(2);
      } else if (page === 0 && startIndex == 12) {
        setPage(3);
      } else if (page === 0 && startIndex == 13) {
        setPage(4);
      } else if (page === 0 && startIndex == 14) {
        setPage(5);
      } else if (page === 0 && startIndex == 15) {
        setPage(6);
      } else if (page === 0 && startIndex == 16) {
        setPage(7);
      } else if (page === 0 && startIndex == 17) {
        setPage(8);
      } else if (page === 0 && startIndex == 18) {
        setPage(9);
      } else if (page === 0 && startIndex == 19) {
        setPage(10);
      } else if (page === 0 && startIndex == 20) {
        setPage(11);
      } else if (page === 0 && startIndex == 21) {
        setPage(12);
      } else if (page === 0 && startIndex == 22) {
        setPage(13);
      } else if (page === 0 && startIndex >= 23) {
        setPage(13);
      }
    }
  }, [startIndex]);

  const getQuestionMedia = (file) => {
    const filename = file.substring(file.lastIndexOf('/') + 1);
    if (filename.endsWith('.mp4')) {
      return <ReactPlayer fullscreen={false} playsinline={true} url={file} controls={true} width="100%" height="100%" />;
    }
    if (filename.endsWith('.webp')) {
      return <img className="question__img" src={file} alt="" />;
    }
  };

  function getYouTubeVideoId(url) {
    if (url && url.indexOf('youtube.com') !== -1) {
      const urlParams = new URLSearchParams(new URL(url).search);
      const videoId = urlParams.get('v');
      return videoId;
    } else if (url.indexOf('youtu.be') !== -1) {
      const videoId = url.split('/').pop();
      return videoId;
    } else {
      return null;
    }
  }

  const handleGoBack = () => {
    if (isClickBox) {
      setPage(0);
      setActiveQuestion(undefined);
      setIsClickBox(false);
      onClose();
    } else {
      setPage(0);
      setActiveQuestion(undefined);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`modal--full answersModal`}>
      {activeQuestion ? (
        <div className="singleAnswer">
          <div className="singleAnswer__left">
            <div className="singleAnswer__top">
              <div className="singleAnswer__goBack" onClick={() => handleGoBack()}>
                <span>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                </span>
                Wróć
              </div>
              <div>{pointsNumber} pkt</div>
              <div>{startIndex + 1} z 32</div>
              <div>{QUESTION_TYPE[type]}</div>
            </div>
            <div className="singleAnswer__pagination">
              <div
                className={`singleAnswer__pagination-item singleAnswer__pagination-item__back ${
                  startIndex === 0 ? 'singleAnswer__pagination-item--disabled' : ''
                }`}
                onClick={() => handlePrevious()}>
                <SVG type={SVG_TYPE.BACK_CHEVRON} />
              </div>
              {totalPages.slice(page, smallScreen ? page + 6 : page + 19).map((item) => {
                const activeItem = item === startIndex;
                return (
                  <>
                    <div
                      key={item}
                      className={`singleAnswer__pagination-item ${
                        result.question[item].isAnsweredCorrectly ? 'singleAnswer__pagination-item--corectly' : 'singleAnswer__pagination-item--wrong'
                      }${activeItem ? ' singleAnswer__pagination-item--active' : ''}`}
                      onClick={() => {
                        setStartIndex(item);
                      }}>
                      {item + 1}
                    </div>
                  </>
                );
              })}
              <div
                className={`singleAnswer__pagination-item singleAnswer__pagination-item__next ${
                  startIndex === 31 ? 'singleAnswer__pagination-item--disabled' : ''
                }`}
                onClick={() => handleNextClick()}>
                <SVG type={SVG_TYPE.BACK_CHEVRON} />
              </div>
            </div>
            <div className="singleAnswer__media">{getQuestionMedia(filePath)}</div>
            <div className="singleAnswer__question">{activeQuestion.question}</div>
            <div className="singleAnswer__answers">{qetAnswers()}</div>
          </div>
          {!noExplanationInfo && (
            <div className="singleAnswer__right">
              <div className="singleAnswer__right-heading">Wyjaśnienie</div>
              <div className="singleAnswer__right--explanation">{explanation}</div>
              <div className="singleAnswer__right--photo">{file && <img src={file.url} />}</div>
              <div className="singleAnswer__right--youtube">
                {youtubeLink && <YouTube videoId={getYouTubeVideoId(youtubeLink)} opts={optionsYoutube} />}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="answers-list">
          {standard && (
            <div className="sidebar-invitation sidebar-invitation--answersModal">
              <>
                <SVG type={SVG_TYPE.STAR} className="sidebar-invitation__icon sidebar-invitation__icon--star" />
                <div className="sidebar-invitation__paragraph">
                  Jeśli chcesz mieć możliwość podglądu odpowiedzi i wyjaśnienia oraz nieograniczoną ilość prób egzaminu — wykup pakiet PREMIUM.
                </div>
                <div className="sidebar-invitation__call-to-action" onClick={() => navigate({ pathname: ROUTES.MYPROFILE_PACKET })}>
                  Dowiedz się więcej <SVG type={SVG_TYPE.SHORT_ARROW_RIGHT} />
                </div>
              </>
            </div>
          )}
          <div className="answersModal__content">
            <div className="answersModal__heading">
              <span onClick={onClose}>
                <SVG type={SVG_TYPE.BACK_CHEVRON} />
              </span>
              Podgląd odpowiedzi
            </div>
            {isOpen && (
              <div className="answersModal__list">
                {question.map((item, idx) => {
                  return (
                    <div
                      key={item.uuid}
                      onClick={() => {
                        if (impulsOrPremium) {
                          setActiveQuestion(item);
                          setStartIndex(idx);
                        }
                      }}
                      className={`answersModal-item ${impulsOrPremium ? 'answersModal-item--premium' : ''}`}>
                      <div className="answersModal-item--left">
                        <div className="answersModal-item__question">{item.question}</div>
                        <div className="answersModal-item__others">
                          <div>{item.id}</div>
                          <div>{item.pointsNumber} pkt.</div>
                          <div>{QUESTION_TYPE[item.type]}</div>
                        </div>
                      </div>
                      <div className="answersModal-item--right">
                        {item.isAnsweredCorrectly ? <SVG type={SVG_TYPE.CIRCLE_GREEN} /> : <SVG type={SVG_TYPE.CIRCLE_RED} />}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AnswersModal;
