import { useState, useEffect } from 'react';
import { Pagination } from 'Components';
import { SingleTask } from 'Screens/LearningPage/components';
import { SaveTasksEmptyState } from 'Screens/LearningPage/components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type SaveTasksType = {
  data: any;
  pagination: any;
  getSaveTasks: any;
  deleteSaveQuestion: any;
};

const SaveTasks = ({ data, pagination, getSaveTasks, deleteSaveQuestion }: SaveTasksType) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const emptySkeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 500);
  }, []);

  const isMobile = window.innerWidth < 1024;

  useEffect(() => {
    getSaveTasks(currentPage);
  }, [currentPage]);

  return (
    <>
      {data?.length === 0 ? (
        <SaveTasksEmptyState />
      ) : (
        <>
          {!isMobile ? (
            <>
              {!isLoading ? (
                emptySkeleton.map((item) => (
                  <div key={item} className="single-task single-task--skeleton">
                    <Skeleton height="57px" />
                  </div>
                ))
              ) : (
                <table className="save-tasks__table">
                  <thead className="save-tasks__thead">
                    <tr className="save-tasks__tr">
                      <th className="save-tasks__th save-tasks__th--name">Nazwa</th>
                      <th className="save-tasks__th save-tasks__th--type">Typ</th>
                      <th className="save-tasks__th save-tasks__th--points">Punkty</th>
                      <th className="save-tasks__th save-tasks__th--question">Treść pytania</th>
                    </tr>
                  </thead>
                  <div className="save-tasks--margin"></div>

                  <tbody className="save-tasks__tbody">
                    {data &&
                      data.map((item) => {
                        return (
                          <SingleTask
                            getSaveTasks={getSaveTasks}
                            totalTask={pagination?.total}
                            data={item}
                            deleteSaveQuestion={deleteSaveQuestion}
                            key={item.uuid}
                          />
                        );
                      })}
                  </tbody>
                </table>
              )}
            </>
          ) : (
            <>
              {data &&
                data.map((item) => {
                  return (
                    <SingleTask
                      getSaveTasks={getSaveTasks}
                      totalTask={pagination?.total}
                      data={item}
                      deleteSaveQuestion={deleteSaveQuestion}
                      key={item.uuid}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
      {pagination?.last_page > 1 && (
        <Pagination
          className="order-list__pagination users-list--pagination"
          totalPages={pagination?.last_page}
          currentPage={pagination?.current_page}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

export default SaveTasks;
