import * as AuthModule from 'Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = () => ({
  type: Auth.SEND_PASSWORD_RESET_LINK_SUCCESS,
});

const failure = () => ({
  type: Auth.SEND_PASSWORD_RESET_LINK_SUCCESS,
});

const sendPasswordResetLink =
  ({ email }) =>
  async (dispatch) => {
    const id = toast.loading('Trwa wysyłanie linku...');
    try {
      await AuthModule.Connector.sendPasswordResetLink({ email });
      dispatch(success());
      toast.update(id, {
        render: 'Wysłano link do resetu hasła',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
      });

      return true;
    } catch (err) {
      dispatch(failure());
      toast.update(id, {
        render: 'Nie udało się wysłać linku do resetu hasła',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });

      return false;
    }
  };

export default sendPasswordResetLink;
