import { Button } from 'Components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PieChart, Pie, Cell } from 'recharts';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type TopTownsType = {
  popularCities: any;
  allUserCities: any;
};

const TopTowns = ({ popularCities, allUserCities }: TopTownsType) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 1024;
  const showStatistics = data.length === 6 && popularCities;

  useEffect(() => {
    const modified = [];
    popularCities &&
      popularCities.length > 0 &&
      popularCities.slice(0, 6).map(({ city, userPercentage, uuid, usersCount }) => {
        modified.push({ uuid, name: city, value: Number(userPercentage.replace(/%/g, '')), users: usersCount });
      });
    setData(modified);
  }, [popularCities]);

  const COLORS = [
    'rgba(109, 104, 253, 1)',
    'rgba(176, 173, 254, 1)',
    'rgba(99, 103, 166, 1)',
    'rgba(191, 143, 38, 1)',
    'rgba(242, 188, 70, 1)',
    'rgba(42, 38, 153, 1)',
  ];

  return (
    <>
      {showStatistics ? (
        <>
          <div className="top-towns">
            <div className="top-towns__top">
              <span>Najpopularniejsze miasta</span>
              <Button label="Więcej" stroke svg_type={SVG_TYPE.SHORT_ARROW_RIGHT} onClick={() => navigate({ pathname: ROUTES.ALL_CITIES })} />
            </div>
            <div className="top-towns__bottom">
              <div className="top-towns__circle">
                {popularCities && popularCities.length > 0 && data.length === 6 && (
                  <PieChart width={160} height={160}>
                    <Pie
                      data={data}
                      cx={75}
                      cy={75}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={0}
                      dataKey="value"
                      startAngle={90}
                      endAngle={-360}>
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                )}
                <div className="top-towns__circle-center">
                  <span>Razem</span>
                  <p>{allUserCities?.meta?.total} miast</p>
                </div>
              </div>
              <div className="top-towns__list">
                {popularCities &&
                  popularCities.length > 0 &&
                  data.length === 6 &&
                  data.map(({ value, name, uuid, users }) => (
                    <div key={uuid} className="top-towns__list-item">
                      <div className="top-towns__list-item--left">
                        <div className="top-towns__list-item-color"></div>
                        <div className="top-towns__list-item-town">{name}</div>
                      </div>
                      <div className="top-towns__list-item--right">
                        <div className="top-towns__list-item-numbers">{users}</div>
                        <div className="top-towns__list-item-percentage">{value}%</div>
                      </div>
                    </div>
                  ))}
              </div>
              {isMobile && (
                <Button label="Więcej" stroke svg_type={SVG_TYPE.SHORT_ARROW_RIGHT} onClick={() => navigate({ pathname: ROUTES.ALL_CITIES })} />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="top-towns--skeleton">
          <Skeleton height="351px" />
        </div>
      )}
    </>
  );
};

export default TopTowns;
