import * as AuthModule from 'Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

export const success = () => ({
  type: Auth.RECOVER_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: Auth.RECOVER_PASSWORD_FAILURE,
});

const recoverPassword =
  ({ newPassword, resetToken }) =>
  async (dispatch) => {
    const id = toast.loading('Trwa resetowanie hasła...');
    try {
      await AuthModule.Connector.resetPassword({ newPassword, resetToken });
      dispatch(success());
      toast.update(id, {
        render: 'Pomyślnie zresetowano hasło',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
      });
      return true;
    } catch (err) {
      dispatch(failure());
      toast.update(id, {
        render: 'Nie udało się zresetować hasła',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
      return false;
    }
  };

export default recoverPassword;
