import SVG from 'Components/SVG';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { shortCategories } from 'Screens/AdminLearningPage/utils/shortCategories';

type SingleDepartmentType = {
  data: any;
  setEditedData: (data) => void;
  setDeletedData: (data) => void;
};

function Department({ data, setEditedData, setDeletedData }: SingleDepartmentType): JSX.Element {
  const navigate = useNavigate();
  const { name, lessonsCount, categories, status } = data;
  const handleRedirect = (uuid) => {
    navigate({ pathname: ROUTES.ADMIN_DEPARTMENT.replace('uuid', uuid) });
  };

  return (
    <div className="department">
      <div className="department__top">
        {data && data.file && <img src={data && data.file && data.file.length > 0 && data.file[0].url} alt="icon" className="department__icon" />}
        <p className="department__title">{name}</p>
        <div className="department__svg department__svg--chevrone" onClick={() => handleRedirect(data.uuid)}>
          <SVG type={SVG_TYPE.BACK_CHEVRON} />
        </div>
      </div>
      <div className="department__mid">
        <p className="department__stats">
          Ilość lekcji : {lessonsCount} ・ Kategorie: {shortCategories(categories)}
        </p>
      </div>
      <div className="department__bottom">
        <div className={`department__badge ${status === 'active' ? 'department__badge--active' : 'department__badge--inactive'}`}>
          {status === 'active' ? 'Opublikowany' : 'Nieopublikowany'}
        </div>
        <div className="department__call-to-action">
          <div className="department__svg" onClick={() => setEditedData(data)}>
            <SVG type={SVG_TYPE.EDIT} />
          </div>
          <div className="department__svg" onClick={() => setDeletedData(data)}>
            <SVG type={SVG_TYPE.BIN} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Department;
