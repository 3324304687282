import { useEffect } from 'react';
import { ROUTES, SVG_TYPE, USER_ROLES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { Button, SVG } from 'Components';
import { MainType } from './Container';
import { history } from 'Store/Reducers/RootReducer';
import { generatePath } from 'react-router-dom';

const Page404 = (props: MainType) => {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const {
    actions: { getAuthorizedUser },
    authorizedUser,
  } = props;

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  useEffect(() => {
    const slideRoutesElement = document.getElementsByClassName('slide-routes')[0] as HTMLElement;
    if (slideRoutesElement) {
      slideRoutesElement.style.width = '100vw';
    }
    return () => {
      if (slideRoutesElement && pathname === ROUTES.PAGE_404) {
        slideRoutesElement.style.width = 'calc(100vw - 72px)';
      }
    };
  }, []);

  const handleRedirect = () => {
    if (authorizedUser && authorizedUser != 'init') {
      if (authorizedUser.role === USER_ROLES.CLIENT) {
        // navigate({ pathname: ROUTES.LEARNING });
        navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
        // history.push(ROUTES.LEARNING);
        window.location.reload();
      } else {
        // navigate({ pathname: ROUTES.USERS });
        navigate({ pathname: generatePath(ROUTES.USERS, { role: USER_ROLES.CLIENT, page: '1' }) });
        // window.location.reload();
      }
    } else {
      history.push(ROUTES.LOGIN_PAGE);
      window.location.reload();
      // navigate({ pathname: ROUTES.LOGIN_PAGE });
    }
  };

  if (pathname === ROUTES.PAGE_404) {
    return (
      <>
        <div className="page404__wrapper">
          <div className="page404__top--left">
            <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
          </div>
          <div className="page404__top--right">
            <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
          </div>
          <div className="page404__top-logo">
            <p onClick={() => handleRedirect()}>
              <SVG type={SVG_TYPE.LOGO_ROYAL} />
            </p>
          </div>
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
          <div className="page404__content">
            <div className="page404__cloud">
              <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--third" />
            </div>
            <SVG type={SVG_TYPE.PAGE_404} />
            <div className="page404__descript">Ups! Wygląda na to, że trafiłeś w ślepą uliczkę.</div>
            <div className="page404__descript">Nie udało nam się odnaleźć szukanej strony.</div>
            <Button label={`${authorizedUser !== 'init' ? `Wróć do nauki` : `Zaloguj się`}`} onClick={() => handleRedirect()} />
          </div>
        </div>
      </>
    );
  } else navigate({ pathname: ROUTES.PAGE_404 });
};

export default Page404;
