import * as Types from 'Shared/models/CityModel';
import { City } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.CityStore;

export const initialState: State = {
  allUserCities: null,
  popularCities: null,
};

export function cityReducer(state: any = initialState, action) {
  switch (action.type) {

    case City.GET_CITIES_BY_USERS_SUCCESS: {
      return {
        ...state,
        allUserCities: action.payload.data.cities,
      };
    }
    
    case City.GET_POPULAR_CITIES_SUCCESS: {
      return {
        ...state,
        popularCities: action.payload.data.cities,
      };
    }
    
    default: {
      return state;
    }
  }
}
