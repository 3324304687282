import { useState } from 'react';
import { SVG } from 'Components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import ReactPlayer from 'react-player';
// import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type QuestionType = {
  activeTask: any;
  saveTask: any;
};

const Question = ({ activeTask, saveTask }: QuestionType) => {
  const [isQuestionSaved, setIsQuestionSaved] = useState(activeTask.isSaveTask || activeTask.taskIsSaved);
  const savedQuestionContainerClass = isQuestionSaved ? 'lesson-question__save-label__container--active' : '';
  const savedQuestionIconClass = isQuestionSaved ? 'lesson-question__save-label--active' : '';
  const navigate = useNavigate();

  const handleSaveQuestion = () => {
    saveTask({ taskUuid: activeTask.uuid, isQuestionSaved });
    setIsQuestionSaved(!isQuestionSaved);
    activeTask.isSaveTask && navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'save_question' }) });
  };

  const getQuestionMedia = (file) => {
    const filename = file.substring(file.lastIndexOf('/') + 1);
    if (filename.endsWith('.mp4')) {
      return (
        <div className="lesson-question__photo-container">
          <ReactPlayer className="video" fullscreen={false} playsinline={true} url={file} controls={true} width="100%" height="100%" />
          <div className={`lesson-question__save-label__container ${savedQuestionContainerClass}`} onClick={() => handleSaveQuestion()}>
            <SVG className={`lesson-question__save-label ${savedQuestionIconClass}`} type={SVG_TYPE.SAVE_LABEL} />
          </div>
        </div>
      );
    }
    if (filename.endsWith('.webp')) {
      return (
        <div className="lesson-question__photo-container">
          <img className="question__img" src={file} alt="" />
          <div className={`lesson-question__save-label__container ${savedQuestionContainerClass}`} onClick={() => handleSaveQuestion()}>
            <SVG className={`lesson-question__save-label ${savedQuestionIconClass}`} type={SVG_TYPE.SAVE_LABEL} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="lesson-question__photo-container lesson-question__photo-container--empty">
          <div className={`lesson-question__save-label__container ${savedQuestionContainerClass}`} onClick={() => handleSaveQuestion()}>
            <SVG className={`lesson-question__save-label ${savedQuestionIconClass}`} type={SVG_TYPE.SAVE_LABEL} />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {getQuestionMedia(activeTask.question.filePath)}
      <div className="lesson-question__question">{activeTask.question.question}</div>
    </>
  );
};

export default Question;
