import { connect } from 'react-redux';
import ExaminationPage from './Main';
import { bindActionCreators } from 'redux';
import { drivingTest } from 'Store/Selectors/DrivingTestSelector';
import { getDrivingTest, getDrivingTestAnswer, finishDrivingTest } from 'Store/Actions/DrivingTest';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { getAuthorizedUser } from 'Store/Actions/Auth';
import { User } from 'Shared/models/UserModel';
 
type ConnectedP = {
  drivingTest: any
  authorizedUser: User | 'init';
};

type DispatchedP = {
  actions: {
    getDrivingTest: () => any;
    getDrivingTestAnswer: (params) => any;
    finishDrivingTest: (uuid) => any;
    getAuthorizedUser: () => (dispatch: any) => Promise<any>;
  };
};
const mapStateToProps = (state) => ({
  drivingTest: drivingTest(state),
  authorizedUser: authorizedUser(state),

});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: getAuthorizedUser,
      getDrivingTest: getDrivingTest,
      getDrivingTestAnswer: getDrivingTestAnswer,
      finishDrivingTest: finishDrivingTest,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ExaminationPage);
