import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export function addDepartment(data): AxiosPromise<any> {
  return API.post(`departments`, data);
}

export function getDepartments(params): AxiosPromise<any> {
  const queryString = qs.stringify(params);
  return API.get(`departments?${queryString}`);
}

export function editDepartment(uuid, data): AxiosPromise<any> {
  return API.put(`departments/${uuid}`, data);
}

export function deleteDepartment(uuid): AxiosPromise<any> {
  return API.delete(`departments/${uuid}`);
}

export function addDepartmentPhoto(uuid, file): AxiosPromise<any> {
  return API.put(`departments/file/${uuid}`, file);
}

export function getClientDepartments(params): AxiosPromise<any> {
  const queryString = qs.stringify(params);
  return API.get(`clients/departments?${queryString}`);
}

export function getClientLearningInfo(): AxiosPromise<any> {
  return API.get(`users/departments/info`);
}
