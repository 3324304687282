import * as UserModule from 'Modules/UserModule';
import * as AuthModule from 'Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

export const success = (data) => ({
  type: Auth.DELETE_CURRENT_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Auth.DELETE_CURRENT_USER_FAILURE,
});

const deleteCurrentUser = () => async (dispatch) => {
  const id = toast.loading('Trwa usuwanie konta...');
  try {
    const response = await UserModule.Connector.deleteCurrentUser();
    toast.update(id, {
      render: 'Konto zostało usunięte',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });
    dispatch(AuthModule.Actions.getAuthorizedUser());
    dispatch(success(response.data.user));
    return true;
  } catch (err) {
    toast.update(id, {
      render: 'Nie udało się usunąć konta',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });
    dispatch(failure());
    return false;
  }
};

export default deleteCurrentUser;
