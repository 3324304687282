import { connect } from 'react-redux';
import Contact from './Main';
import { bindActionCreators } from 'redux';
import * as Types from 'Shared/models/AuthModel';
import getPhonePrefixes from 'Store/Actions/Auth/getPhonePrefix';
import { authorizedUser, phonePrefixes } from 'Store/Selectors/AuthSelector';
import { sendMail } from 'Store/Actions/User';

type ConnectedP = {
  phonePrefixes: Array<Types.PhonePrefix>;
  authorizedUser: any
};

type DispatchedP = {
  actions: {
    getPhonePrefixes: () => (dispatch: any) => Promise<boolean>;
    sendMail: (form) => any,
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getPhonePrefixes: getPhonePrefixes,
      sendMail: sendMail,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
