import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  short?: boolean;
  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
}

function Modal({ children, isOpen, onClose, className = '', onOpen, short }: ModalProps) {
  const isMobile = window.innerWidth < 1024;
  const mobileShort = isMobile && short;
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const mobileShortClass = mobileShort ? `modal__short ${isModalOpen ? 'modal__short--open' : ''}` : '';

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      onClose && onClose();
    }
  };

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    setIsModalOpen(isOpen);
    if (isOpen && onOpen) {
      onOpen();
    }
    if (isOpen) {
      if (isMobile) {
        html.classList.add('modal-mobile');
        // window.scrollTo({
        //   top: 0,
        // });
      }
    } else {
      if (isMobile) {
        html.classList.remove('modal-mobile');
      }
    }
  }, [isOpen]);

  return (
    <>
      <div onClick={(e) => handleOutsideClick(e)} className={`modal__backdrop ${isModalOpen ? 'modal__backdrop--open' : ''}`}>
        <div className={`modal ${mobileShortClass} ${className}`}>{children}</div>
      </div>
    </>
  );
}

export default (props: ModalProps) => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body'));
