import * as Types from 'Shared/models/UserModel';
import { User } from '../Actions/EnumTypes/ActionEnumTypes';


export type State = Types.UserStore;

export const initialState: State = {
  token: null,
  user: null,
  usersList: [],
  userExam: null,
  userStats: null,
  usersListMeta: {
    current_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page: 0,
    last_page_url: '',
    next_page_url: null,
    per_page: 0,
    previous_page_url: null,
    total: 0,
  },
  drivingCategories: [],
};

export function userReducer(state: any = initialState, action) {
  switch (action.type) {
    case User.GET_USERS_INIT: {
      return {
        ...state,
      };
    }
    case User.GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.payload.users.data,
        usersListMeta: action.payload.users.meta,
      };
    }
    case User.GET_USER_STATS_SUCCESS: {
      return {
        ...state,
        userStats: action.payload.data,
      };
    }
    case User.GET_USERS_FAILURE: {
      return {
        ...state,
      };
    }
    case User.EDIT_USER_SUCCESS: {
      const user = action.payload.data;
      const updatedUserList = state.usersList.map((item) => {
        if (item.uuid === user.uuid) {
          return user;
        }
        return item;
      });

      return {
        ...state,
        usersList: updatedUserList,
      };
    }


    case User.USER_RESTORATION_SUCCESS: {
      const updatedUsersList = state.usersList.map(user => {
        if (user.uuid === action.payload.uuid) {
          return {
            ...user,
            isArchivied: false
          };
        }
        return user;
      });
    
      return {
        ...state,
        usersList: updatedUsersList
      };
    }

    case User.EDIT_USER_FAILURE: {
      return {
        ...state,
      };
    }
    case User.ADD_ADMIN_SUCCESS: {
      return {
        ...state,
      };
    }
    case User.ADD_ADMIN_FAILURE: {
      return {
        ...state,
      };
    }
    case User.CHANGE_USER_NAME_SUCCESS: {
      return {
        ...state,
      };
    }
    case User.CHANGE_USER_NAME_FAILURE: {
      return {
        ...state,
      };
    }
    case User.GET_DRIVING_CATEGORY_SUCCESS: {
      return {
        ...state,
        drivingCategories: action.payload.users,
      };
    }
    case User.GET_DRIVING_CATEGORY_FAILURE: {
      return {
        ...state,
      };
    }
    case User.DELETE_CURRENT_USER_SUCCESS: {
      return {
        ...state,
      };
    }
    case User.DELETE_CURRENT_USER_FAILURE: {
      return {
        ...state,
      };
    }
    case User.GET_USER_EXAM_SUCCESS: {
      return {
        ...state,
        userExam: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
