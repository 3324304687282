import SVG from 'Components/SVG';
import { useNavigate } from 'react-router';
import { SVG_TYPE } from 'Shared/enums';

interface BackProps {
  onClick?: () => void;
  className?: string;
}

function Back({ onClick, className = '' }: BackProps) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <button className={`back ${className}`} onClick={onClick || handleClick}>
      <SVG type={SVG_TYPE.BACK_CHEVRON} />
    </button>
  );
}

export default Back;
