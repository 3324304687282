import { useState, useEffect } from 'react';
import { ROUTES, SVG_TYPE, USER_TYPE } from 'Shared/enums';
import { SVG } from 'Components';
import { useNavigate } from 'react-router-dom';
import { CallToAction, CompletedLessons, LessonTile } from './components';
import { MainType } from './Container';
import { Dailylimit } from 'Screens/LessonPage/components';
import { generatePath } from 'react-router-dom';

function DepartmentsPage(props: MainType) {
  const [isLoading, setIsLoading] = useState(true);
  const { actions, lessons, authorizedUser } = props;
  const { getClientLessons } = actions;
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const departmentUuid = pathname.split(':')[1];
  const isUserLogged = !!authorizedUser && authorizedUser !== 'init';
  const userType = isUserLogged && authorizedUser.type;
  const halfLesson = lessons?.lessons.length / 2;
  const isUserStandard = isUserLogged && authorizedUser.type === USER_TYPE.STANDARD;
  const isUserDemo = isUserLogged && authorizedUser.type === USER_TYPE.DEMO;
  const isTaskAvailable = isUserLogged && authorizedUser.availableTasks;

  const goBack = () => {
    navigate({ pathname: generatePath(ROUTES.LEARNING, { submenu: 'departments' }) });
  };

  useEffect(() => {
    getClientLessons(departmentUuid);
  }, []);

  useEffect(() => {
    if (lessons) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(true);
    }
  }, [lessons]);

  return (
    <>
      <Dailylimit isUserStandard={isUserStandard} isUserDemo={isUserDemo} isOpen={!isTaskAvailable} />
      <header className="dashboard__header ">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

        <div className="dashboard__container departments-page__header-container">
          <h1 className="dashboard__title departments-page__title">
            {lessons?.department?.name}
            <div onClick={goBack} className="dashboard__goBack">
              <SVG type={SVG_TYPE.CHEVRONE} />
            </div>
          </h1>
        </div>
      </header>
      <div className="dashboard__content departments-page__content">
        <div className="dashboard__container departments-page__container">
          <div className="departments-page__top">
            <CompletedLessons isLoading={isLoading} department={lessons?.department} />
            <CallToAction userType={userType} />
          </div>
          <div className="departments-page__bottom">
            <div className="departments-page__bottom--left">
              {isLoading ? (
                <>
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                </>
              ) : (
                <>
                  {lessons?.lessons?.map((item, index) => {
                    if (index < halfLesson) {
                      return <LessonTile key={item.uuid} item={item} index={index} />;
                    }
                  })}
                </>
              )}
            </div>
            <div className="departments-page__bottom--right">
              {isLoading ? (
                <>
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                  <LessonTile isLoading={isLoading} />
                </>
              ) : (
                <>
                  {lessons?.lessons?.map((item, index) => {
                    if (index >= halfLesson) {
                      return <LessonTile key={item.uuid} item={item} index={index} />;
                    }
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DepartmentsPage;
