import { connect } from 'react-redux';
import Users from './Main';
import { bindActionCreators } from 'redux';
import { addAdmin, editUser, getUsers, manageUserSuspension, editAvatar, userRestoration } from 'Store/Actions/User';
import { usersList, usersListMeta } from 'Store/Selectors/UserSelector';
import { AdminEdit, User, UserEdit, UserListMetaType } from 'Shared/models/UserModel';
import getPhonePrefixes from 'Store/Actions/Auth/getPhonePrefix';
import { phonePrefixes, cities } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import {  resendActivateLink, getCities } from 'Store/Actions/Auth';

type ConnectedP = {
  usersList: Array<User>;
  usersListMeta: UserListMetaType;
  phonePrefixes: Array<Types.PhonePrefix>;
  cities: Array<Types.Cities>;
};

type DispatchedP = {
  actions: {
    getUsers: (role: string, page: number) => (dispatch: any) => Promise<boolean>;
    getPhonePrefixes: () => (dispatch: any) => Promise<boolean>;
    getCities: () => (dispatch: any) => Promise<boolean>;
    editUser: (userData: UserEdit | AdminEdit, uuid: string) => (dispatch: any) => any;
    addAdmin: (userData: AdminEdit) => (dispatch: any) => Promise<any>;
    resendActivateLink: ({email}) => (dispatch: any) => Promise<boolean>;
    manageUserSuspension: (uuid) => (dispatch: any) => Promise<boolean>;
    editAvatar: (uuid, file) => void;
    userRestoration: (uuid) => void;
  };
};
const mapStateToProps = (state) => ({
  cities: cities(state),
  usersList: usersList(state),
  usersListMeta: usersListMeta(state),
  phonePrefixes: phonePrefixes(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      editAvatar:editAvatar,
      manageUserSuspension: manageUserSuspension,
      getUsers: getUsers,
      getPhonePrefixes: getPhonePrefixes,
      getCities: getCities,
      editUser: editUser,
      addAdmin: addAdmin,
      resendActivateLink: resendActivateLink,
      userRestoration: userRestoration,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Users);
