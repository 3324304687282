import * as UserModule from 'Modules/UserModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

export const success = (data) => ({
  type: Auth.EDIT_AVATAR_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Auth.EDIT_AVATAR_FAILURE,
});

const editAvatar = (uuid, avatar, addNotification = false) => async (dispatch) => {
  let id;
  if(addNotification) {
   id = toast.loading('Trwa dodawanie avatara...');
  }
  try {
    const formData = new FormData();
    formData.append('avatar', avatar);
    const response = await UserModule.Connector.editAvatar(uuid, formData);
    dispatch(success(response.data.avatar));
    addNotification && toast.update(id, {
      render: 'Pomyślnie dodano avatar',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });
    return true;
  } catch (err) {
    dispatch(failure());
    addNotification &&  toast.update(id, {
      render: 'Nie udało się dodać avatara',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });
    return false;
  }
};

export default editAvatar;
