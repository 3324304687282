import React from 'react';
import ReactDOM from 'react-dom/client';
import 'Assets/Sass/style.scss';
import { Provider } from 'react-redux';
import { store } from 'Store/Store';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import App from 'App';

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // {/* </React.StrictMode>, */}
);
