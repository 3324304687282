import Button from 'Components/Button';
import { useNavigate } from 'react-router';
import { ROUTES } from 'Shared/enums';

function SuccessfulPasswordChangePage() {
  const navigate = useNavigate();

  return (
    <div className="login-page ">
      <main className="login-page__body">
        <div className="login-page__content-wrapper">
          <h2 className="login-page__title">Hasło zostało zmienione</h2>
          <p className="login-page__description login-page__description--successful-password-change">
            Możesz teraz zalogować się za pomocą nowego hasła.
          </p>
        </div>
        <div className="login-page__buttons">
          <Button
            label={'Zaloguj się'}
            onClick={() => {
              navigate({ pathname: ROUTES.ADMIN_LOGIN_PAGE });
            }}
          />
        </div>
      </main>
    </div>
  );
}

export default SuccessfulPasswordChangePage;
