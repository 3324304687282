import { connect } from 'react-redux';
import Dashboard from './Main';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { adminLogin, changeCurrentUserPassword, getAuthorizedUser, logout } from 'Store/Actions/Auth';
import { User } from 'Shared/models/UserModel';
import { changeUserName, deleteCurrentUser } from 'Store/Actions/User';
type ConnectedP = {
  authorizedUser: User | 'init';
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    changeCurrentUserPassword: ({ currentPassword, newPassword }) => (dispatch: any) => Promise<boolean>;
    changeUserName: (name: string) => (dispatch: any) => Promise<boolean>;
    logout: () => (dispatch: any, getState) => Promise<any>;
    getAuthorizedUser: () => (dispatch: any) => Promise<any>;
    deleteCurrentUser: () => (dispatch: any) => Promise<boolean>;
  };
};
const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      adminLogin: adminLogin,
      changeCurrentUserPassword: changeCurrentUserPassword,
      getAuthorizedUser: getAuthorizedUser,
      logout: logout,
      changeUserName: changeUserName,
      deleteCurrentUser: deleteCurrentUser,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
