import * as LessonConnector from 'Modules/LessonModule';
import { Lesson } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Lesson.GET_CLIENT_LESSONS_INIT,
});

export const success = (data) => ({
  type: Lesson.GET_CLIENT_LESSONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Lesson.GET_CLIENT_LESSONS_FAILURE,
});

const getClientLessons = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await LessonConnector.Connector.getClientLessons(uuid);
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default getClientLessons;
