import * as Departments from 'Modules/DepartmentModule';
import { Department } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Department.EDIT_DEPARTMENT_INIT,
});

export const success = (data) => ({
  type: Department.EDIT_DEPARTMENT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Department.EDIT_DEPARTMENT_FAILURE,
});

const editDepartment = (uuid, data) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa edycja działu...');
  try {
    const response = await Departments.Connector.editDepartment(uuid, data);
    dispatch(success(response.data));
    toast.update(id, {
      render: 'Pomyślna edycja działu',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się edytować działu',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_RED} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default editDepartment;
