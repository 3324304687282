import { FieldProps } from 'formik';
import { useState, useEffect } from 'react';

interface InputProps {
  label: string;
  className?: string;
  //formik
  field: any;
  errors: any;
  form: any;
  setFieldValue: any;
}

function Switch({ label, className = '', field, setFieldValue }: InputProps & FieldProps) {
  const [isChecked, setIsChecked] = useState(field.value);
  useEffect(() => {
    setIsChecked(field.value);
  }, [field]);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
    setFieldValue(field.name, !isChecked);
  };

  return (
    <div className={`switch__wrapper ${className}`}>
      <label className="switch__label" htmlFor={field.name}>
        {label}
      </label>
      <input checked={field.value} onChange={() => null} className="switch-checkbox" id={`switch`} type="checkbox" />
      <label onClick={handleOnChange} className={`switch-label ${isChecked ? 'switch-label--active' : ''}`} htmlFor={`switch`}>
        <span className={`switch-button`} />
      </label>
    </div>
  );
}

export default Switch;
