import { connect } from 'react-redux';
import ExamPage from './Main';
import { bindActionCreators } from 'redux';
import { phonePrefixes, authorizedUser } from 'Store/Selectors/AuthSelector';
import * as Types from 'Shared/models/AuthModel';
import { adminLogin } from 'Store/Actions/Auth';
import { getUserExam } from 'Store/Actions/User';
import { User } from 'Shared/models/UserModel';
import { getUserExam as getUserExamSelector } from 'Store/Selectors/UserSelector';


type ConnectedP = {
  authorizedUser: User | 'init';
  phonePrefixes: Array<Types.PhonePrefix>;
  userExam: any;
};

type DispatchedP = {
  actions: {
    adminLogin: ({ email, password }) => (dispatch: any) => Promise<boolean>;
    getUserExam: () => any;
  };
};
const mapStateToProps = (state) => ({
  phonePrefixes: phonePrefixes(state),
  authorizedUser: authorizedUser(state),
  userExam: getUserExamSelector(state)
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      adminLogin: adminLogin,
      getUserExam: getUserExam,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ExamPage);
