import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Components';
import { TopTowns } from './components';
import { MainType } from './Container';
import { useEffect } from 'react';

function StatisticsPage(props: MainType) {
  const {
    allUserCities,
    popularCities,
    actions: { getAllUserCity, getPopularCities },
  } = props;

  useEffect(() => {
    getAllUserCity(1);
  }, []);

  useEffect(() => {
    getPopularCities();
  }, []);

  return (
    <>
      <header className="dashboard__header learning-page__header ">
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
        <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />

        <div className="dashboard__container dashboard__container--learning">
          <h1 className="dashboard__title">Statystyki</h1>
        </div>
      </header>
      <div className="dashboard__content learning-page">
        <div className="dashboard__container learning-page__container">
          <TopTowns allUserCities={allUserCities} popularCities={popularCities} />
        </div>
      </div>
    </>
  );
}

export default StatisticsPage;
